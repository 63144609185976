<div #root [class.disabled]="disabled"
     [class.editable]="editable"
     [class.error]="invalid"
     [class.icon]="iconName"
     [class.table-input]="tableInput"
     [class.with-label]="label && iconName"
     [style.max-width]="maxWidth + 'px'"
     [style.width]="width + 'px'"
     class="ax-ui-select ax-input">
  @if (label) {
    <label>
      <small><b>{{ label | translate }} @if (required) {
        <b class="ax-ui-required-asterisk"> *</b>
      }</b></small>
    </label>
  }
  <div #customSelect (click)="focusOnSelect()"
       [class.disabled]="disabled"
       class="ax-ui-select__custom"
       tabindex="0">
    <div [class.placeholder]="!value" class="ax-ui-select__selected-option">
      <small>{{ selectedLabel | translate }}</small>
    </div>
    <select #nativeSelect
            (change)="emitValueChange($event)"
            [disabled]="disabled"
            [name]="name"
            [value]="value">
      <option>{{ placeholder }}</option>
      @for (option of options; track option) {
        <option
          [selected]="option.value === value"
          [value]="option.value">
          {{ option.label | translate }}
        </option>
      }
    </select>
    @if (valueClearable) {
      <ax-ui-button class="clear-value"
                    [iconOnly]="true"
                    iconName="close"
                    [small]="true"
                    variant="secondary"
                    [whiteBackground]="true"
                    axUiShowConfirmActionModal
                    (actionConfirmResult)="emitValueCleared()" />
    }
    <ax-ui-icon class="arrow-down" name="expand_more" />
  </div>
  @if (iconName) {
    <ax-ui-icon [name]="iconName" />
  }
  @if (!disableHint) {
    <span [class.hide]="hideHint" [style.opacity]="invalid ? '1' : '0'" class="ax-input__hint">
      <small><b>{{ hintMessage }}</b></small>
    </span>
  }
</div>
