import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions } from '../../../models/chart-options.type';

@Component({
  selector: 'ax-ui-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
  standalone: true,
  imports: [NgApexchartsModule],
})
export class ChartLineComponent implements OnChanges {
  @ViewChild('chart') chart!: ChartComponent;
  @Input() title = '';
  @Input() unitName = '';
  @Input() xAxisLabels: string[] = [];
  @Input() xAxisValues: number[] = [];
  @Input() chartSeries: ApexAxisChartSeries = [];
  @Input() showToolbar = true;
  public chartOptions: ChartOptions | undefined;

  constructor(private readonly translateService: TranslateService) {}

  ngOnChanges() {
    this.setChartsOptions();
  }

  private setChartsOptions() {
    this.chartOptions = {
      series: this.chartSeries.length
        ? this.chartSeries
        : [
            {
              name: this.unitName ? this.translateService.instant(this.unitName) : '',
              data: this.xAxisValues,
            },
          ],
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: this.showToolbar,
        },
      },
      title: {
        text: this.title ? this.translateService.instant(this.title) : '',
      },
      xaxis: {
        categories: this.xAxisLabels.map((label) => (label ? this.translateService.instant(label) : '')),
      },
    };
  }
}
