import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ax-ui-accordion-item',
  standalone: true,
  imports: [IconComponent, NgIf],
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {
  @ViewChild('heading') headingElement: ElementRef | undefined;
  @Input() isOpen = false;
  @Input() isDisabled = false;
  @Input() withBorder = true;
  @Input() hideIcon = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
