<ax-ui-modal #confirmationModal
             (modalClosedWithNoButton)="declineAction()"
             [hideCloseButton]="modalOptions.hideCloseButton || false"
             [modalWidth]="'small'"
             [titleText]="modalOptions.title! | translate"
             modalId="confirmationModal">
  <ng-container modal-body>
    @if (modalOptions.infoText) {
      <p>{{ modalOptions.infoText | translate }}</p>
    }
  </ng-container>
  <ng-container modal-footer>
    @for (button of modalOptions.buttons; track button) {
      <ax-ui-button (click)="doAction(button)"
                    [text]="button.label | translate"
                    [variant]="button.variant" />
    }
  </ng-container>
</ax-ui-modal>
