import { Component, Input, OnInit } from '@angular/core';
import { AxReportsV2Service, AxSalesForPvDto } from '@axova-frontend-monorepo/axova-rest-api';
import { ExceptionHandlingService } from '../../../services/exception-handling/exception-handling.service';
import { lastValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import { ChartSeries } from '../../../models/chart-options.type';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { ChartColumnComponent } from '../../charts/chart-column/chart-column.component';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';

@Component({
  selector: 'ax-ui-widget-verkaufte-anlagen',
  templateUrl: './widget-verkaufte-anlagen.component.html',
  styleUrls: ['./widget-verkaufte-anlagen.component.scss'],
  standalone: true,
  imports: [WidgetBaseComponent, ChartColumnComponent, LoadingSpinnerComponent, TranslateModule],
})
export class WidgetVerkaufteAnlagenComponent implements OnInit {
  @Input() route: string | undefined = '';
  public salesForPv: AxSalesForPvDto | undefined;
  public columnChartxAxisLabels: string[] = [];
  public columnChartSeries: ChartSeries[] = [
    {
      name: this.translateService.instant('JAHR_DIESES'),
      data: [],
    },
    {
      name: this.translateService.instant('MONAT_DIESER'),
      data: [],
    },
  ];
  public showLoading = true;
  public showChart = false;
  protected readonly dayjs = dayjs;

  constructor(
    private readonly axReportsV2Service: AxReportsV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService,
    private readonly translateService: TranslateService,
  ) {}

  async ngOnInit() {
    await this.loadSalesForPv();
  }

  public async loadSalesForPv() {
    this.showLoading = true;
    try {
      this.salesForPv = await lastValueFrom(
        this.axReportsV2Service.reportsSalesControllerFindSalesForPv({
          withDetails: true,
        }),
      );
      this.showLoading = false;
      this.populateColumnChart();
    } catch (loadSalesForPvException) {
      this.showLoading = false;
      this.exceptionHandlingService.handleExceptionWithAlert('loadSalesForPvException', loadSalesForPvException);
    }
  }

  private populateColumnChart() {
    this.salesForPv?.amountByUser?.forEach((user) => {
      // data
      this.columnChartSeries[0].data.push(user.amountThisYear);
      this.columnChartSeries[1].data.push(user.amountThisMonth);

      // xAxis labels
      this.columnChartxAxisLabels.push(`${user.firstName} ${user.lastName}`);
    });
    this.showChart = true;
  }
}
