export enum ConfigurationKeyNamesEnum {
  AxOfficeKontakteTable = 'AxOfficeKontakteTable',
  AxOfficeVeranstaltungenTable = 'AxOfficeVeranstaltungenTable',
  AxOfficeMontageplanTeams = 'AxOfficeMontageplanTeams',
  AxOfficeMontageplanWeekOverviewTeams = 'AxOfficeMontageplanWeekOverviewTeams',
  AxOfficeMitarbeiterTable = 'AxOfficeMitarbeiterTable',
  AxOfficeAuswertungenFoerdergelder = 'AxOfficeAuswertungenFoerdergelder',
  AxOfficeMitarbeiterStundenuebersichtTable = 'AxOfficeMitarbeiterStundenuebersichtTable',
  AxOfficeBetriebsmittelTable = 'AxOfficeBetriebsmittelTable',
  AxOfficeLagerorteTable = 'AxOfficeLagerorteTable',
  AxOfficeBetriebsmittelLogbuchTable = 'AxOfficeBetriebsmittelLogbuchTable',
  AxOfficeAuswertungenMitarbeiterStundenauszugTable = 'AxOfficeAuswertungenMitarbeiterStundenauszugTable',
  AxOfficeAuswertungenMitarbeiterSaldouebertragTable = 'AxOfficeAuswertungenMitarbeiterSaldouebertragTable',
  AxOfficeAuswertungenMitarbeiterSaldouebersichtTable = 'AxOfficeAuswertungenMitarbeiterSaldouebersichtTable',
  AxOfficeAuswertungenUmsatzDeckungsbeitrag = 'AxOfficeAuswertungenUmsatzDeckungsbeitrag',
  AxOfficeAuswertungenMitarbeiterLohnlisteTable = 'AxOfficeAuswertungenMitarbeiterLohnlisteTable',
  AxOfficeArtikelTable = 'AxOfficeArtikelTable',
  AxOfficeGeruestbauTable = 'AxOfficeGeruestbauTable',
  AxOfficeProjekteTable = 'AxOfficeProjekteTable',
  AxOfficeElektroDrittkontrolleTable = 'AxOfficeElektroDrittkontrolleTable',
  AxOfficeMontageplanUnplannedBlocksProjectsTable = 'AxOfficeMontageplanUnplannedBlocksProjectsTable',
  AxOfficeMontageplanUnplannedBlocksTasksTable = 'AxOfficeMontageplanUnplannedBlocksTasksTable',
  AxOfficeMontageplanZoom = 'MontageplanZoom',
  AxOfficeMontageplanTeamsExpandedState = 'AxOfficeMontageplanTeamsExpandedState',
}
