
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateProjectAnlageUndObjektdatenDtoFormGroup() {
return new FormGroup({
    powerKwp: new FormControl<number | undefined>(undefined),
    revenueKwhSpecific: new FormControl<number | undefined>(undefined),
    constructionType: new FormControl<any>(undefined),
    systemType: new FormControl<any>(undefined),
    supply: new FormControl<any>(undefined),
    esti: new FormControl<boolean | undefined>(undefined),
    realestatetypeId: new FormControl<number | undefined>(undefined),
    lot: new FormControl<string | undefined>(undefined),
    buildingpermit: new FormControl<boolean | undefined>(undefined),
    supplierId: new FormControl<number | undefined>(undefined),
    authenticatorId: new FormControl<number | undefined>(undefined),
    powerHakAmpere: new FormControl<number | undefined>(undefined),
})
;
}
      