import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AvatarComponent,
  ButtonComponent,
  CardModalOpenerComponent,
  CheckboxComponent,
  FilterComponent,
  IconComponent,
  InfoblockComponent,
  InputFieldComponent,
  LabelComponent,
  LoadingAndFeedbackState,
  LogoComponent,
  ModalComponent,
  RadioButtonOptions,
  RadioGroupComponent,
  SelectComponent,
  SelectDropdownOptions,
  TabContentDirective,
  TabOptions,
  TabsComponent,
  ToggleSwitchComponent,
} from '@axova-frontend-monorepo/axova-ui';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationKeyNamesEnum } from '@axova-frontend-monorepo/axova-commons';
import { TableDemoComponent } from './components/table-demo/table-demo.component';
import { ItemComponent } from './components/item/item.component';
import { ContainerComponent } from './components/container/container.component';

@Component({
  selector: 'ax-component-library-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LogoComponent,
    ContainerComponent,
    ItemComponent,
    IconComponent,
    ButtonComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    RadioGroupComponent,
    ToggleSwitchComponent,
    InputFieldComponent,
    SelectComponent,
    LabelComponent,
    CardModalOpenerComponent,
    ModalComponent,
    AvatarComponent,
    InfoblockComponent,
    TabsComponent,
    TabContentDirective,
    TableDemoComponent,
    FilterComponent,
  ],
})
export class AppComponent {
  title = 'axova-component-library';
  public testOptions: RadioButtonOptions[] = [
    {
      name: 'test1',
      label: 'Test 1',
      value: 'test1',
    },
    {
      name: 'test2',
      label: 'Test 2',
      value: 'test2',
    },
    {
      name: 'test3',
      label: 'Test 3',
      value: 'test3',
      disabled: true,
    },
  ];
  public genderOptions: RadioButtonOptions[] = [
    {
      name: 'male',
      label: 'Männlich',
      value: 'male',
    },
    {
      name: 'female',
      label: 'Weiblich',
      value: 'female',
    },
    {
      name: 'other',
      label: 'Divers',
      value: 'other',
    },
    {
      id: 'superSpecialCustomId',
      name: 'penguin',
      label: 'Pinguin',
      value: 'penguin',
      disabled: true,
    },
  ];
  public selectOptions: SelectDropdownOptions[] = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
    { label: 'Option 3', value: 3 },
  ];
  public radioTestForm: FormGroup = new FormGroup({
    test: new FormControl(this.testOptions[0].value),
  });
  public showCaseform1: FormGroup = new FormGroup({
    gender: new FormControl(this.genderOptions[0].value),
    inputDemo1: new FormControl(''),
    inputDemo2: new FormControl(''),
    acceptTerms: new FormControl(false),
    toggleTest: new FormControl(false),
  });
  public showCaseform2: FormGroup = new FormGroup({
    gender: new FormControl(this.genderOptions[0].value),
    inputDemo1: new FormControl(''),
    inputDemo2: new FormControl(''),
    acceptTerms: new FormControl(false),
    toggleTest: new FormControl(false),
  });
  public isModalOpen = false;
  public tabs: TabOptions[] = [
    { title: 'Tab 1', tabReference: 'demo 1' },
    { title: 'Tab 2', tabReference: 'demo 2' },
    { title: 'Tab 3', tabReference: 'demo 3' },
    { title: 'Tab 4', iconName: 'edit', tabReference: 'demo 4' },
    { title: 'Tab 5', badgeValue: 7, tabReference: 'demo 5' },
    { title: 'Tab 6', iconName: 'edit', badgeValue: 7, tabReference: 'demo 6' },
  ];
  public loadingState: LoadingAndFeedbackState = 'none';

  constructor(
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang('de');
  }


  public simulateRequestLoading() {
    setTimeout(() => {
      this.loadingState = 'success';
    }, 2000);
  }

  public onSubmit() {
    console.log(this.showCaseform1.value);
    console.log(this.showCaseform2.value);
  }

  public onSelectValueChanged(value: any) {
    alert(`Select value changed: ${value}`);
  }

  protected readonly ConfigurationKeyNamesEnum = ConfigurationKeyNamesEnum;
}
