<div class="ax-ui-breadcrumb">
  @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
    <p class="ax-ui-breadcrumb__item">
      @if (!last) {
        <a [routerLink]="breadcrumb.route">
          @if (breadcrumb.icon) {
            <ax-ui-icon [name]="breadcrumb.icon" [fontSize]="20" />
          }
          <span><small>{{ breadcrumb.label | translate }}</small></span>
        </a>
      } @else {
        <p class="ax-ui-breadcrumb__item-last">
          @if (breadcrumb.icon) {
            <ax-ui-icon [name]="breadcrumb.icon" [fontSize]="20" />
          }
          <span><small><b>{{ breadcrumb.label | translate }}</b></small></span>
        </p>
      }
    </p>
    @if (!last) {
      <ax-ui-icon name="arrow_forward_ios" [fontSize]="16"/>
    }
  }
</div>
