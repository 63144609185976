<div class="ax-ui-terminassistent fade-in">
  <div class="ax-ui-terminassistent__wrapper flex flex-column gap-16 max-width-896 height-100">
    @if (wizardStepsNavService.showNav) {
      <div class="flex gap-32 center space-between">
        <div class="flex flex-row vertical-center gap-16">
          <ax-ui-button (buttonClick)="wizardStepsNavService.goToPreviousStep()"
                        iconName="arrow_back"
                        [small]="true"
                        [iconOnly]="true"
                        variant="secondary" />
        </div>

        <ax-ui-button (buttonClick)="wizardStepsNavService.goToNextStep()"
                      [disabled]="wizardStepsNavService.wizardStepNavGuard"
                      [class.hidden]="wizardStepsNavService.wizardStepNavGuard"
                      [small]="true"
                      iconName="arrow_forward"
                      [iconOnly]="true"
                      variant="secondary"
                      class="fade-in" />
      </div>
    }
    <ax-ui-terminassistent-wizard-steps />
    <div class="ax-ui-terminassistent__form flex flex-column gap-32 space-between"
         [@terminAssistentRoutesAnimations]="getRouteAnimationData()">
      <router-outlet style="display: none"></router-outlet>
    </div>

  </div>
  @if (wizardStepsNavService.showLoading) {
    <ax-ui-loading-spinner loadingState="loading" [pageOverlay]="true" [iconSize]="40" />
  }
</div>

<ax-ui-modal #feedbackFormModal
             (modalClosed)="navigateByOptOutRoute()"
             titleText="Keinen Termin gefunden?"
             modalId="feedbackFormModal"
             modalWidth="large">
  <ng-container modal-body>
    <form (ngSubmit)="submitFeedback()"
          [formGroup]="terminAssisstentFeedbackformGroup"
          class="flex flex-column gap-16">
      <h3 class="ax-web-text--larger">Wunschtermin</h3>
      <p>Geben Sie uns Ihren <b>Wunschtermin</b> und eine <b>Kontaktmöglichkeit</b> an und wir melden uns mit einer Lösung bei Ihnen:</p>
      <div class="ax-ui-terminassistent__contact-inputs flex gap-24 tablet__column margin-top-8 margin-bottom-48">
        <ax-ui-input-field type="datetime-local"
                           [disableError]="true"
                           label="Datum & Uhrzeit"
                           formControlName="dateTime" />
        <ax-ui-input-field type="email"
                           [disableError]="true"
                           [autoComplete]="true"
                           label="E-Mail"
                           [placeholder]="terminAssisstentFeedbackformGroup.controls.dateTime.value ? 'Pflichfeld' : ''"
                           formControlName="email" />
        <ax-ui-input-field type="tel"
                           [disableError]="true"
                           [autoComplete]="true"
                           label="Telefonnummer"
                           [placeholder]="terminAssisstentFeedbackformGroup.controls.dateTime.value ? 'Pflichfeld' : ''"
                           formControlName="phoneNumber" />
      </div>
      <h3 class="ax-web-text--larger">Feedback</h3>
      <p>Wir würden uns über ein kurzes Feedback freuen, warum Sie keinen Beratungstermin abgeschlossen haben.</p>
      <fieldset>
        <legend><small><b>Wählen Sie Zutreffendes aus (Mehrfachauswahl möglich):</b></small></legend>
        <ax-ui-checkbox formControlName="feedbackOption1"
                        [label]="feedbackOption1NoFittingAppointment" />
        <ax-ui-checkbox formControlName="feedbackOption2"
                        [label]="feedbackOption2LocationNotServed" />
        <ax-ui-checkbox formControlName="feedbackOption3"
                        [label]="feedbackOption3IssuesOrTooComplicated" />
      </fieldset>

      <ax-ui-input-field variant="textarea"
                         [disableError]="true"
                         label="Etwas Anderes/weitere Bemerkungen"
                         formControlName="additionalComments" />
    </form>
  </ng-container>
  <ng-container modal-footer>
    <ax-ui-button (buttonClick)="submitFeedback()"
                  [fullWidthMobile]="true"
                  [disabled]="terminAssisstentFeedbackformGroup.invalid || terminAssisstentFeedbackformGroup.untouched"
                  text="Feedback senden" />
    <ax-ui-button (click)="feedbackFormModal.close();"
                  [fullWidthMobile]="true"
                  [text]="'SCHLIESSEN' | translate"
                  variant="secondary" />
  </ng-container>
</ax-ui-modal>

