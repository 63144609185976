/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxContact } from '../models/ax-contact';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxProject } from '../models/ax-project';
import { AxSearchResultContactDto } from '../models/ax-search-result-contact-dto';

@Injectable({ providedIn: 'root' })
export class AxContactsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactsControllerFindAll()` */
  static readonly ContactsControllerFindAllPath = '/v1/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAll$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 20
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$in</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC'>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'id'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindAllPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.id', params['filter.id'], {});
      rb.query('sortBy', params.sortBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxContact>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'id'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAll(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 20
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by id query param.
     *           <p>
     *              <b>Format: </b> filter.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.id=$not:$like:John Doe&filter.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$in</li></ul>
     */
      'filter.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC'>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'id'?: (string | Array<string>);
};
};
}> {
    return this.contactsControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'id'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'id'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `contactsControllerSearch()` */
  static readonly ContactsControllerSearchPath = '/v1/contacts/search/{keyword}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSearch$Response(
    params: {
      keyword: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxSearchResultContactDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerSearchPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxSearchResultContactDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSearch(
    params: {
      keyword: string;
    },
    context?: HttpContext
  ): Observable<Array<AxSearchResultContactDto>> {
    return this.contactsControllerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxSearchResultContactDto>>): Array<AxSearchResultContactDto> => r.body)
    );
  }

  /** Path part for operation `contactsControllerFindOne()` */
  static readonly ContactsControllerFindOnePath = '/v1/contacts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOne$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxContact>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxContact>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOne(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AxContact> {
    return this.contactsControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxContact>): AxContact => r.body)
    );
  }

  /** Path part for operation `contactsControllerFindProjects()` */
  static readonly ContactsControllerFindProjectsPath = '/v1/contacts/{id}/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindProjects$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxProject>>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindProjectsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxProject>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindProjects(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxProject>> {
    return this.contactsControllerFindProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxProject>>): Array<AxProject> => r.body)
    );
  }

}
