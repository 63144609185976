import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  TOOLTIP_DATA,
  TooltipContainerComponent
} from '../../components/tooltip-container/tooltip-container.component';
import { fromEvent, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[axUiTooltip]',
  standalone: true
})
export class TooltipDirective implements OnDestroy {
  @Input() axUiTooltip: string | TemplateRef<void> | undefined;
  @Input() axUiTooltipMaxWidth: number | undefined;
  @Input() axUiTooltipOpenOnClick = false;

  private overlayRef: OverlayRef | null = null;
  private outsideClickListener!: Subscription;
  private tooltipAttached = false;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  showTooltip(): void {
    if (this.axUiTooltip === '' || this.overlayRef?.hasAttached() || this.axUiTooltipOpenOnClick) {
      return;
    }
    this.attachTooltip();
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  hideTooltipOnMouseLeave(): void {
    if (!this.axUiTooltipOpenOnClick && this.tooltipAttached) {
      this.hideTooltip();
    }
  }

  @HostListener('click')
  toggleTooltipOnClick(): void {
    if (!this.axUiTooltipOpenOnClick) return;
    if (this.overlayRef?.hasAttached()) {
      this.hideTooltip();
    } else {
      this.attachTooltip();
      this.listenForOutsideClicks();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener('scroll', this.hideTooltip.bind(this), true);
    }
  }

  private attachTooltip(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.hideTooltip.bind(this), true);
    }

    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: this.axUiTooltip
        }
      ]
    });
    const component = new ComponentPortal(TooltipContainerComponent, this.viewContainer, injector);
    const componentRef = this.overlayRef.attach(component);

    if (this.axUiTooltipMaxWidth) {
      componentRef.instance.maxWidth = this.axUiTooltipMaxWidth;
    }
    this.tooltipAttached = true;
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: 'top',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: 'bottom'
        }
      ]);
  }

  private hideTooltip(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef?.detach();
      this.removeOutsideClickListener();
      if (isPlatformBrowser(this.platformId)) {
        window.removeEventListener('scroll', this.hideTooltip.bind(this), true);
      }
    }
    this.tooltipAttached = false;
  }

  private listenForOutsideClicks(): void {
    if (this.outsideClickListener) {
      this.outsideClickListener.unsubscribe();
    }

    this.outsideClickListener = fromEvent<MouseEvent>(document, 'click')
      .subscribe(event => {
        const clickTarget = event.target as HTMLElement;
        if (this.overlayRef && !this.element.nativeElement.contains(clickTarget) && this.overlayRef.hasAttached()) {
          this.hideTooltip();
        }
      });
  }

  private removeOutsideClickListener(): void {
    if (this.outsideClickListener) {
      this.outsideClickListener.unsubscribe();
    }
  }
}
