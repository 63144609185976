import { Component, Input, OnInit } from '@angular/core';
import {
  AxInteraction,
  AxInteractionRelationTypeEnum,
  AxInteractionsV2Service,
} from '@axova-frontend-monorepo/axova-rest-api';
import { FormatDatePipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AvatarComponent } from '../avatar/avatar.component';
import { lastValueFrom } from 'rxjs';
import { ExceptionHandlingService } from '../../services/exception-handling/exception-handling.service';
import { AccordionItemComponent } from '../accordion/accordion-item/accordion-item.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ax-ui-interactions-timeline',
  templateUrl: './interactions-timeline.component.html',
  styleUrls: ['./interactions-timeline.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    IconComponent,
    RouterLink,
    TranslateModule,
    FormatDatePipe,
    AvatarComponent,
    PrependServerToFileSrcPipe,
    AccordionItemComponent,
    NgTemplateOutlet,
    ButtonComponent,
  ],
})
export class InteractionsTimelineComponent implements OnInit {
  @Input() relationType!: AxInteractionRelationTypeEnum;
  @Input() relationTypeId!: number;
  @Input() interactions: AxInteraction[] = [];
  @Input() hideTitle = false;
  @Input() tableStyle = false;
  @Input() accordionView = false;

  constructor(
    private readonly axInteractionsV2Service: AxInteractionsV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService,
  ) {
  }

  async ngOnInit() {
    if (this.interactions.length === 0) {
      await this.loadInteractionsHistory();
    }
  }

  public async loadInteractionsHistory() {
    try {
      this.interactions = await lastValueFrom(this.axInteractionsV2Service.interactionsControllerFindByRelationType({
        relationType: this.relationType,
        relationTypeId: this.relationTypeId,
      }));
    } catch (getInteractionsException) {
      await this.exceptionHandlingService.handleExceptionWithErrorPageRedirect('getInteractionsException', getInteractionsException);
    }
  }

  public getBadgeIconName(interactiontypeId: number | null, index: number): string {
    switch (interactiontypeId) {
      case 2:
        switch (this.interactions[index].activitytypeId) {
          case 2:
            return 'mail';
          case 3:
            return 'handshake';
          case 4:
            return 'local_post_office';
          case 5:
            return 'calendar_month';
          default: {
            return 'headset_mic';
          }
        }
      case 3:
        return 'task_alt';
      default:
        return 'sticky_note_2';
    }
  }
}
