import { afterNextRender, AfterViewInit, Component, effect, ElementRef, input, Input, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'ax-ui-chart-radial-bar',
  standalone: true,
  imports: [],
  templateUrl: './chart-radial-bar.component.html',
  styleUrl: './chart-radial-bar.component.scss',
})
export class ChartRadialBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chartComponent', { static: true }) chartComponent!: ElementRef<HTMLElement>;
  public chartValue = input.required<number>();
  @Input({ required: true }) chartDomId!: string;
  @Input() chartLabel = '';
  @Input() showDataOnInit = false;
  @Input() showToolbar = false;
  @Input() height = 350;
  @Input() fontsizeValue = '32px';
  @Input() offsetYValue = 0;
  private chart: ApexCharts | undefined;
  private ApexCharts: any;
  private observer: IntersectionObserver | undefined;
  private chartInView = false;

  constructor() {
    let chartOptions: any = {
      series: [0],
    };

    afterNextRender(async () => {
      chartOptions = {
        ...chartOptions,
        series: [0],
        chart: {
          height: this.height,
          type: 'radialBar',
          fontFamily: 'Gotham Narrow Regular, Tahoma, Verdana, sans-serif',
          toolbar: {
            show: this.showToolbar,
          },
          animations: {
            easing: 'easeinout',
            speed: 350,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            },
            dataLabels: {
              name: {
                offsetY: 44,
                color: '#10260D',
                fontSize: '16px',
              },
              value: {
                offsetY: this.offsetYValue,
                fontFamily: 'Gotham Narrow Bold, Tahoma, Verdana, sans-serif',
                color: '#10260D',
                fontSize: this.fontsizeValue,
              },
            },
          },
        },
        fill: {
          colors: ['#51AC3B'],
        },
        labels: [''],
        stroke: {
          lineCap: 'round',
        },
      };

      if (this.showDataOnInit) {
        chartOptions.series = [this.chartValue()];
        chartOptions?.labels.push(this.chartLabel);
      }

      // use JS import approach, since ApexCharts Angular lib doesn't support SSR yet
      try {
        const apexChartsModule = await import('apexcharts');
        this.ApexCharts = (apexChartsModule as any).default || apexChartsModule;
        this.chart = new this.ApexCharts(document.querySelector(`#${this.chartDomId}`), chartOptions);
        await this.chart?.render();
      } catch (error) {
        // Nothing to catch.
      }
    });

    effect(async () => {
      if (this.chartInView) {
        const value = this.chartValue();
        await this.chart?.updateSeries([value]);
      }
    });
  }

  ngAfterViewInit() {
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.75) {
            this.chartInView = true;
            await this.chart?.updateSeries([this.chartValue()]);
          } else {
            this.chartInView = false;
          }
        },
        { threshold: 0.75 },
      );
      this.observer.observe(this.chartComponent.nativeElement);
    } else {
      // Fallback for environments where IntersectionObserver is not available
      this.chartInView = true;
      this.chart?.updateSeries([this.chartValue()]);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.chartComponent.nativeElement);
    }
  }
}
