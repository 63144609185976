<div class="ax-ui-select-user">
  <div [class.disabled]="disabled"
       [class.editable]="editable"
       [class.error]="invalid"
       [class.icon]="iconName"
       [class.table-input]="tableInput"
       [class.with-label]="label && iconName"
       [style.max-width]="maxWidth + 'px'"
       [style.width]="width + 'px'"
       [class.readonly]="readonly"
       class="ax-ui-select ax-input">
    @if (label) {
      <label>
        <ng-template #labelContent>
          <b>{{ label | translate }}
            @if (required) {
              <b class="ax-ui-required-asterisk"> *</b>
            }</b>
        </ng-template>

        @switch (labelSize) {
          @case ('small') {
            <small>
              <ng-container *ngTemplateOutlet="labelContent" />
            </small>
          }
          @case ('default') {
            <p>
              <ng-container *ngTemplateOutlet="labelContent" />
            </p>
          }
        }
      </label>
    }
    <div (click)="openModal()"
         [class.disabled]="disabled"
         class="ax-ui-select__custom"
         [class.readonly]="readonly"
         [class.readonly--no-borders]="readonlyNoBorders"
         tabindex="0">
      @if (selectedUser) {
        <ax-ui-infoblock
          [avatarImgSrc]="selectedUser.userImage ? (selectedUser.userImage.fileSrcThumbnail | prependServerToFileSrc) : null"
          [subtitle]="selectedUser.jobtitle"
          [textCenteredVertically]="true"
          [title]="selectedUser.firstName + ' ' + selectedUser.lastName"
          [withAvatar]="true"
          avatarSize="small"
          avatarStatus="none" />
      } @else {
        <span class="placeholder">{{ placeholder | translate }}</span>
      }
      <ax-ui-icon class="arrow-down" name="expand_more" />
    </div>

    @if (iconName) {
      <ax-ui-icon [name]="iconName" />
    }
    @if (!disableHint) {
      <span [class.hide]="hideHint" [style.opacity]="invalid ? '1' : '0'" class="ax-input__hint">
      <small><b>{{ hintMessage }}</b></small>
    </span>
    }
  </div>

  <ax-ui-modal #userListModal
               [noBodyPadding]="true"
               [noFooter]="true"
               [alignedTop]="true"
               [closableOnOutsideClick]="true"
               modalId="userListModal">
    <ng-container modal-body>
      <div class="ax-ui-select-user__modal">
        <div class="padding-left-24 padding-right-72 margin-bottom-16">
          <ax-ui-input-field (valueChange)="search($event)"
                             (keydown)="handleKeyboardNavigation($event)"
                             [debounceTimeInMs]="0"
                             [disableError]="true"
                             [showClearButton]="true"
                             placeholder="SUCHE_MITARBEITERNAME"
                             autofocus
                             iconName="search" />
        </div>
        <div class="ax-ui-select-user__list border-top">
          @for (group of displayedFlatList | groupBy: 'section'; track $index; let last = $last) {
            @if (group['section']) {
              <h5 class="padding-horizontal-24 padding-top-24">
                {{ group ['section'] | translate }}
              </h5>
            }
            <ul class="ax-list padding-12" role="listbox">
              @for (item of group.items; track item.user.id; let i = $index) {
                <li #listItem
                    (click)="selectUser(item.user)"
                    [class.active]="displayedFlatList[activeListIndex].user.id === item.user.id"
                    [attr.tabindex]="displayedFlatList[activeListIndex].user.id === item.user.id ? 0 : -1"
                    class="no-border ax-action-element borders-rounded no-transition"
                    role="option">
                  <ax-ui-infoblock
                    [avatarImgSrc]="item.user.userImage?.fileSrcThumbnail | prependServerToFileSrc"
                    [subtitle]="item.user.jobtitle"
                    [textCenteredVertically]="true"
                    [title]="item.user.firstName + ' ' + item.user.lastName"
                    [withAvatar]="true"
                    avatarSize="small"
                    avatarStatus="none"/>
                </li>
              }
            </ul>
            @if (!last) {
              <hr>
            }
          } @empty {
            <p class="padding-24">{{ 'KEINE_TREFFER_BEI_DIESER_SUCHE' | translate }}</p>
          }
        </div>
      </div>
    </ng-container>
  </ax-ui-modal>
</div>