import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { FilterStateSetFilterOptions } from './filter.actions';
import { FilterStateModel } from './filter-state.model';
import { DropdownOption, ConfigurationKeyNamesEnum } from '@axova-frontend-monorepo/axova-commons';

const DEFAULT_STATE: FilterStateModel = {
  filters: [],
};

@State<FilterStateModel>({
  name: 'ax_state__filter',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class FilterState {

  static singleFilterOptions(filterName: ConfigurationKeyNamesEnum): (state: FilterStateModel) => DropdownOption[] {
    return createSelector(
      [FilterState],
      (state: FilterStateModel) => {
        return state.filters?.find(filter => filter.name === filterName)?.options || [];
      },
    );
  }

  @Action(FilterStateSetFilterOptions)
  FilterStateSetFilterOptions(ctx: StateContext<FilterStateModel>, action: FilterStateSetFilterOptions) {
    const currentState = ctx.getState();
    // Ensure filters is always treated as an array
    const currentFilters = Array.isArray(currentState.filters) ? currentState.filters : [];
    const existingFilterIndex = currentFilters.findIndex(filter => filter.name === action.filter.name);

    if (existingFilterIndex > -1) {
      // Filter exists, update it
      const updatedFilters = currentFilters.map((filter, index) => {
        if (index === existingFilterIndex) {
          return { ...filter, options: action.filter.options };
        }
        return filter;
      });
      ctx.patchState({ filters: updatedFilters });
    } else {
      // Filter does not exist, add it
      const newFilters = [...currentFilters, action.filter];
      ctx.patchState({ filters: newFilters });
    }
  }
}
