import { Directive, ElementRef, EventEmitter, HostListener, Injector, Input, NgZone, OnDestroy, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MODAL_OVERLAY_DATA, ModalFloatingContainerComponent } from '../../components/modal-floating-container/modal-floating-container.component';
import { ModalFloatingInstanceManagementService } from '../../services/modal-floating-instance-management.service';
import { dropDownAlignment } from '../../components/dropdown-list/dropdown-list.component';

@Directive({
    selector: '[axUiModalFloating]',
    standalone: true,
})
export class ModalFloatingDirective implements OnDestroy {
  @Input({ required: true }) axUiModalFloating!: TemplateRef<void>;
  @Input() axUiModalFloatingPosition: dropDownAlignment = "left";

  @Output() axUiModalFloatingOpening: EventEmitter<any> = new EventEmitter<any>();

  private overlayRef: OverlayRef | null = null;
  private readonly outsideClickListener: (event: MouseEvent) => void;

  constructor(
    private element: ElementRef<HTMLElement>,
    private overlay: Overlay,
    private viewContainer: ViewContainerRef,
    private ngZone: NgZone,
    private modalFloatingInstanceManagementService: ModalFloatingInstanceManagementService,
  ) {
    this.outsideClickListener = this.outsideClick.bind(this);
  }

  @HostListener('click', ['$event'])
  showModalOverlay(event: MouseEvent): void {
    // Prevent the click from closing the overlay immediately after opening
    event.stopPropagation();
    if (this.overlayRef?.hasAttached()) {
      this.hideModalOverlay();
      return;
    }
    this.attachModalOverlay();
    this.listenForOutsideClicks();
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
    this.removeClickListener();
    this.modalFloatingInstanceManagementService.clearActiveOverlay(this);
    window.removeEventListener('scroll', this.hideModalOverlay.bind(this), true);
  }

  public hideModalOverlay(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
      this.removeClickListener();
      this.modalFloatingInstanceManagementService.clearActiveOverlay(this);
      window.removeEventListener('scroll', this.hideModalOverlay.bind(this), true);
    }
  }

  private attachModalOverlay(): void {
    this.axUiModalFloatingOpening.emit();
    window.addEventListener('scroll', this.hideModalOverlay.bind(this), true);
    this.modalFloatingInstanceManagementService.setActiveOverlay(this);

    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
      this.overlayRef.backdropClick().subscribe(() => this.hideModalOverlay());
    }

    const injector = Injector.create({
      providers: [
        {
          provide: MODAL_OVERLAY_DATA,
          useValue: this.axUiModalFloating,
        },
      ],
    });
    const component = new ComponentPortal(ModalFloatingContainerComponent, this.viewContainer, injector);
    this.overlayRef.attach(component);
  }

  private getPositionStrategy(): PositionStrategy {
    let positionConfig: ConnectedPosition;

    switch (this.axUiModalFloatingPosition) {
      case 'right':
        positionConfig = {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 4,
          offsetY: -4,
        };
        break;

      case 'bottom':
        positionConfig = {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: 4,
        };
        break;

      default:
        positionConfig = {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: -4,
          offsetY: -4,
        };
        break;
    }

    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([positionConfig]);
  }

  private listenForOutsideClicks(): void {
    // Run outside Angular to prevent performance issues
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('click', this.outsideClickListener);
    });
  }

  private outsideClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    // Check if the click is outside the overlay element
    if (this.overlayRef && !this.overlayRef.overlayElement.contains(targetElement)) {
      // Run inside Angular zone to ensure change detection works properly
      this.ngZone.run(() => this.hideModalOverlay());
    }
  }

  private removeClickListener(): void {
    document.removeEventListener('click', this.outsideClickListener);
  }
}
