import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutofocusDirective, NavigationGroupInterface } from '@axova-frontend-monorepo/axova-commons';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '../inputs/input-field/input-field.component';
import { ButtonComponent } from '../button/button.component';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'ax-ui-inline-nav-list',
  templateUrl: './inline-nav-list.component.html',
  styleUrls: ['./inline-nav-list.component.scss'],
  standalone: true,
  imports: [ButtonComponent, InputFieldComponent, RouterLinkActive, RouterLink, TranslateModule, IconComponent, AutofocusDirective],
})
export class InlineNavListComponent implements OnInit {
  @Input({ required: true }) navigationItems: NavigationGroupInterface[] = [];
  @Input() showToggleButton = false;
  @Input() automaticallyOpenFirstRoute = true;
  @Input() sortAlphabetically = false;
  @Input() sortAlphabeticallyInnerItems = false;
  @Output() menuClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  public navigationItemsFiltered: NavigationGroupInterface[] = [];
  public closeMenu = false;

  constructor(private readonly router: Router, private readonly store: Store) {}

  async ngOnInit() {
    this.navigationItemsFiltered = this.navigationItems.sort((a, b) => {
      if (this.sortAlphabetically) {
        const textA = a.title ? a.title.toLowerCase() : a.items[0].label.toLowerCase();
        const textB = b.title ? b.title.toLowerCase() : b.items[0].label.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }
      return 0;
    });
    this.navigationItemsFiltered.forEach((group) => {
      if (this.sortAlphabeticallyInnerItems) {
        group.items = group.items.sort((innerA, innerB) => {
          const textA = innerA.label.toLowerCase();
          const textB = innerB.label.toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      }
    });
    this.navigationItemsFiltered = this.navigationItems.filter((navigationGroup: NavigationGroupInterface) => {
      navigationGroup.items = navigationGroup.items.filter((item) => {
        if (item.requiredPermission) {
          return this.store.selectSnapshot(ProfileState.hasPermission(item.requiredPermission));
        }
        return true;
      });
      return navigationGroup.items.length > 0;
    });
    const outlet = this.navigationItemsFiltered[0].items[0].outlet;
    const route = this.navigationItemsFiltered[0].items[0].route;

    if (!this.router.url.includes(`/(${outlet}`) && this.automaticallyOpenFirstRoute) {
      // open the first list item per default
      await this.router.navigate([this.router.url, { outlets: { [outlet || '']: route } }]);
    }
  }

  public getRouterLink(navigationItem: any) {
    return [{ outlets: { [navigationItem.outlet]: [navigationItem.route] } }];
  }

  public async search(searchterm: string) {
    let filterResult: NavigationGroupInterface[] = [];
    if (searchterm) {
      filterResult = this.navigationItems
        .map((group: NavigationGroupInterface) => {
          const filteredItems = group.items.filter((item) => item.label.toUpperCase().includes(searchterm.toUpperCase()));

          // Only return the group if there are matching items
          return {
            ...group,
            items: filteredItems, // Always return the group, even if items array is empty
          };
        })
        .filter((group) => group.items.length > 0); // Remove groups that have no matching items
    }
    this.navigationItemsFiltered = filterResult.length > 0 ? filterResult : this.navigationItems;
  }

  public toggleMenu() {
    this.closeMenu = !this.closeMenu;
    this.menuClosed.emit(this.closeMenu);
  }
}
