<ax-ui-widget-base [route]="route"
                   iconName="sticky_note_2"
                   title="{{'NOTIZEN' | translate}}">
  <div class="ax-ui-widget-notizen">
    <form>
      <ax-ui-input-field (valueChange)="saveNoteChange($event)"
                         [setAutoHeight]="false"
                         [disableError]="true"
                         [placeholder]="'GIB_EINE_NOTIZ_EIN' | translate"
                         variant="textarea" />
    </form>
  </div>
</ax-ui-widget-base>
