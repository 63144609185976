/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxLocation } from '../models/ax-location';
import { AxSearchChResultDto } from '../models/ax-search-ch-result-dto';
import { AxUpdateLocationDto } from '../models/ax-update-location-dto';

@Injectable({ providedIn: 'root' })
export class AxLocationsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `locationsControllerGetAllDropdownOptions()` */
  static readonly LocationsControllerGetAllDropdownOptionsPath = '/v2/locations/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxLocationsV2Service.LocationsControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.locationsControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `locationsControllerGetAllDropdownOptionsCantons()` */
  static readonly LocationsControllerGetAllDropdownOptionsCantonsPath = '/v2/locations/dropdown-options/cantons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetAllDropdownOptionsCantons()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetAllDropdownOptionsCantons$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxLocationsV2Service.LocationsControllerGetAllDropdownOptionsCantonsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetAllDropdownOptionsCantons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetAllDropdownOptionsCantons(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.locationsControllerGetAllDropdownOptionsCantons$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `locationsControllerUpdate()` */
  static readonly LocationsControllerUpdatePath = '/v2/locations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateLocationDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxLocation>> {
    const rb = new RequestBuilder(this.rootUrl, AxLocationsV2Service.LocationsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxLocation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateLocationDto
    },
    context?: HttpContext
  ): Observable<AxLocation> {
    return this.locationsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxLocation>): AxLocation => r.body)
    );
  }

  /** Path part for operation `locationsControllerSearchOnSearchCh()` */
  static readonly LocationsControllerSearchOnSearchChPath = '/v2/locations/searchOnSearchCh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerSearchOnSearchCh()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerSearchOnSearchCh$Response(
    params: {
      searchterm: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxSearchChResultDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxLocationsV2Service.LocationsControllerSearchOnSearchChPath, 'get');
    if (params) {
      rb.query('searchterm', params.searchterm, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxSearchChResultDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerSearchOnSearchCh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerSearchOnSearchCh(
    params: {
      searchterm: string;
    },
    context?: HttpContext
  ): Observable<Array<AxSearchChResultDto>> {
    return this.locationsControllerSearchOnSearchCh$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxSearchChResultDto>>): Array<AxSearchChResultDto> => r.body)
    );
  }

}
