import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { LoggerService } from '../services/logger.service';

@Directive({
  selector: '[axAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef<HTMLInputElement>, private readonly renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    try {
      const inputElement = this.elementRef.nativeElement.querySelector('input');
      if (inputElement) {
        this.renderer2.selectRootElement(inputElement).focus();
      }
    } catch (noNativeElement) {
      LoggerService.LOG(this, 'axAutofocus directive not working on this type of element.');
    }
  }
}
