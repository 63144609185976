<div class="ax-ui-gallery flex flex-column gap-48">
  @if (documentsByDate.length > 0) {
    <div class="ax-ui-gallery__grid">
      @for (day of documentsByDate; track day.date; let first = $first) {
        <p class="ax-ui-gallery__title margin-bottom-8"
           [class.margin-top-48]="!first">
          <b>{{ day.date | formatDate: 'dddd, DD.MM.YYYY' }}</b>
        </p>
        @for (document of day.documents; track document.id) {
          <div class="ax-ui-gallery__item">
            <img [src]="document.fileSrcOptimized | prependServerToFileSrc"
                 [alt]="document.name">
          </div>
        }
      }
    </div>
  } @else {
    <div class="color-light centered flex flex-column gap-16">
      <ax-ui-icon name="no_photography"
                    [fontSize]="98" />
      <p>{{ 'KEINE_BILDER_VORHANDEN' | translate }}</p>
    </div>
  }
</div>
