import { ButtonVariant } from '../components/button/button.component';

export interface ConfirmActionModalOptions {
  title?: string;
  infoText?: string;
  hideCloseButton?: boolean;
  buttons?: ConfirmActionModalButton[];
}

export interface ConfirmActionModalButton {
  label: string;
  variant: ButtonVariant;
  value: string | number | boolean | null;
  role?: 'action' | 'cancel';
}
