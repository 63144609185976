<div class="margin-bottom-72">
  <h2 class="ax-web-text--large margin-bottom-24">Ihre Kontaktinformationen</h2>
  <p><small>Bitte beachten Sie, dass wir Ihre Daten anschliessend überprüfen werden. Sofern alles in Ordnung ist,
    reservieren wir den Termin definitiv und freuen uns darauf, Sie persönlich kennenzulernen.</small></p>

  <form class="margin-top-48 flex flex-column gap-64" (ngSubmit)="onSubmit(true)" [formGroup]="formGroup">
    <!--Kontaktdaten-->
    <div class="width-100">
      <h3 class="ax-web-text--medium margin-bottom-4">Ihre Kontaktdaten</h3>
      <div class="margin-top-24">
        <div class="ax-input__flexbox">
          <ax-ui-select [options]="dropdownOptionstitle"
                        [required]="true"
                        hintMessage="Pflichtfeld"
                        formControlName="titleId"
                        label="Anrede" />
        </div>

        @if (formGroup.controls.titleId.getRawValue() === 4) {
          <div class="ax-input__flexbox fade-in">
            <ax-ui-input-field [required]="true"
                               [autoComplete]="true"
                               hintMessage="Pflichtfeld"
                               label="Firmenname"
                               formControlName="company" />
          </div>
        }

        <div class="ax-input__flexbox">

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             label="Vorname"
                             formControlName="firstName" />

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             label="Nachname"
                             formControlName="lastName" />

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             label="Telefonnummer"
                             type="tel"
                             [validateSwissPhoneNumbers]="true"
                             formControlName="phone" />

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             label="E-Mail"
                             type="email"
                             formControlName="email" />
        </div>
      </div>
    </div>

    <hr>

    <!--Objektadresse-->
    <div class="width-100">
      <h3 class="ax-web-text--medium margin-bottom-4">Objektadresse der Besichtigung</h3>
      <p><small>Das ist der Ort, an welchem der Termin stattfindet.</small></p>
      <div class="margin-top-24">
        <div class="ax-input__flexbox">
          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             label="Strasse"
                             formControlName="objectStreet" />

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             [disabled]="!hasApiKey"
                             label="Postleitzahl"
                             formControlName="objectZip" />

          <ax-ui-input-field [required]="true"
                             [autoComplete]="true"
                             [disabled]="!hasApiKey"
                             label="Ort"
                             formControlName="objectCity" />

          @if ((dropdownOptionsCantons$ | async)?.length) {
            <ax-ui-select
              [options]="(dropdownOptionsCantons$ | async) ?? []"
              [required]="true"
              [disabled]="!hasApiKey"
              formControlName="objectCanton"
              label="Kanton" />
          }
        </div>
      </div>
    </div>

    <hr>

    <!--Kontakt Adresse-->
    <div class="width-100">
      <h3
        class="ax-web-text--medium margin-bottom-4">{{ formGroup.controls.titleId.getRawValue() === 4 ? 'Firmenadresse' : 'Wohnadresse' }}</h3>
      <ax-ui-checkbox class="margin-top-16"
                      label="Meine Kontaktadresse stimmt mit der oben angegebenen Objektadresse überein"
                      (valueChanged)="changeIsSeparateObjectAddress($event)"
                      [checked]="!isSeparateObjectAddress" />

      @if (isSeparateObjectAddress) {
        <div class="fade-in margin-top-24">
          <p>Bitte geben Sie hier
            Ihre {{ formGroup.controls.titleId.getRawValue() === 4 ? 'Firmenadresse' : 'Wohnadresse' }} an:</p>
          <div class="margin-top-24">
            <div class="ax-input__flexbox">
              <ax-ui-input-field [required]="true"
                                 [autoComplete]="true"
                                 label="Strasse"
                                 formControlName="street" />

              <ax-ui-input-field [required]="true"
                                 [autoComplete]="true"
                                 label="Postleitzahl"
                                 formControlName="zipcode" />

              <ax-ui-input-field [required]="true"
                                 [autoComplete]="true"
                                 label="Ort"
                                 formControlName="city" />

              @if ((dropdownOptionsCantons$ | async)?.length) {
                <ax-ui-select
                  [options]="(dropdownOptionsCantons$ | async) ?? []"
                  [required]="true"
                  formControlName="canton"
                  label="Kanton" />
              }
            </div>
          </div>
        </div>
      }
    </div>

    <hr>

    <!--Bemerkungen-->
    <div class="width-100">
      <h3 class="ax-web-text--medium">Bemerkungen</h3>
      <ax-ui-input-field placeholder="Möchten Sie uns sonst noch etwas mitteilen?"
                         variant="textarea"
                         [disableHint]="true"
                         formControlName="notes" />
    </div>

    <hr>

    <!--Datenschutzbestimmungen-->
    <div class="width-100">
      <div class="flex gap-12 vertical-center">
        <ax-ui-icon name="policy" [boldness]="400" [fontSize]="32" />
        <a href="https://www.axova.ch/datenschutz/"
           class="ax-link--underlined"
           target="_blank">Axova Datenschutzbedingungen</a>
      </div>

      <ax-ui-checkbox class="margin-top-32"
                      label="Ich bestätige hiermit die Datenschutzbedingungen von Axova AG gelesen zu haben und akzeptiere diese zwecks Verarbeitung meiner personenbezogenen Daten."
                      (valueChanged)="acceptTerms($event)"
                      [checked]="isTermsAccepted" />
      <p><small>(Bitte akzeptieren, um fortfahren zu können)</small></p>
    </div>

    <ax-ui-button text="weiter"
                  type="submit"
                  iconName="arrow_forward"
                  [iconPositionRight]="true"
                  [alignRight]="true"
                  [fullWidthMobile]="true"
                  [disabled]="formGroup.invalid || !isTermsAccepted" />
  </form>
</div>
