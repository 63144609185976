import { afterNextRender, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconService } from './icon.service';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

export type IconColorVariant = 'neutral' | 'success' | 'warning' | 'error' | undefined;
export type IconWeight = 300 | 400;

@Component({
  selector: 'ax-ui-icon',
  standalone: true,
  imports: [CommonModule, TooltipDirective],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input({ required: true }) name = '';
  @Input() fontSize = 24;
  @Input() boldness: IconWeight = 300;
  @Input() filled = false;
  @Input() framed = false;
  @Input() title = '';
  @Input() theme: IconColorVariant = undefined;
  @Input() fontColor: IconColorVariant = undefined;
  @Input() paddingSize = 8;
  public isMaterialSymbolsFontLoaded = false;

  constructor(
    private readonly iconService: IconService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    afterNextRender(() => {
      this.iconService.isMaterialSymbolsFontLoaded.pipe().subscribe({
        next: isLoaded => {
          this.isMaterialSymbolsFontLoaded = isLoaded;
          this.changeDetectorRef.detectChanges();
        },
      });
    });
  }

  ngOnInit() {
    if (this.theme && !this.fontColor) {
      this.fontColor = this.theme;
    }
  }

  get maxWidth(): number {
    if (this.fontSize) {
      let maxWidth = this.fontSize;
      if (this.framed || this.theme !== undefined) {
        // +2 for border width
        maxWidth += (2 * this.paddingSize) + 2;
      }
      return maxWidth;
    }
    return 0;
  }
}
