import { Component, QueryList, ViewChildren } from '@angular/core';
import {
  CheckboxComponent,
  InputFieldComponent,
  LoadingAndFeedbackState,
  SelectComponent,
  TableBase,
  TableFooterComponent,
  TableHeadersComponent,
  TableHeaderItem,
  TablePaginationOptions,
} from '@axova-frontend-monorepo/axova-ui';

@Component({
  selector: 'ax-component-library-table-demo',
  templateUrl: './table-demo.component.html',
  styleUrls: ['./table-demo.component.scss'],
  standalone: true,
  imports: [
    TableHeadersComponent,
    InputFieldComponent,
    SelectComponent,
    CheckboxComponent,
    TableFooterComponent,
  ],
})
export class TableDemoComponent implements TableBase<any> {
  @ViewChildren(TableHeadersComponent) tableHeaders!: QueryList<TableHeadersComponent>;
  public tableHeaderItems: TableHeaderItem[] = [
    {
      label: 'Name',
      sortable: true,
      name: 'name',
    },
    {
      label: 'Menge',
      sortable: true,
      name: 'amount',
    },
    {
      label: 'EP',
      sortable: true,
      name: 'ep',
    },
    {
      label: 'Summe',
      sortable: true,
      name: 'sum',
    },
    {
      label: 'Bemerkungen',
      sortable: true,
      name: 'notes',
    },
    {
      label: 'Lagerort',
      sortable: true,
      name: 'storageSite',
    },
    {
      label: 'Sichtbar für Kunde',
      sortable: false,
      name: 'viewableForCustomer',
    },
    {
      label: 'Fehlt bei IBN',
      sortable: false,
      name: 'missingAtIbn',
    },
  ];
  paginationOptions: TablePaginationOptions = {
    itemsPerPage: 25,
    totalItems: 1000,
    currentPage: 3,
  };
  public data: any[] = [];
  public loadingState: LoadingAndFeedbackState = 'none';
  public filterDropdownOptions = [];
  protected readonly console = console;

  public sort(tableHeaderItems: TableHeaderItem) {
    console.log(tableHeaderItems);
  }

  public simulateRequestLoading() {
    setTimeout(() => {
      this.loadingState = 'success';
    }, 2000);
  }
}
