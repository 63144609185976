import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LoadingAndFeedbackState } from '../../models/loading-and-feedback-state.type';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { IconComponent } from '../icon/icon.component';
import { DndDirective } from '../../directives/drag-and-drop/dnd.directive';

@Component({
    selector: 'ax-ui-dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss'],
    standalone: true,
    imports: [
        DndDirective,
        IconComponent,
        LoadingSpinnerComponent,
        TranslateModule,
    ],
})
export class DropzoneComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;
  @Output() filesReceived: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() multiple = false;
  @Input() hasLoadingState = false;
  @Input() disabled = false;
  @Input() loadingState: LoadingAndFeedbackState = 'none';
  @Input() acceptPdf = false;
  @Input() acceptImage = false;
  @Input() acceptWord = false;
  @Input() acceptPowerpoint = false;
  @Input() acceptExcel = false;
  @Input() acceptCsv = false;

  public get accept() {
    const accept = [];
    this.acceptPdf ? accept.push('.pdf') : null;
    this.acceptImage ? accept.push('.jpeg', '.jpg', '.png', '.gif', '.heic') : null;
    this.acceptWord ? accept.push('.docx') : null;
    this.acceptPowerpoint ? accept.push('.pptx') : null;
    this.acceptExcel ? accept.push('.xlsx') : null;
    this.acceptCsv ? accept.push('.csv') : null;
    return accept;
  }

  /**
   * on file drop handler
   */
  public onFilesDropped($event: any) {
    this.prepareFilesList($event as File[]);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(files: any) {
    this.prepareFilesList(files.target.files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  public prepareFilesList(files: File[]) {
    if (this.hasLoadingState) {
      this.loadingState = 'loading'
    }
    this.filesReceived.emit(files);
  }
}
