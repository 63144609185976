import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getEquipmenttypeIconName',
  standalone: true,
})
export class GetEquipmenttypeIconNamePipe implements PipeTransform {
  transform(equipmenttypeId: number): string {
    switch (equipmenttypeId) {
      case 1:
        return 'directions_car';
      case 2:
        return 'tools_power_drill';
      case 3:
        return 'key';
      case 4:
        return 'build';
      case 5:
        return 'rv_hookup';
      case 6:
        return 'view_timeline';
      case 7:
        return 'credit_card';
      case 8:
        return 'medical_services';
      case 9:
        return 'engineering';
      case 10:
        return 'eyeglasses';
      case 11:
        return 'cable';
      default:
        return 'handyman';
    }
  }
}
