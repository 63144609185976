<div (fileDropped)="onFilesDropped($event)"
     [accept]="accept"
     [class.disabled]="loadingState !== 'none' || disabled"
     [multiple]="multiple"
     axUiDnd
     class="ax-ui-dropzone">
  <input #fileDropRef
         (change)="fileBrowseHandler($event)"
         [accept]="accept.join(',')"
         [multiple]="multiple"
         id="fileDropRef"
         type="file" />
  <div [class.fade-out]="loadingState !== 'none'"
       class="ax-ui-dropzone__content">
    <ax-ui-icon [fontSize]="48"
                [name]="'upload_file'" />
    @if (multiple) {
      <h4>{{ 'DATEIEN_HIERHIN_ZIEHEN' | translate }}</h4>
    }
    @if (!multiple) {
      <h4>{{ 'DATEI_HIERHIN_ZIEHEN' | translate }}</h4>
    }
  </div>
  @if (loadingState !== 'none') {
    <ax-ui-loading-spinner
      [iconSize]="40"
      [loadingState]="loadingState" />
  }
</div>
