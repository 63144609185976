<div #tableFooter class="ax-ui-table-footer">
  @if (!hideItemsPerPageSelect) {
    <ax-ui-select (valueChanged)="setItemsPerPage($event)"
                  [options]="itemsPerPageSelectOption"
                  [tableInput]="true"
                  maxWidth="fit-content"
                  [value]="paginationOptions.itemsPerPage"
                  placeholder="Einträge pro Seite" />
  }
  <nav aria-label="Table navigation">
    <ul>
      @if (totalPages > 10) {
        <li>
          <button (click)="setPage(paginationOptions.currentPage - 1)"
                  [disabled]="paginationOptions.currentPage === 1"
                  rel="prev">
            <ax-ui-icon name="chevron_left" />
          </button>
        </li>
      }
      @if (totalPages > 1) {
        <li>
          <ul class="ax-ui-table-footer-pages">
            @if (totalPages > 10) {
              <li>
                <button (click)="setPage(1)"
                        [disabled]="paginationOptions.currentPage === 1">
                  <ax-ui-icon name="first_page" />
                </button>
              </li>
            }
            @for (number of paginationNumbers; track number) {
              <li>
                <button (click)="setPage(number)"
                        [class.active]="number === paginationOptions.currentPage">
                  {{ number }}
                </button>
              </li>
            }
            @if (totalPages > 10) {
              <li>
                <button (click)="setPage(calculateTotalNumberOfPages)"
                        [disabled]="paginationOptions.currentPage === calculateTotalNumberOfPages">
                  <ax-ui-icon name="last_page" />
                </button>
              </li>
            }
          </ul>
        </li>
      }
      @if (totalPages > 10) {
        <li>
          <button (click)="setPage(paginationOptions.currentPage + 1)"
                  [disabled]="paginationOptions.currentPage === calculateTotalNumberOfPages"
                  rel="next">
            <ax-ui-icon name="chevron_right" />
          </button>
        </li>
      }
    </ul>
  </nav>
  <div>
    <p><small [innerHTML]="'TOTAL_EINTRAEGE' | translate:{ amount: paginationOptions.totalItems }"></small></p>
  </div>
</div>
