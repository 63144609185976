import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { TerminassistentStateModel } from './terminassistent.state.model';
import {
  TerminassistentStateSetApiAppointmentSchedulerExternalKey,
  TerminassistentStateSetApiAppointmentSchedulerKey,
  TerminassistentStateSetLocation,
  TerminassistentStateSetReferralCode,
  TerminassistentStateSetReservation,
  TerminassistentStateSetReservationtype,
  TerminassistentStateSetRouteBeforeTerminassistentStart,
  TerminassistentStateSetSearchedLocation,
  TerminassistentStateSetTimeslot,
  TerminassistentStateSetUtm,
} from './terminassistent.actions';
import * as dayjs from 'dayjs';

const STATE_NAME = 'axovaterminassistent__appState';
const DEFAULT_STATE: TerminassistentStateModel = {};

@State<TerminassistentStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class TerminassistentState implements NgxsOnInit {
  constructor(private readonly router: Router) {}

  @Selector()
  static location(state: TerminassistentStateModel) {
    return state.location;
  }

  @Selector()
  static searchedLocation(state: TerminassistentStateModel) {
    return state.searchedLocation;
  }

  @Selector()
  static reservationtype(state: TerminassistentStateModel) {
    return state.reservationtype;
  }

  @Selector()
  static timeslot(state: TerminassistentStateModel) {
    return state.timeslot;
  }

  @Selector()
  static reservation(state: TerminassistentStateModel) {
    return state.reservation;
  }

  @Selector()
  static accessKey(state: TerminassistentStateModel) {
    return state.reservation?.accessKey;
  }

  @Selector()
  static apiAppointmentSchedulerKey(state: TerminassistentStateModel) {
    return state.apiAppointmentSchedulerKey;
  }

  @Selector()
  static apiAppointmentSchedulerExternalKey(state: TerminassistentStateModel) {
    return state.apiAppointmentSchedulerExternalKey;
  }

  @Selector()
  static utm(state: TerminassistentStateModel) {
    return state.utm;
  }

  @Selector()
  static referralCode(state: TerminassistentStateModel) {
    return state.referralCode;
  }

  @Selector()
  static routeBeforeTerminassistentStart(state: TerminassistentStateModel) {
    return state.routeBeforeTerminassistentStart;
  }

  @Action(TerminassistentStateSetLocation)
  setLocation(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetLocation) {
    ctx.patchState({ location: action.location, lastModifiedAt: new Date() });
  }

  @Action(TerminassistentStateSetUtm)
  setUtm(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetUtm) {
    ctx.patchState({ utm: action.utm });
  }

  @Action(TerminassistentStateSetReferralCode)
  setReferralCode(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetReferralCode) {
    ctx.patchState({ referralCode: action.referralCode });
  }

  @Action(TerminassistentStateSetSearchedLocation)
  setSearchedLocation(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetSearchedLocation) {
    ctx.patchState({ searchedLocation: action.searchedLocation, lastModifiedAt: new Date() });
  }

  @Action(TerminassistentStateSetReservationtype)
  setReservationtype(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetReservationtype) {
    ctx.patchState({ reservationtype: action.reservationtype, lastModifiedAt: new Date() });
  }

  @Action(TerminassistentStateSetTimeslot)
  setTimeslot(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetTimeslot) {
    ctx.patchState({ timeslot: action.timeslot, lastModifiedAt: new Date() });
  }

  @Action(TerminassistentStateSetReservation)
  setReservation(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetReservation) {
    ctx.patchState({
      reservation: {
        ...ctx.getState().reservation,
        ...action.reservation,
      },
      lastModifiedAt: new Date(),
    });
  }

  @Action(TerminassistentStateSetApiAppointmentSchedulerKey)
  setApiAppointmentSchedulerKey(ctx: StateContext<TerminassistentStateModel>, action: TerminassistentStateSetApiAppointmentSchedulerKey) {
    ctx.patchState({ apiAppointmentSchedulerKey: action.apiAppointmentSchedulerKey, lastModifiedAt: new Date() });
  }

  @Action(TerminassistentStateSetApiAppointmentSchedulerExternalKey)
  setApiAppointmentSchedulerExternalKey(
    ctx: StateContext<TerminassistentStateModel>,
    action: TerminassistentStateSetApiAppointmentSchedulerExternalKey,
  ) {
    ctx.patchState({
      apiAppointmentSchedulerExternalKey: action.apiAppointmentSchedulerExternalKey,
      lastModifiedAt: new Date(),
    });
  }

  @Action(TerminassistentStateSetRouteBeforeTerminassistentStart)
  setRouteBeforeTerminassistentStart(
    ctx: StateContext<TerminassistentStateModel>,
    action: TerminassistentStateSetRouteBeforeTerminassistentStart,
  ) {
    ctx.patchState({
      routeBeforeTerminassistentStart: action.routeBeforeTerminassistentStart,
      lastModifiedAt: new Date(),
    });
  }

  ngxsOnInit(ctx: StateContext<TerminassistentStateModel>) {
    const currentState = ctx.getState();
    if (currentState && currentState.lastModifiedAt) {
      const lastModifiedAt = currentState.lastModifiedAt;
      if (dayjs(lastModifiedAt).isBefore(dayjs().subtract(3, 'day'))) {
        ctx.setState({
          lastModifiedAt: new Date(),
          utm: currentState && currentState.utm ? currentState.utm : undefined,
          referralCode: currentState && currentState.referralCode ? currentState.referralCode : undefined,
        });
      }
    }
  }
}
