import { afterNextRender, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ApexAxisChartSeries } from 'ng-apexcharts';

@Component({
  selector: 'ax-ui-chart-column-stacked',
  standalone: true,
  imports: [],
  templateUrl: './chart-column-stacked.component.html',
  styleUrls: ['./chart-column-stacked.component.scss'],
})
export class ChartColumnStackedComponent implements OnChanges {
  @ViewChild('chartComponent', { static: true }) chartComponent!: ElementRef<HTMLElement>;
  @Input({ required: true }) chartDomId!: string;
  @Input() chartSeries: ApexAxisChartSeries = [];
  @Input() labels: string[] = [];
  @Input() showToolbar = false;

  private chart: ApexCharts | undefined;
  private ApexCharts: any;
  private chartColors = ['#51AC3B', '#2D5E20', '#193612'];

  constructor() {
    afterNextRender(async () => {
      const chartOptions = {
        series: this.chartSeries as any,
        fill: {
          colors: this.chartColors,
        },
        chart: {
          height: 760,
          type: 'bar',
          stacked: true,
          fontFamily: 'Gotham Narrow Medium, Tahoma, Verdana, sans-serif',
          toolbar: {
            show: this.showToolbar,
          },
          animations: {
            easing: 'easeinout',
            speed: 350,
          },
        },
        dataLabels: {
          formatter: (val: number) => {
            return val.toLocaleString('de-CH') + ' CHF';
          },
          style: {
            fontSize: '20px',
            fontWeight: 500,
          },
        },
        xaxis: {
          categories: this.labels,
          labels: {
            show: this.labels && this.labels.length,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 16,
            columnWidth: '50%',
            borderRadiusApplication: 'around',
            borderRadiusWhenStacked: 'all',
          },
        },
        legend: {
          showForSingleSeries: true,
          offsetY: 8,
          fontSize: '16px',
          markers: {
            fillColors: this.chartColors,
            offsetX: -3,
          },
          itemMargin: {
            horizontal: 12,
            vertical: 6,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      };

      try {
        const apexChartsModule = await import('apexcharts');
        this.ApexCharts = (apexChartsModule as any).default || apexChartsModule;
        this.chart = new this.ApexCharts(document.querySelector(`#${this.chartDomId}`), chartOptions);
        if (this.chart) {
          await this.chart.render();
        }
      } catch (error) {
        // Nothing to catch.
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['chartSeries'] && this.chart) {
      await this.chart.updateSeries(this.chartSeries);
    }
  }
}
