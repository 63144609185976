/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';

@Injectable({ providedIn: 'root' })
export class AxSuppliersV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `suppliersControllerGetAllDropdownOptions()` */
  static readonly SuppliersControllerGetAllDropdownOptionsPath = '/v2/suppliers/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suppliersControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  suppliersControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxSuppliersV2Service.SuppliersControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suppliersControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suppliersControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.suppliersControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `suppliersControllerGetAllDropdownOptionsForZipcode()` */
  static readonly SuppliersControllerGetAllDropdownOptionsForZipcodePath = '/v2/suppliers/dropdown-options/{zipcode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suppliersControllerGetAllDropdownOptionsForZipcode()` instead.
   *
   * This method doesn't expect any request body.
   */
  suppliersControllerGetAllDropdownOptionsForZipcode$Response(
    params: {
      zipcode: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxSuppliersV2Service.SuppliersControllerGetAllDropdownOptionsForZipcodePath, 'get');
    if (params) {
      rb.path('zipcode', params.zipcode, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suppliersControllerGetAllDropdownOptionsForZipcode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suppliersControllerGetAllDropdownOptionsForZipcode(
    params: {
      zipcode: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.suppliersControllerGetAllDropdownOptionsForZipcode$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

}
