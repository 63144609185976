
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateLocationDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>(undefined),
    zipcode: new FormControl<number | undefined>(undefined),
    canton: new FormControl<string | undefined>(undefined),
    locationCommunitySponsorship: new FormControl<string | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
})
;
}
      