<label [class.removable]="removable"
       [class.small]="small"
       [class.clickable]="clickable"
       [class.disabled]="disabled"
       [class.text-wrap]="textWrap"
       [ngClass]="[
        status ? status === 'Ausführung' || 'Administrativer Abschluss' ? 'warning': status === 'Beendet' || 'Erledigt' || 'Genehmigt' ? 'success' : status === 'Nicht realisiert' || 'Abgelehnt' ? 'error' : 'neutral' : variant,
        borderStyle,
        iconName ? 'flex gap-8 flew-row vertical-center' : ''
       ]"
       class="ax-ui-label">
  <ax-ui-icon [fontSize]="16"
              [name]="iconName" />
  {{ text.toString() | translate }}
  @if (removable) {
    <ax-ui-icon (click)="emitRemoveClick()"
                name="close"
    ></ax-ui-icon>
  }
</label>
