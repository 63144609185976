import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  model,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../../modal/modal.component';
import { AxUser, AxUsersV2Service, AxUserV2 } from '@axova-frontend-monorepo/axova-rest-api';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { ExceptionHandlingService } from '../../../services/exception-handling/exception-handling.service';
import { GroupByPipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';
import { InfoblockComponent } from '../../infoblock/infoblock.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import { NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'ax-ui-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    TranslateModule,
    ModalComponent,
    InfoblockComponent,
    PrependServerToFileSrcPipe,
    NgTemplateOutlet,
    InputFieldComponent,
    IconComponent,
    GroupByPipe,

  ],
})
export class SelectUserComponent implements AfterViewInit {
  @ViewChild('userListModal') modal!: ModalComponent;
  @ViewChildren('listItem') listItems!: QueryList<ElementRef>;

  @HostBinding('style.maxWidth') hostMaxWidth = '100%';

  @Input() selectedUser: AxUser | AxUserV2 | undefined;
  selectedUserId = model<number | undefined | null>();
  @Input() required = false;
  @Input() label = '';
  @Input() labelSize: 'small' | 'default' = 'small';
  @Input() placeholder = 'MITARBEITER_AUSWAEHLEN';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() readonlyNoBorders = false;
  @Input() invalid = false;
  @Input() hintMessage = 'Hinweis';
  @Input() hideHint = false;
  @Input() disableHint = false;
  @Input() iconName = '';
  @Input() tableInput = false;
  @Input() editable = false;
  @Input() maxWidth: number | string | undefined;
  @Input() width: number | string | undefined;
  @Input() formControlName = '';

  @Output() selectedUserChanged = new EventEmitter<AxUser | AxUserV2>();

  public profileUser: AxUser | undefined = this.store.selectSnapshot(ProfileState.user);
  public flatList: { user: AxUserV2; section: string }[] = [];
  public displayedFlatList: { user: AxUserV2; section: string }[] = [];
  public activeListIndex = 0;
  private usersLoaded = false;

  constructor(
    private readonly store: Store,
    private readonly usersV2Service: AxUsersV2Service,
    private readonly exceptionHandlingService: ExceptionHandlingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    effect(async () => {
      await this.selectUserFromId();
    });
  }

  ngAfterViewInit(): void {
    if (this.maxWidth) {
      this.hostMaxWidth = this.maxWidth + 'px';
    }
  }

  public async openModal() {
    await this.loadDataForContext();
    // Initialize displayed list
    this.displayedFlatList = this.flatList;
    await this.selectUserFromId();
    if (this.selectedUser) {
      this.activeListIndex = this.displayedFlatList.findIndex(user => user.user.id === this.selectedUser?.id) || 0;
    }
    this.modal.open();
    setTimeout(() => {
      this.scrollActiveItemIntoView();
    });
  }

  public async search(searchTerm: string): Promise<void> {
    // reset to assure correct nav behaviour after search changes
    this.activeListIndex = 0;

    if (!searchTerm) {
      // Reset the searched list when no search term is provided
      this.resetSearchList();
      return;
    }

    this.displayedFlatList = this.flatList.filter(item => {
      const fullName = `${item.user.firstName} ${item.user.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }

  public async selectUser(user: AxUserV2) {
    this.selectedUser = user;
    this.selectedUserId.update(() => user.id);
    this.activeListIndex = this.displayedFlatList.findIndex(user => user.user.id === this.selectedUser?.id);
    this.selectedUserChanged.emit(user);
    await this.modal.close();
  }

  public async handleKeyboardNavigation(event: KeyboardEvent) {
    if (this.displayedFlatList.length === 0) {
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.activeListIndex = (this.activeListIndex + 1) % this.displayedFlatList.length;
      this.scrollActiveItemIntoView();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.activeListIndex = (this.activeListIndex - 1 + this.displayedFlatList.length) % this.displayedFlatList.length;
      this.scrollActiveItemIntoView();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      await this.selectUser(this.displayedFlatList[this.activeListIndex]?.user);
    }
  }

  private scrollActiveItemIntoView() {
    const item = this.listItems.toArray()[this.activeListIndex];
    if (!item) {
      return;
    }
    item.nativeElement
      .scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
  }

  private async loadAllUsersGroupedForUser() {
    if (!this.profileUser) {
      return;
    }
    try {
      const userListGrouped = await lastValueFrom(this.usersV2Service.usersControllerGetAllGroupedForUser({
        id: this.profileUser.id,
      }));
      this.flatList = [
        ...this.mapUsersToFlatList(userListGrouped.myBusinessDivision, 'MITARBEITER_MEINE_ABTEILUNG'),
        ...this.mapUsersToFlatList(userListGrouped.restOfCompany, 'MITARBEITER_RESTLICHE'),
      ];
    } catch (loadAllUsersGroupedForUserException) {
      await this.exceptionHandlingService.handleExceptionWithErrorPageRedirect('loadAllUsersGroupedForUserException', loadAllUsersGroupedForUserException);
    }
  }

  private async loadAllUsers() {
    try {
      const userList = await lastValueFrom(this.usersV2Service.usersControllerGetAll());
      this.flatList = this.mapUsersToFlatList(userList, '');
    } catch (loadAllUsersException) {
      await this.exceptionHandlingService.handleExceptionWithErrorPageRedirect('loadAllUsersException', loadAllUsersException);
    }
  }

  private mapUsersToFlatList(users: AxUserV2[] | undefined, section: string): { user: AxUserV2; section: string }[] {
    return (users ?? []).map(user => ({ user, section }));
  }

  private resetSearchList() {
    this.displayedFlatList = this.flatList;
    this.scrollActiveItemIntoView();
  }

  private async selectUserFromId() {
    if (this.selectedUserId()) {
      if (this.flatList.length === 0) {
        await this.loadDataForContext();
      }
      this.selectedUser = this.flatList.find(user => user.user.id === this.selectedUserId())?.user;
    } else {
      this.selectedUser = undefined;
    }
    this.changeDetectorRef.detectChanges();
  }

  private async loadDataForContext() {
    if (!this.usersLoaded) {
      if (this.profileUser) {
        await this.loadAllUsersGroupedForUser();
      } else {
        await this.loadAllUsers();
      }
      this.usersLoaded = true;
    }
  }
}
