import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ax-ui-chart-progress',
  templateUrl: './chart-progress.component.html',
  styleUrls: ['./chart-progress.component.scss'],
  standalone: true,
  imports: [
    NgIf,
  ],
})
export class ChartProgressComponent {
  @Input() showNumbers = true;
  @Input() total = 100;
  @Input() reached = 0;
  @Input() showTotal = true;
  @Input() showPercentage = false;

  get percentageReached() {
    let reached = this.reached;
    try {
      reached = parseInt(this.reached as unknown as string);
    } catch (nothing) {
    }
    return reached / this.total * 100;
  }
}
