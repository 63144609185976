import { Pipe, PipeTransform } from '@angular/core';
import { getFormattedNumber } from '../functions/get-formatted-number';

@Pipe({
  name: 'formatNumber',
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number | undefined | null, numberOfDecimals?: number): string {
    return getFormattedNumber(value, numberOfDecimals);
  }
}
