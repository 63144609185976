import { ComponentRef, EventEmitter, Injectable } from '@angular/core';
import { ConfirmActionModalHostDirective } from '../../directives/confirm-action-modal-host/confirm-action-modal-host.directive';
import { ModalConfirmActionComponent } from '../../components/modal-confirm-action/modal-confirm-action.component';
import { ConfirmActionModalButton, ConfirmActionModalOptions } from '../../models/confirm-action-modal-options.model';

@Injectable({
  providedIn: 'root',
})
export class ConfirmActionModalService {
  private modalsHost!: ConfirmActionModalHostDirective;
  private activeModal: ComponentRef<ModalConfirmActionComponent> | null = null;

  public setModalHost(host: ConfirmActionModalHostDirective) {
    this.modalsHost = host;
  }

  public showModal(modalOptions?: ConfirmActionModalOptions): EventEmitter<ConfirmActionModalButton | undefined> {
    if (this.activeModal) {
      this.closeModal();
    }

    const modalRef = this.modalsHost.viewContainerRef.createComponent(ModalConfirmActionComponent);
    const modalRefInstance = modalRef.instance;

    if (!modalOptions) {
      modalOptions = {};
    }

    modalRefInstance.modalOptions = modalOptions;

    this.activeModal = modalRef;

    modalRefInstance.userAction.subscribe(() => {
      this.closeModal();
    });

    return modalRefInstance.userAction;
  }

  public closeModal() {
    if (this.activeModal) {
      this.activeModal.destroy();
      this.activeModal = null;
    }
  }
}
