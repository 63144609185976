import { Injectable } from '@angular/core';
import { ConfirmActionModalService } from '../confirm-action-modal/confirm-action-modal.service';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormEditModeService {

  constructor(
    private readonly confirmActionModalService: ConfirmActionModalService,
  ) {
  }

  public showConfirmEditModeCancelModal(): Observable<boolean> {
    return this.confirmActionModalService.showModal({
      title: 'BEARBEITUNGSMODUS_BEENDEN',
      infoText: 'BEARBEITUNGSMODUS_BEENDEN_DESCRIPTION',
      hideCancelButton: true,
      buttons: [
        {
          label: 'WEITER_BEARBEITEN',
          variant: 'primary',
          value: false,
        },
        {
          label: 'VERWERFEN',
          variant: 'secondary',
          value: true,
        },
      ],
    }).pipe(
      take(1),
      map((actionButton) => {
        if (typeof actionButton.value === 'boolean') {
          return actionButton.value;
        } else {
          throw new Error('showConfirmEditModeCancelModal: Button value is not a boolean');
        }
      }),
    );
  }


  preventRouteChangesListener() {

  }
}
