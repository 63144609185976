import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TableHeaderItem } from '../../models/table-header-options.model';

import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'ax-ui-table-headers',
  templateUrl: './table-headers.component.html',
  styleUrls: ['./table-headers.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    TranslateModule,
    CheckboxComponent,
    FormsModule,
  ],
})
export class TableHeadersComponent implements OnInit {
  @HostBinding('style.display') display = 'contents';

  @Input({ required: true }) tableHeaderItems!: TableHeaderItem[];
  @Input() hasActionsColumn = false;

  @Output() sort = new EventEmitter<TableHeaderItem>();
  @Output() checkboxChange = new EventEmitter<TableHeaderItem>();

  // used to skip UI change when sort is triggered on init
  protected sortOnClick = false;

  ngOnInit() {
    this.tableHeaderItems.forEach(option => {
      if (!option.sortStatus) {
        option.sortStatus = 'NONE';
      }
    });
    if (this.hasActionsColumn && this.tableHeaderItems && this.tableHeaderItems.findIndex(option => option.name === 'ACTIONS') === -1) {
      this.tableHeaderItems.push({
        label: '',
        sortable: false,
        name: 'ACTIONS',
      });
    }
  }

  public changeSortStatus(selectedIndex: number) {
    if (!this.sortOnClick) {
      this.sortOnClick = true;
    }
    this.tableHeaderItems.forEach((option, index) => {
      if (index === selectedIndex) {
        switch (option.sortStatus) {
          case 'ASC': {
            option.sortStatus = 'DESC';
            break;
          }
          case 'DESC': {
            option.sortStatus = 'ASC';
            break;
          }
          case 'NONE': {
            option.sortStatus = 'DESC';
            break;
          }
        }
      } else {
        option.sortStatus = 'NONE';
      }
    });
    this.sort.emit(this.tableHeaderItems[selectedIndex]);
  }
}
