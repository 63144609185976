import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, Router, RouterOutlet } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import {
  TerminassistentWizardStepsComponent
} from './components/terminassistent-wizard-steps/terminassistent-wizard-steps.component';
import { TerminassistentWizardStepsNavService } from './services/terminassistent-wizard-steps-nav.service';
import { TerminassistentState } from './ngxs/terminassistent/terminassistent.state';
import * as dayjs from 'dayjs';
import { Store } from '@ngxs/store';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { AxReservation, AxReservationsService } from '@axova-frontend-monorepo/axova-rest-api';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import {
  TerminassistentStateSetApiAppointmentSchedulerExternalKey,
  TerminassistentStateSetApiAppointmentSchedulerKey,
  TerminassistentStateSetReservation
} from './ngxs/terminassistent/terminassistent.actions';
import { terminAssistentRoutesAnimations } from './terminassistent.routes';
import { InputFieldComponent } from '../inputs/input-field/input-field.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { lastValueFrom, Subscription } from 'rxjs';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha-2';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { AlertDialogService } from '../../services/alert-dialog/alert-dialog.service';

@Component({
  selector: 'ax-ui-terminassistent',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonComponent,
    TerminassistentWizardStepsComponent,
    LoadingSpinnerComponent,
    InputFieldComponent,
    ReactiveFormsModule,
    TranslateModule,
    CheckboxComponent,
    ModalComponent,
    RecaptchaV3Module
  ],
  templateUrl: './terminassistent.component.html',
  styleUrl: './terminassistent.component.scss',
  animations: [terminAssistentRoutesAnimations]
})
export class TerminassistentComponent implements OnInit, OnDestroy {
  @ViewChild('feedbackFormModal') feedbackFormModal!: ModalComponent;

  public terminAssisstentFeedbackformGroup = new FormGroup(
    {
      dateTime: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      phoneNumber: new FormControl(''),
      feedbackOption1: new FormControl(false),
      feedbackOption2: new FormControl(false),
      feedbackOption3: new FormControl(false),
      additionalComments: new FormControl('')
    },
    { validators: this.atLeastOneControlFilledValidator() }
  );
  public feedbackOption1NoFittingAppointment = 'Keinen passenden Termin gefunden';
  public feedbackOption2LocationNotServed = 'Mein Standort wird nicht bedient';
  public feedbackOption3IssuesOrTooComplicated = 'Technische Probleme/Der Assistent ist zu kompliziert';
  public feedbackPopupShowedOnce = false;
  private optOutRoute = '';
  private recaptchaSubscription: Subscription | undefined;

  constructor(
    private store: Store,
    public wizardStepsNavService: TerminassistentWizardStepsNavService,
    private activatedRoute: ActivatedRoute,
    private contexts: ChildrenOutletContexts,
    private reservationsService: AxReservationsService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private alertDialogService: AlertDialogService
  ) {
  }

  ngOnInit() {
    this.checkForQueryParameters();
    this.store.select(TerminassistentState.location).subscribe(location => this.wizardStepsNavService.steps[1].note = location?.name || '');
    this.store.select(TerminassistentState.reservationtype).subscribe(reservationtype => this.wizardStepsNavService.steps[2].note = reservationtype?.description || '');
    this.store.select(TerminassistentState.timeslot).subscribe(timeslot => {
      if (timeslot && timeslot?.start) {
        this.wizardStepsNavService.steps[3].note = dayjs(timeslot?.start).format('DD.MM.YYYY, H:mm');
      } else {
        this.wizardStepsNavService.steps[3].note = '';
      }
    });
    this.store.select(TerminassistentState.reservation).subscribe(reservation => this.wizardStepsNavService.steps[3].note = reservation?.street ? 'Komplett' : '');
    this.store.select(TerminassistentState.apiAppointmentSchedulerExternalKey).subscribe(apiKey => {
      if (apiKey) {
        this.wizardStepsNavService.steps[1].isVisible = false;
        this.wizardStepsNavService.steps[4].isVisible = false;
      }
    });

    this.wizardStepsNavService.observeRouterChanges();

    // set mail and phone number to required, if a favorite date is selected
    this.terminAssisstentFeedbackformGroup.controls.dateTime?.valueChanges.subscribe(value => {
      if (value) {
        // Add the required validators to email and phoneNumber controls
        this.terminAssisstentFeedbackformGroup.controls.email.setValidators([
          Validators.required,
          Validators.email
        ]);
        this.terminAssisstentFeedbackformGroup.controls.phoneNumber.setValidators([Validators.required]);
      } else {
        // Remove the required validators from email and phoneNumber controls
        this.terminAssisstentFeedbackformGroup.controls.email.clearValidators();
        this.terminAssisstentFeedbackformGroup.controls.phoneNumber.clearValidators();
      }

      // Update the validity status of the controls
      this.terminAssisstentFeedbackformGroup.controls.email.updateValueAndValidity();
      this.terminAssisstentFeedbackformGroup.controls.phoneNumber.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.wizardStepsNavService.unSubScribeFromRouterChanges();
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
  }

  public getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  public async submitFeedback() {
    const submitHelper = async (recaptchaToken: string, recaptchaActionName: string) => {
      const controls = this.terminAssisstentFeedbackformGroup.controls;
      const selectedFeedbackOptions: string[] = [];

      if (controls.feedbackOption1.value) {
        selectedFeedbackOptions.push(this.feedbackOption1NoFittingAppointment);
      }
      if (controls.feedbackOption2.value) {
        let selectedLocationString = '';
        if (this.store.selectSnapshot(TerminassistentState.searchedLocation)) {
          selectedLocationString = ': ' + this.store.selectSnapshot(TerminassistentState.searchedLocation);
        }
        selectedFeedbackOptions.push(this.feedbackOption2LocationNotServed + selectedLocationString);
      }
      if (controls.feedbackOption3.value) {
        selectedFeedbackOptions.push(this.feedbackOption3IssuesOrTooComplicated);
      }

      try {
        await lastValueFrom(this.reservationsService.reservationsControllerSendFeedback({
          body: {
            googleRecaptchaToken: recaptchaToken,
            googleRecaptchaAction: recaptchaActionName,
            contactMail: controls.email.value || '',
            contactPhoneNumber: controls.phoneNumber.value || '',
            desiredDate: controls.dateTime.value || '',
            selectedFeedbackOptions: selectedFeedbackOptions,
            additionalComments: controls.additionalComments.value || ''
          }
        }));
        await this.feedbackFormModal.close();
        this.alertDialogService.showAlert({
          title: 'Danke für Ihr Feedback',
          message: 'Falls Sie eine Kontaktmöglichkeit angegeben haben, werden wir uns schnellstmöglich bei Ihnen melden',
          showCentered: true,
          alertLifeTimeInMs: 6000,
          feedbackState: 'success'
        });
      } catch (sendReservationFeedbackException) {
        this.wizardStepsNavService.hideLoader();
        LoggerService.ERROR('Terminassisstent send reservation feedback', sendReservationFeedbackException);
        await this.feedbackFormModal.close();
        this.alertDialogService.showAlert({
          title: 'Fehler beim senden des Feedbacks',
          showCentered: true,
          alertLifeTimeInMs: 6000,
          feedbackState: 'error'
        });
      }
    };

    if (environment.production || environment.name === 'test') {
      const actionName = 'terminassistent_feedback';
      this.recaptchaSubscription = this.recaptchaV3Service.execute(actionName).subscribe({
          next: async (token) => {
            await submitHelper(token, actionName);
          },
          error: (error) => LoggerService.ERROR(`Recaptcha v3 error:`, error)
        }
      );
    } else {
      await submitHelper('', '');
    }
  }

  private checkForQueryParameters() {
    this.activatedRoute.queryParams
      .subscribe((params: any) => {
          try {
            const reservation: Partial<AxReservation> = {};
            const keys: (keyof Partial<AxReservation>)[] = [
              'company',
              'email',
              'titleId',
              'firstName',
              'lastName',
              'objectStreet',
              'objectZip',
              'objectCity',
              'objectCanton',
              'phone',
              'reservationtypeId'
            ];

            keys.forEach(key => {
              if (params[key]) {
                reservation[key] = params[key];
              }
            });

            type AddressKey = 'street' | 'zipcode' | 'city' | 'canton';
            const addressKeys: AddressKey[] = [
              'street',
              'zipcode',
              'city',
              'canton'
            ];

            addressKeys.forEach(key => {
              reservation[key] = params[key] || params[`object${key.charAt(0).toUpperCase() + key.slice(1)}` as AddressKey];
            });

            if (Object.keys(reservation).some(key => reservation[key as keyof Partial<AxReservation>] !== undefined)) {
              this.store.dispatch(new TerminassistentStateSetReservation(reservation));
            }
          } catch (noParamsException) {
            LoggerService.ERROR(this, 'Keine Einstiegsparameter mitgegeben.');
          }

          // Set API Key.
          if (params.apiAppointmentSchedulerKey) {
            this.store.dispatch(new TerminassistentStateSetApiAppointmentSchedulerKey(params.apiAppointmentSchedulerKey));
          }

          // Set External API Key.
          if (params.apiAppointmentSchedulerExternalKey) {
            this.store.dispatch(new TerminassistentStateSetApiAppointmentSchedulerExternalKey(params.apiAppointmentSchedulerExternalKey));
          }
        }
      );
  }

  public showFeedbackModal(optOutRoute: string) {
    this.optOutRoute = optOutRoute;
    this.feedbackFormModal.open();
    this.feedbackPopupShowedOnce = true;
  }

  public async navigateByOptOutRoute() {
    await this.router.navigateByUrl(this.optOutRoute);
  }

  // checks if at least one control has a valid value (no empty form submits)
  private atLeastOneControlFilledValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const controls = (formGroup as any).controls;
      const isValid = Object.keys(controls).some(controlName => {
        const control = controls[controlName];
        return control && control.value;
      });
      return isValid ? null : { atLeastOneRequired: true };
    };
  }
}
