import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideRouter } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AxovaStateModule } from '@axova-frontend-monorepo/axova-state';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { TRANSLATE_FACTORY_MODULES, translateHttpModuleLoaderFactory } from '@axova-frontend-monorepo/axova-commons';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AxovaStateModule, ReactiveFormsModule, TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpModuleLoaderFactory,
        deps: [
          HttpClient,
          TRANSLATE_FACTORY_MODULES,
        ],
      },
    })),
    {
      provide: TRANSLATE_FACTORY_MODULES,
      useValue: [
        {
          moduleName: 'LIBRARY',
          baseTranslateUrl: './assets/i18n',
        },
      ],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter([]),
  ],
})
  .catch((err) => console.error(err));
