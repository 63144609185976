@if (!hideTitle) {
  <div class="ax-office-layout__content-headline">
    <h2>{{ 'AKTIVITAETSVERLAUF' | translate }}</h2>
  </div>
}
@if (interactions && interactions.length > 0) {
  <div class="ax-ui-interactions-timeline"
       [class.table-style]="tableStyle">
    @if (accordionView) {
      @if (interactions.length > 1) {
        <ax-ui-accordion-item #accordion
                              [withBorder]="false"
                              [hideIcon]="true"
                              [noPadding]="true"
                              [headerIconSpaceBetween]="false"
                              [headerNoHoverEffect]="true">
          <div accordion-header>
            <ng-container [ngTemplateOutlet]="interactionItemTemplate"
                          [ngTemplateOutletContext]="{interaction: interactions[0], i: 0}" />
          </div>
          <div accordion-icon>
            <ax-ui-button [text]="'+' + (interactions.length - 1) + ' ' + ('WEITERE_ANZEIGEN' | translate)"
                          [iconName]="accordion.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                          [iconPositionRight]="true"
                          [small]="true"
                          fontWeight="regular"
                          variant="tertiary" />
          </div>
          <div accordion-content>
            @for (interaction of interactions; track interaction.id; let i = $index) {
              @if (i > 0) {
                <ng-container [ngTemplateOutlet]="interactionItemTemplate"
                              [ngTemplateOutletContext]="{interaction: interaction, i: i}" />
              }
            }
          </div>
        </ax-ui-accordion-item>
      } @else {
        <ng-container [ngTemplateOutlet]="interactionItemTemplate"
                      [ngTemplateOutletContext]="{interaction: interactions[0], i: 0}" />
      }
    } @else {
      @for (interaction of interactions; track interaction.id; let i = $index) {
        <ng-container [ngTemplateOutlet]="interactionItemTemplate"
                      [ngTemplateOutletContext]="{interaction: interaction, i: i}" />
      }
    }
  </div>
} @else {
  <p class="color-light">{{ 'AKTIVITAET_KEINE' | translate }}<p>
    }

<ng-template #interactionItemTemplate let-interaction="interaction" let-i="i">
  @let createdByUser = interaction.createdByUser;
  <div class="ax-ui-interactions-timeline__item">
    <div class="ax-ui-interactions-timeline__item-badge">
      <div
        [ngClass]="{'success' : interaction.endtime && interaction.interactiontypeId === 3, 'todo' : !interaction.endtime && interaction.interactiontypeId === 3}"
        class="ax-ui-interactions-timeline__item-badge-icon">
        <ax-ui-icon [name]="getBadgeIconName(interaction.interactiontypeId, i)"
                    [fontSize]="tableStyle ? 18 : 24" />
      </div>
    </div>
    <div class="ax-ui-interactions-timeline__item-body">
      <div class="ax-ui-interactions-timeline__meta flex gap-48 space-between margin-bottom-4" style="height: 40px">
        <div class="flex flex-row vertical-center gap-8">
          @if (createdByUser) {
            <ax-ui-avatar
              [size]="'mini'"
              [imgSrc]="createdByUser && createdByUser.userImage ? (createdByUser.userImage.fileSrcThumbnail | prependServerToFileSrc) : null" />
          }
          <p>
            @if (createdByUser) {
              <a
                [routerLink]="'/mitarbeiter/read/' + interaction.createdBy"><b>{{ createdByUser.firstName }} {{ createdByUser.lastName }}</b></a>@if (!tableStyle) {
                hat eine
              }
            }
            @if (!tableStyle) {
              <b>{{ interaction.interactiontype.name }}</b>
              erstellt@if (interaction.interactiontypeId === 2 && interaction.activitytype) {
                <b>{{ ': ' + interaction.activitytype.name + (interaction.outcometype ? (' - ' + interaction.outcometype.name) : '') }}</b>
              }
            }
          </p>
        </div>

      </div>
      @if (interaction.assigneeId || interaction.duetime || interaction.endtime) {
        <div class="ax-ui-interactions-timeline__task note task flex flex-column gap-4">
          <p><b>{{ (interaction.interactiontypeId !== 1 && interaction.subject) ? interaction.subject : '' }}</b>
          </p>
          <p
            class="flex flex-row vertical-center">
            @if (interaction.interactiontypeId === 3 && !interaction.endtime) {
              <small>{{ 'ERLEDIGEN' | translate }}</small>
            }
            @if (interaction.duetime && !interaction.endtime) {
              <small>&nbsp;bis {{ interaction.duetime | formatDate }}</small>
            }
            @if (interaction.endtime) {
              <small>{{ 'ERLEDIGT_AM' | translate }} {{ interaction.endtime | formatDate }}</small>
            }

            @if (interaction.assignee && !interaction.endtime) {
              <small>&nbsp;von
                <a
                  [routerLink]="'/mitarbeiter/read/' + interaction.assigneeId">{{ interaction.assignee.firstName }} {{ interaction.assignee.lastName }}</a>
              </small>
            }
            @if (interaction.endtime && interaction.modifiedByUser) {
              <small>&nbsp;von
                <a
                  [routerLink]="'/mitarbeiter/read/' + interaction.modifiedByUser.id">{{ interaction.modifiedByUser.firstName }} {{ interaction.modifiedByUser.lastName }}</a>
              </small>
            }
          </p>
        </div>
      }

      @if (interaction.notes) {
        <div class="ax-ui-interactions-timeline__note note flex flex-column gap-12">
          <p>{{ interaction.notes }}</p>
        </div>
      }
      <div class="ax-ui-interactions-timeline__date flex end">
        <small class="color-light">{{ interaction.created | formatDate: 'DD.MM.YYYY, HH:mm [Uhr]' }}</small>
      </div>
    </div>
  </div>
</ng-template>

