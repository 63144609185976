/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateNewsDto } from '../models/ax-create-news-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxNews } from '../models/ax-news';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateNewsDto } from '../models/ax-update-news-dto';

@Injectable({ providedIn: 'root' })
export class AxNewsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `newsControllerFindAllPaginated()` */
  static readonly NewsControllerFindAllPaginatedPath = '/v2/news';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by audiences.users.id query param.
     *           <p>
     *              <b>Format: </b> filter.audiences.users.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.audiences.users.id=$not:$like:John Doe&filter.audiences.users.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.audiences.users.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li>
     * <li>showFrom</li>
     * <li>author.firstName</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC' | 'showFrom:ASC' | 'showFrom:DESC' | 'author.firstName:ASC' | 'author.firstName:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'audiences.users.id'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.audiences.users.id', params['filter.audiences.users.id'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxNews>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'audiences.users.id'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by audiences.users.id query param.
     *           <p>
     *              <b>Format: </b> filter.audiences.users.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.audiences.users.id=$not:$like:John Doe&filter.audiences.users.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.audiences.users.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> created:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li>
     * <li>showFrom</li>
     * <li>author.firstName</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC' | 'showFrom:ASC' | 'showFrom:DESC' | 'author.firstName:ASC' | 'author.firstName:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'audiences.users.id'?: (string | Array<string>);
};
};
}> {
    return this.newsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'audiences.users.id'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxNews>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'audiences.users.id'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `newsControllerCreate()` */
  static readonly NewsControllerCreatePath = '/v2/news';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newsControllerCreate$Response(
    params: {
      body: AxCreateNewsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNews>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newsControllerCreate(
    params: {
      body: AxCreateNewsDto
    },
    context?: HttpContext
  ): Observable<AxNews> {
    return this.newsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNews>): AxNews => r.body)
    );
  }

  /** Path part for operation `newsControllerGetAllDropdownOptions()` */
  static readonly NewsControllerGetAllDropdownOptionsPath = '/v2/news/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.newsControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `newsControllerFindOneById()` */
  static readonly NewsControllerFindOneByIdPath = '/v2/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNews>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxNews> {
    return this.newsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNews>): AxNews => r.body)
    );
  }

  /** Path part for operation `newsControllerDelete_1()` */
  static readonly NewsControllerDelete_1Path = '/v2/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerDelete_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerDelete_1$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerDelete_1Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerDelete_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerDelete_1(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.newsControllerDelete_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newsControllerUpdate()` */
  static readonly NewsControllerUpdatePath = '/v2/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateNewsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNews>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateNewsDto
    },
    context?: HttpContext
  ): Observable<AxNews> {
    return this.newsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNews>): AxNews => r.body)
    );
  }

  /** Path part for operation `newsControllerAddAudienceToNews()` */
  static readonly NewsControllerAddAudienceToNewsPath = '/v2/news/{id}/audiences/{audienceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerAddAudienceToNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerAddAudienceToNews$Response(
    params: {
      id: number;
      audienceId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerAddAudienceToNewsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('audienceId', params.audienceId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerAddAudienceToNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerAddAudienceToNews(
    params: {
      id: number;
      audienceId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.newsControllerAddAudienceToNews$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `newsControllerRemoveAudienceFromNews()` */
  static readonly NewsControllerRemoveAudienceFromNewsPath = '/v2/news/{id}/audiences/{audienceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerRemoveAudienceFromNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerRemoveAudienceFromNews$Response(
    params: {
      id: number;
      audienceId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerRemoveAudienceFromNewsPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('audienceId', params.audienceId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerRemoveAudienceFromNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerRemoveAudienceFromNews(
    params: {
      id: number;
      audienceId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.newsControllerRemoveAudienceFromNews$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `newsControllerAddTagToNews()` */
  static readonly NewsControllerAddTagToNewsPath = '/v2/news/{id}/tags/{tagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerAddTagToNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerAddTagToNews$Response(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerAddTagToNewsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('tagId', params.tagId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerAddTagToNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerAddTagToNews(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.newsControllerAddTagToNews$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `newsControllerRemoveTagFromNews()` */
  static readonly NewsControllerRemoveTagFromNewsPath = '/v2/news/{id}/tags/{tagId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newsControllerRemoveTagFromNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerRemoveTagFromNews$Response(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxNewsV2Service.NewsControllerRemoveTagFromNewsPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('tagId', params.tagId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newsControllerRemoveTagFromNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  newsControllerRemoveTagFromNews(
    params: {
      id: number;
      tagId: number;
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.newsControllerRemoveTagFromNews$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
