/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxMessagingOptionsDto } from '../models/ax-messaging-options-dto';
import { AxMessagingTemplate } from '../models/ax-messaging-template';
import { AxMessagingTemplateDto } from '../models/ax-messaging-template-dto';
import { AxMessagingTemplateNameEnum } from '../models/ax-messaging-template-name-enum';
import { AxProjectAppointmentDatesOverviewMessageOptionsDto } from '../models/ax-project-appointment-dates-overview-message-options-dto';
import { AxSendMessageDto } from '../models/ax-send-message-dto';
import { AxSuccessDto } from '../models/ax-success-dto';

@Injectable({ providedIn: 'root' })
export class AxMessagingV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingControllerGetAllMessagingTemplateDropdownOptions()` */
  static readonly MessagingControllerGetAllMessagingTemplateDropdownOptionsPath = '/v2/messaging/messagingTemplate/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetAllMessagingTemplateDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetAllMessagingTemplateDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetAllMessagingTemplateDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetAllMessagingTemplateDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetAllMessagingTemplateDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.messagingControllerGetAllMessagingTemplateDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `messagingControllerFindMessagingTemplateById()` */
  static readonly MessagingControllerFindMessagingTemplateByIdPath = '/v2/messaging/messagingTemplate/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerFindMessagingTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerFindMessagingTemplateById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingTemplate>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerFindMessagingTemplateByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingTemplate>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerFindMessagingTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerFindMessagingTemplateById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxMessagingTemplate> {
    return this.messagingControllerFindMessagingTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingTemplate>): AxMessagingTemplate => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetMessagingOptionsForUser()` */
  static readonly MessagingControllerGetMessagingOptionsForUserPath = '/v2/messaging/options/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetMessagingOptionsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForUser$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingOptionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetMessagingOptionsForUserPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingOptionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetMessagingOptionsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForUser(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<AxMessagingOptionsDto> {
    return this.messagingControllerGetMessagingOptionsForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingOptionsDto>): AxMessagingOptionsDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetMessagingOptionsForContact()` */
  static readonly MessagingControllerGetMessagingOptionsForContactPath = '/v2/messaging/options/contact/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetMessagingOptionsForContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForContact$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingOptionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetMessagingOptionsForContactPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingOptionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetMessagingOptionsForContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForContact(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<AxMessagingOptionsDto> {
    return this.messagingControllerGetMessagingOptionsForContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingOptionsDto>): AxMessagingOptionsDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetMessagingTemplate()` */
  static readonly MessagingControllerGetMessagingTemplatePath = '/v2/messaging/messagingTemplate/byName/{templateName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetMessagingTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingTemplate$Response(
    params: {
      templateName: AxMessagingTemplateNameEnum;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetMessagingTemplatePath, 'get');
    if (params) {
      rb.path('templateName', params.templateName, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingTemplateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetMessagingTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingTemplate(
    params: {
      templateName: AxMessagingTemplateNameEnum;
    },
    context?: HttpContext
  ): Observable<AxMessagingTemplateDto> {
    return this.messagingControllerGetMessagingTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingTemplateDto>): AxMessagingTemplateDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetProjectAppointmentDatesOverviewMessageOptions()` */
  static readonly MessagingControllerGetProjectAppointmentDatesOverviewMessageOptionsPath = '/v2/messaging/projectAppointmentDatesOverviewMessageOptions/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetProjectAppointmentDatesOverviewMessageOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetProjectAppointmentDatesOverviewMessageOptions$Response(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProjectAppointmentDatesOverviewMessageOptionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetProjectAppointmentDatesOverviewMessageOptionsPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProjectAppointmentDatesOverviewMessageOptionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetProjectAppointmentDatesOverviewMessageOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetProjectAppointmentDatesOverviewMessageOptions(
    params: {
      projectId: number;
    },
    context?: HttpContext
  ): Observable<AxProjectAppointmentDatesOverviewMessageOptionsDto> {
    return this.messagingControllerGetProjectAppointmentDatesOverviewMessageOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProjectAppointmentDatesOverviewMessageOptionsDto>): AxProjectAppointmentDatesOverviewMessageOptionsDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate()` */
  static readonly MessagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplatePath = '/v2/messaging/projectAppointmentDatesOverviewConfirmationTemplate/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate$Response(
    params: {
      projectId: number;
      appointmentTaskIds?: Array<number>;
      montageStartAndEnd: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingTemplateDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplatePath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.query('appointmentTaskIds', params.appointmentTaskIds, {});
      rb.query('montageStartAndEnd', params.montageStartAndEnd, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingTemplateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate(
    params: {
      projectId: number;
      appointmentTaskIds?: Array<number>;
      montageStartAndEnd: boolean;
    },
    context?: HttpContext
  ): Observable<AxMessagingTemplateDto> {
    return this.messagingControllerGetProjectAppointmentDatesOverviewConfirmationTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingTemplateDto>): AxMessagingTemplateDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerSendMessage()` */
  static readonly MessagingControllerSendMessagePath = '/v2/messaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingControllerSendMessage$Response(
    params: {
      body: AxSendMessageDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerSendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingControllerSendMessage(
    params: {
      body: AxSendMessageDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.messagingControllerSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
