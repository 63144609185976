import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { TerminassistentState } from '../ngxs/terminassistent/terminassistent.state';

export enum TerminassistentStepsLabelsEnum {
  START = 'Start',
  LOCATION = 'Ort',
  TYPE = 'Dienstleistung',
  TIME = 'Termin',
  DATA = 'Kontaktinfos',
  VERIFICATION = 'Bestätigung',
}

@Injectable({
  providedIn: 'root'
})
export class TerminassistentWizardStepsNavService {
  public currentRoute = '/';
  public destinationRoute = '/';
  public currentIndex = 0;
  public reservationSuccessFull = false;
  public steps = [
    {
      label: TerminassistentStepsLabelsEnum.START,
      route: '/terminassistent/intro',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: true,
      note: ''
    },
    {
      label: TerminassistentStepsLabelsEnum.LOCATION,
      route: '/terminassistent/ort',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: false,
      note: ''
    },
    {
      label: TerminassistentStepsLabelsEnum.TYPE,
      route: '/terminassistent/typ',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: false,
      note: ''
    },
    {
      label: TerminassistentStepsLabelsEnum.TIME,
      route: '/terminassistent/zeit',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: false,
      note: ''
    },
    {
      label: TerminassistentStepsLabelsEnum.DATA,
      route: '/terminassistent/daten',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: false,
      note: ''
    },
    {
      label: TerminassistentStepsLabelsEnum.VERIFICATION,
      route: '/terminassistent/verifizierung',
      isComplete: false,
      isActive: false,
      isVisible: true,
      isClickable: false,
      note: ''
    }
  ];
  public wizardStepNavGuard = true;
  public showLoading = false;
  public showNav = true;
  private routerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private store: Store
  ) {
  }

  public observeRouterChanges() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd || event instanceof Scroll) {

        if (event instanceof Scroll) {
          if (event.routerEvent instanceof NavigationEnd) {
            this.currentRoute = event.routerEvent.urlAfterRedirects;
          }
          const index = this.steps.findIndex(step => step.route === this.currentRoute);
          this.currentIndex = index >= 0 ? index : 0;
        } else {
          this.currentRoute = event.url;
        }

        this.steps.forEach((step, index) => {
          step.isActive = step.route === this.currentRoute;
          step.isComplete = !this.checkStepNavGuard(step.label);
          if (this.steps[index + 1]) {
            this.steps[index + 1].isClickable = !this.checkStepNavGuard(step.label);
          }
          if (step.route === this.currentRoute) {
            this.currentIndex = index;
            this.wizardStepNavGuard = this.checkStepNavGuard(step.label);
          }
        });
      } else if (event instanceof NavigationStart) {
        this.destinationRoute = event.url;
      }
    });
  }

  public unSubScribeFromRouterChanges() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public async goToNextStep() {
    if (this.currentIndex < this.steps.length - 1) {
      this.currentIndex += 1;
      const nextStep = this.steps[this.currentIndex];
      await this.router.navigateByUrl(nextStep.route);
      window.scrollTo(0, 0);
    }
  }

  public async goToPreviousStep() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      const previousStep = this.steps[this.currentIndex];
      await this.router.navigateByUrl(previousStep.route);
    } else {
      const previousRoute = this.store.selectSnapshot(TerminassistentState.routeBeforeTerminassistentStart);
      if (previousRoute) {
        await this.router.navigateByUrl(previousRoute);
      }
    }
  }

  public checkStepNavGuard(destination: string): boolean {
    switch (destination) {
      case TerminassistentStepsLabelsEnum.LOCATION:
        return !this.store.selectSnapshot(TerminassistentState.location);
      case TerminassistentStepsLabelsEnum.TYPE:
        return !this.store.selectSnapshot(TerminassistentState.reservationtype);
      case TerminassistentStepsLabelsEnum.TIME:
        return !this.store.selectSnapshot(TerminassistentState.timeslot);
      case TerminassistentStepsLabelsEnum.DATA:
        return true;
      case TerminassistentStepsLabelsEnum.VERIFICATION:
        return true;
      default: {
        return false;
      }
    }
  }

  public showLoader() {
    this.showLoading = true;
  }

  public hideLoader() {
    this.showLoading = false;
  }

  public hideWizardNav() {
    this.showNav = false;
  }
}
