export type TableHeaderSortStatus = 'ASC' | 'DESC' | 'NONE'

export interface TableHeaderOptions {
  label: string;
  name: string;
  sortable: boolean;
  selectable?: boolean;
  alignRight?: boolean;
  sortStatus?: TableHeaderSortStatus;
  smallerSize?: boolean;
  selectedStatus?: boolean;
}
