/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAppAccessCodeDto } from '../models/ax-app-access-code-dto';
import { AxContact } from '../models/ax-contact';
import { AxCreateContactDto } from '../models/ax-create-contact-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxUpdateContactDto } from '../models/ax-update-contact-dto';

@Injectable({ providedIn: 'root' })
export class AxContactsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactsControllerFindAllPaginated()` */
  static readonly ContactsControllerFindAllPaginatedPath = '/v2/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by categoryId query param.
     *           <p>
     *              <b>Format: </b> filter.categoryId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.categoryId=$not:$like:John Doe&filter.categoryId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.categoryId'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> lastName:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>company</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>street</li>
     * <li>zipcode</li>
     * <li>city</li>
     * <li>category.name</li></ul>
     */
      sortBy?: Array<'company:ASC' | 'company:DESC' | 'firstName:ASC' | 'firstName:DESC' | 'lastName:ASC' | 'lastName:DESC' | 'street:ASC' | 'street:DESC' | 'zipcode:ASC' | 'zipcode:DESC' | 'city:ASC' | 'city:DESC' | 'category.name:ASC' | 'category.name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> company,firstName,lastName,street,zipcode
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>company</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>street</li>
     * <li>zipcode</li>
     * <li>city</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'categoryId'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.businessunitId', params['filter.businessunitId'], {});
      rb.query('filter.categoryId', params['filter.categoryId'], {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxContact>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'businessunitId'?: (string | Array<string>);
        'categoryId'?: (string | Array<string>);
        'active'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by categoryId query param.
     *           <p>
     *              <b>Format: </b> filter.categoryId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.categoryId=$not:$like:John Doe&filter.categoryId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.categoryId'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> lastName:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>company</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>street</li>
     * <li>zipcode</li>
     * <li>city</li>
     * <li>category.name</li></ul>
     */
      sortBy?: Array<'company:ASC' | 'company:DESC' | 'firstName:ASC' | 'firstName:DESC' | 'lastName:ASC' | 'lastName:DESC' | 'street:ASC' | 'street:DESC' | 'zipcode:ASC' | 'zipcode:DESC' | 'city:ASC' | 'city:DESC' | 'category.name:ASC' | 'category.name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> company,firstName,lastName,street,zipcode
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>company</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>street</li>
     * <li>zipcode</li>
     * <li>city</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'categoryId'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}> {
    return this.contactsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'categoryId'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxContact>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'categoryId'?: (string | Array<string>);
'active'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `contactsControllerCreate()` */
  static readonly ContactsControllerCreatePath = '/v2/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerCreate$Response(
    params: {
      body: AxCreateContactDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxContact>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxContact>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerCreate(
    params: {
      body: AxCreateContactDto
    },
    context?: HttpContext
  ): Observable<AxContact> {
    return this.contactsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxContact>): AxContact => r.body)
    );
  }

  /** Path part for operation `contactsControllerFindOneById()` */
  static readonly ContactsControllerFindOneByIdPath = '/v2/contacts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxContact>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxContact>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxContact> {
    return this.contactsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxContact>): AxContact => r.body)
    );
  }

  /** Path part for operation `contactsControllerUpdate()` */
  static readonly ContactsControllerUpdatePath = '/v2/contacts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateContactDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactsControllerUpdate(
    params: {
      id: number;
      body: AxUpdateContactDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactsControllerGenerateAppAccessCodeForContact()` */
  static readonly ContactsControllerGenerateAppAccessCodeForContactPath = '/v2/contacts/{id}/appAccessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerGenerateAppAccessCodeForContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerGenerateAppAccessCodeForContact$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxAppAccessCodeDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerGenerateAppAccessCodeForContactPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxAppAccessCodeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerGenerateAppAccessCodeForContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerGenerateAppAccessCodeForContact(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxAppAccessCodeDto> {
    return this.contactsControllerGenerateAppAccessCodeForContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxAppAccessCodeDto>): AxAppAccessCodeDto => r.body)
    );
  }

  /** Path part for operation `contactsControllerDeleteAppAccessCodeForContact()` */
  static readonly ContactsControllerDeleteAppAccessCodeForContactPath = '/v2/contacts/{id}/appAccessCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerDeleteAppAccessCodeForContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerDeleteAppAccessCodeForContact$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxContactsV2Service.ContactsControllerDeleteAppAccessCodeForContactPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactsControllerDeleteAppAccessCodeForContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerDeleteAppAccessCodeForContact(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactsControllerDeleteAppAccessCodeForContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
