<div class="ax-ui-accordion-item"
     role="tab"
     [attr.aria-expanded]="isOpen"
     [class.ax-ui-accordion-item--with-border]="withBorder"
     [class.ax-ui-accordion-item--is-disabled]="isDisabled"
     [class.ax-ui-accordion-item--no-padding]="noPadding">
  <div #heading
       (click)="toggle()"
       [class.open]="isOpen"
       [class.no-hover-effect]="headerNoHoverEffect"
       [class.space-between]="headerIconSpaceBetween"
       class="flex flex-row vertical-center"
       [ngClass]="noPadding ? 'gap-8' : 'gap-24'"
       role="heading"
       aria-level="4">
    <ng-content select="[accordion-header]" />
    <ng-content select="[accordion-icon]"></ng-content>
    @if (!hideIcon) {
      <ax-ui-icon [fontSize]="40"
                  name="add" />
    }
  </div>
  <div [class.open]="isOpen"
       role="region">
    <div class="content">
      <ng-content select="[accordion-content]" />
    </div>
  </div>
</div>
