<ax-ui-modal #confirmationModal
             (modalClosedWithNoButton)="declineAction()"
             [hideCloseButton]="modalOptions.hideCloseButton || false"
             [modalWidth]="'small'"
             [titleText]="modalOptions.title!"
             [introText]="modalOptions.infoText || ''"
             modalId="confirmationModal">
  <ng-container modal-footer>
    @if (modalOptions.buttons && modalOptions.buttons.length > 2) {
      <div class="flex flex-column gap-12 width-100">
        @for (button of modalOptions.buttons; track button) {
          <ax-ui-button (click)="doAction(button)"
                        [fullWidth]="true"
                        [text]="button.label"
                        [variant]="button.variant" />
        }
      </div>
    } @else {
      @for (button of modalOptions.buttons; track button) {
        <ax-ui-button (click)="doAction(button)"
                      [fullWidth]="true"
                      style="flex: 1"
                      [text]="button.label"
                      [variant]="button.variant" />
      }
    }
  </ng-container>
</ax-ui-modal>
