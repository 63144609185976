<div class="ax-ui-terminassistent-zip-to-business-unit">
  <h2 class="ax-web-text--large margin-bottom-24">Standort der Immobilie</h2>
  <p>Bitte geben Sie als erstes <b>Postleitzahl</b> oder <b>Ort</b> ein, an welchem sich die zu besichtigende Immobilie
    befindet.</p>

  <ax-ui-input-field (valueChange)="search($event)"
                     [(value)]="location"
                     placeholder="PLZ oder Ort eingeben (min. 3 Ziffern)"
                     iconName="location_on"
                     class="margin-top-32" />
  @if (locationResults.length > 0) {
    <p class="margin-bottom-12">Wählen Sie Ihren Ort aus:</p>
    <div class="ax-card no-padding fade-in">
      <ul class="ax-list" role="list">
        @for (result of locationResults; track result; let i = $index) {
          <li (click)="chooseLocation(result, i)"
              [class.active]="selectedLocationIndex === i"
              [class.disabled]="!result.businessunit && (internalView$ | async) === undefined"
              class="ax-action-element"
              role="listitem">
            <div class="flex  flex-row gap-12 space-between vertical-center width-100">
              <p class="text-overflow-ellipsis">{{ result.zipcode }} {{ result.name }}</p>
              @if (result.businessunit) {
                <ax-ui-icon name="arrow_forward" />
              }
            </div>
            @if (!result.businessunit) {
              <ax-ui-label variant="warning"
                           borderStyle="square"
                           [textWrap]="true"
                           [text]="(internalView$ | async) ? 'Eigentlich bedienen wir diesen Ort nicht. Bei Ausnahmen kann man aber trotzdem hier klicken' : 'Dieser Ort wird (aktuell) nicht von uns bedient'" />
            }
          </li>
        }
      </ul>
    </div>
  } @else if (showNoResultError) {
    <p class="color-error"><small>Standort nicht gefunden</small></p>
    <br>
    <p><small>Stimmen Ihre Angaben? Ist vielleicht etwas falsch geschrieben? Versuchen Sie es bitte nochmal.</small></p>

    <hr class="margin-top-64 margin-bottom-32">
    <p><b>Sie finden Ihren Standort weiterhin nicht?</b></p>
    <p><small>Rufen Sie uns an – <a href="tel:+41800400222">0800 400 222</a> – wir finden eine Lösung.</small></p>
  }
  @if (showLocationUsageExplanation) {
    <p><small>Damit Sie fortfahren können, benötigen wir den Standort der Immobilie, um zu prüfen, ob wir unseren
      Service dort anbieten.</small></p>
  }
  @if (showTooShortQueryHint) {
    <p><small>Geben Sie in der Suche mindestens 3 Ziffern ein</small></p>
  }
</div>
