<div>
  <h2 class="ax-web-text--large margin-bottom-24">Ihr Projekt Schwerpunkt</h2>
  <p>
    Bitte teilen Sie uns mit, zu welcher Art von Dienstleistung Sie gerne für Ihr Projekt beraten werden möchten:
  </p>

  <ul class="margin-top-32 flex flex-column gap-24">
    @for (reservationType of reservationTypes; track reservationType; let i = $index) {
      <li (click)="chooseType(reservationType, i)"
          class="ax-card ax-card--clickable"
          [class.ax-card--selected]="selectedReservationTypeIndex === i">
        <div class="flex  flex-row gap-12 space-between width-100">
          <p class="ax-web-text--medium"><b class="ax-text-bold">{{ reservationType.description }}</b></p>
          <ax-ui-icon name="arrow_forward" />
        </div>
        <p>{{ reservationType.additionalInfotext }}</p>
        <p class="color-light"><small>{{ reservationType.name }} dauert ca. {{ reservationType.durationInMinutes }}
          Minuten</small></p>
        @if (!reservationType.isActiveForSelectedBusinessunit) {
          <ax-ui-label text="Hinweis: Diese Dienstleistung wird aktuell in Ihrem Wohnort nicht angeboten."
                       variant="error"
                       class="display-block margin-top-12"
                       borderStyle="square"
                       [textWrap]="true" />
        }
      </li>
    }
  </ul>
</div>
