<div class="ax-ui-montageplan-day-overview">
  <div class="ax-ui-montageplan-day-overview__day">
    <h2>{{ date | formatDate:'dd' }}, {{ date | formatDate }}</h2>
  </div>
  <div class="ax-ui-montageplan-day-overview__content">
    <div class="ax-ui-montageplan-day-overview__teams">
      @for (teamDto of montageplanTeamDtos; track teamDto) {
        <div class="ax-ui-montageplan-day-overview__team ax-card"
             [class.ax-ui-montageplan-day-overview__team--disabled]="isEntireTeamAbsent(teamMembers.teams[teamDto.teamId], date)"
        >
          <div class="ax-ui-montageplan-day-overview__team-header">
            <h1 class="ax-ui-montageplan-day-overview__team-header-heading">{{ teamDto.team }}</h1>
            <div class="ax-ui-montageplan-day-overview__team-members">
              @for (member of teamMembers.teams[teamDto.teamId]; track member) {
                @if(member && member.user) {
                  @let timerecordtypeName = (member.timerecords && member.timerecords[date] && member.timerecords[date].timerecordtypeName) ? member.timerecords[date].timerecordtypeName : '';
                  @let status = timerecordtypeName && timerecordtypeName === axTimerecordtypeNameEnum.Arbeit ? 'available' : 'absent';
                  <ax-ui-avatar size="default"
                                [initials]="member.user.firstName.substring(0,1)+member.user.lastName.substring(0,1)"
                                [title]="member.user.firstName + ' ' + member.user.lastName + ' - ' + timerecordtypeName"
                                [status]="status"
                                [imgSrc]="member.user.userImage ? (member.user.userImage.fileSrcThumbnail | prependServerToFileSrc) : null" />
                }
              }
            </div>
          </div>
          <div class="ax-ui-montageplan-day-overview__team-projects">
            @for (block of teamDto.blocks; track block) {
              <div class="ax-ui-montageplan-day-overview__team-project">
                <p>{{ block.projectName }}</p>
                <h5>{{ block.customerName }}</h5>
              </div>
            }
          </div>
          @if (teamDto.tasks && teamDto.tasks.length) {
            <div class="ax-ui-montageplan-day-overview__team-tasks">
              @for (task of teamDto.tasks; track task) {
                <div class="ax-ui-montageplan-day-overview__team-task">
                  <ax-ui-button [iconOnly]="true"
                                [small]="true"
                                iconName="checklist"
                                variant="primary" />
                  <p>
                    @if ((task.start | formatDate:'HH:mm') !== '00:00') {
                      <b>{{ task.start | formatDate:'HH:mm' }} - </b>
                    }
                    @if (task.project) {
                      <span>{{ task.project.numberQualified }}: </span>
                    }{{ task.name }}@if (task.status) {
                    <span> ({{ task.status | translate }})</span>
                  }
                  </p>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
