/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateEventInvitationV2Dto } from '../models/ax-create-event-invitation-v-2-dto';
import { AxEventInvitation } from '../models/ax-event-invitation';
import { AxNotInvitedFromAudienceDto } from '../models/ax-not-invited-from-audience-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxUpdateEventInvitationV2Dto } from '../models/ax-update-event-invitation-v-2-dto';

@Injectable({ providedIn: 'root' })
export class AxEventInvitationsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventInvitationsControllerFindAllPaginated()` */
  static readonly EventInvitationsControllerFindAllPaginatedPath = '/v2/event-invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by acceptedAt query param.
     *           <p>
     *              <b>Format: </b> filter.acceptedAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.acceptedAt=$not:$like:John Doe&filter.acceptedAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.acceptedAt'?: Array<string>;

    /**
     * Filter by declinedAt query param.
     *           <p>
     *              <b>Format: </b> filter.declinedAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.declinedAt=$not:$like:John Doe&filter.declinedAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.declinedAt'?: Array<string>;

    /**
     * Filter by eventId query param.
     *           <p>
     *              <b>Format: </b> filter.eventId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.eventId=$not:$like:John Doe&filter.eventId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.eventId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> user.firstName:ASC,contact.firstName:ASC,email:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>user.firstName</li>
     * <li>contact.firstName</li>
     * <li>acceptedAt</li>
     * <li>declinedAt</li></ul>
     */
      sortBy?: Array<'user.firstName:ASC' | 'user.firstName:DESC' | 'contact.firstName:ASC' | 'contact.firstName:DESC' | 'acceptedAt:ASC' | 'acceptedAt:DESC' | 'declinedAt:ASC' | 'declinedAt:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> user.firstName,user.lastName,contact.firstName,contact.lastName,firstName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>user.firstName</li>
     * <li>user.lastName</li>
     * <li>contact.firstName</li>
     * <li>contact.lastName</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>email</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEventInvitation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'acceptedAt'?: (string | Array<string>);
'declinedAt'?: (string | Array<string>);
'eventId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.acceptedAt', params['filter.acceptedAt'], {});
      rb.query('filter.declinedAt', params['filter.declinedAt'], {});
      rb.query('filter.eventId', params['filter.eventId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxEventInvitation>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'acceptedAt'?: (string | Array<string>);
        'declinedAt'?: (string | Array<string>);
        'eventId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by acceptedAt query param.
     *           <p>
     *              <b>Format: </b> filter.acceptedAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.acceptedAt=$not:$like:John Doe&filter.acceptedAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.acceptedAt'?: Array<string>;

    /**
     * Filter by declinedAt query param.
     *           <p>
     *              <b>Format: </b> filter.declinedAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.declinedAt=$not:$like:John Doe&filter.declinedAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$null</li>
     * <li>$not</li></ul>
     */
      'filter.declinedAt'?: Array<string>;

    /**
     * Filter by eventId query param.
     *           <p>
     *              <b>Format: </b> filter.eventId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.eventId=$not:$like:John Doe&filter.eventId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.eventId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> user.firstName:ASC,contact.firstName:ASC,email:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>user.firstName</li>
     * <li>contact.firstName</li>
     * <li>acceptedAt</li>
     * <li>declinedAt</li></ul>
     */
      sortBy?: Array<'user.firstName:ASC' | 'user.firstName:DESC' | 'contact.firstName:ASC' | 'contact.firstName:DESC' | 'acceptedAt:ASC' | 'acceptedAt:DESC' | 'declinedAt:ASC' | 'declinedAt:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> user.firstName,user.lastName,contact.firstName,contact.lastName,firstName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>user.firstName</li>
     * <li>user.lastName</li>
     * <li>contact.firstName</li>
     * <li>contact.lastName</li>
     * <li>firstName</li>
     * <li>lastName</li>
     * <li>email</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxEventInvitation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'acceptedAt'?: (string | Array<string>);
'declinedAt'?: (string | Array<string>);
'eventId'?: (string | Array<string>);
};
};
}> {
    return this.eventInvitationsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEventInvitation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'acceptedAt'?: (string | Array<string>);
'declinedAt'?: (string | Array<string>);
'eventId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxEventInvitation>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'acceptedAt'?: (string | Array<string>);
'declinedAt'?: (string | Array<string>);
'eventId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerCreate_1()` */
  static readonly EventInvitationsControllerCreate_1Path = '/v2/event-invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerCreate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerCreate_1$Response(
    params: {
      body: AxCreateEventInvitationV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerCreate_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerCreate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerCreate_1(
    params: {
      body: AxCreateEventInvitationV2Dto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.eventInvitationsControllerCreate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerFindOneById()` */
  static readonly EventInvitationsControllerFindOneByIdPath = '/v2/event-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEventInvitation>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventInvitation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEventInvitation> {
    return this.eventInvitationsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEventInvitation>): AxEventInvitation => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerUpdate_1()` */
  static readonly EventInvitationsControllerUpdate_1Path = '/v2/event-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerUpdate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdate_1$Response(
    params: {
      id: number;
      body: AxUpdateEventInvitationV2Dto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEventInvitation>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerUpdate_1Path, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventInvitation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerUpdate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventInvitationsControllerUpdate_1(
    params: {
      id: number;
      body: AxUpdateEventInvitationV2Dto
    },
    context?: HttpContext
  ): Observable<AxEventInvitation> {
    return this.eventInvitationsControllerUpdate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEventInvitation>): AxEventInvitation => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerDelete_1()` */
  static readonly EventInvitationsControllerDelete_1Path = '/v2/event-invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerDelete_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerDelete_1$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerDelete_1Path, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerDelete_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerDelete_1(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventInvitationsControllerDelete_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerFindMyInvitationByEventId()` */
  static readonly EventInvitationsControllerFindMyInvitationByEventIdPath = '/v2/event-invitations/myInvitationByEventId/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindMyInvitationByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindMyInvitationByEventId$Response(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEventInvitation>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerFindMyInvitationByEventIdPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventInvitation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindMyInvitationByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindMyInvitationByEventId(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<AxEventInvitation> {
    return this.eventInvitationsControllerFindMyInvitationByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEventInvitation>): AxEventInvitation => r.body)
    );
  }

  /** Path part for operation `eventInvitationsControllerFindNotInvitedFromAudiences()` */
  static readonly EventInvitationsControllerFindNotInvitedFromAudiencesPath = '/v2/event-invitations/{eventId}/not-invited-from-audiences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventInvitationsControllerFindNotInvitedFromAudiences()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindNotInvitedFromAudiences$Response(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxNotInvitedFromAudienceDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventInvitationsV2Service.EventInvitationsControllerFindNotInvitedFromAudiencesPath, 'get');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNotInvitedFromAudienceDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventInvitationsControllerFindNotInvitedFromAudiences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventInvitationsControllerFindNotInvitedFromAudiences(
    params: {
      eventId: number;
    },
    context?: HttpContext
  ): Observable<AxNotInvitedFromAudienceDto> {
    return this.eventInvitationsControllerFindNotInvitedFromAudiences$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxNotInvitedFromAudienceDto>): AxNotInvitedFromAudienceDto => r.body)
    );
  }

}
