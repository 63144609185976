import { Route } from '@angular/router';
import { NavigationGroupInterface } from '@axova-frontend-monorepo/axova-commons';

export const extractInlineNavigationItemsFromRouteChildren = (route: Route): NavigationGroupInterface[] => {
  const navigationGroups: { [key: string]: NavigationGroupInterface } = {};

  route.children?.forEach((childRoute: any) => {
    if (childRoute.data?.navigationItem && !childRoute.data?.navigationItem.excludeFromSideNav) {
      const groupKey = childRoute.data.navigationGroup || 0;

      if (!navigationGroups[groupKey]) {
        navigationGroups[groupKey] = {
          title: typeof groupKey === 'string' ? groupKey : undefined,
          items: [],
        };
      }

      navigationGroups[groupKey].items.push({
        label: childRoute.data.navigationItem.label,
        icon: childRoute.data.navigationItem.icon,
        route: childRoute.path,
        outlet: childRoute.outlet,
        requiredPermission: childRoute.data.requiredPermission,
      });
    }
  });

  // Convert the grouped object into an array of NavigationGroupInterface
  return Object.values(navigationGroups);
};
