import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartSeries } from '../../../models/chart-options.type';

@Component({
  selector: 'ax-ui-chart-column',
  templateUrl: './chart-column.component.html',
  styleUrls: ['./chart-column.component.scss'],
  standalone: true,
  imports: [NgApexchartsModule],
})
export class ChartColumnComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  @Input() title = '';
  @Input() unitName = '';
  @Input() xAxisLabels: string[] = [];
  @Input() xAxisValues: number[] = [];
  @Input() chartSeries: ChartSeries[] = [];
  @Input() showToolbar = true;
  private chartFillColors: string[] = ['#ce0a7c', '#661da5'];
  public chartOptions: ChartOptions = {
    series: [],
    chart: {
      height: 300,
      type: 'bar',
      fontFamily: 'Gotham Narrow Regular, Tahoma, Verdana, sans-serif',
      toolbar: {
        show: this.showToolbar,
      },
    },
    plotOptions: {
      bar: {
        borderRadiusApplication: 'end',
        horizontal: false,
        columnWidth: '40%',
        borderRadius: 8,
        dataLabels: {
          position: 'top',
        },
      },
    },
    xaxis: {
      categories: this.xAxisLabels,
      labels: {
        style: {
          colors: '#343A40',
        },
      },
    },
    yaxis: {
      max: function (val: number) {
        return Math.ceil((val * 1.1) / 10) * 10;
      },
      tickAmount: 4,
      labels: {
        style: {
          colors: '#ADB5BDFF',
        },
      },
    },
    title: {
      text: this.title ? this.translateService.instant(this.title) : '',
    },
    dataLabels: {
      style: {
        colors: ['#343A40'],
        fontFamily: 'Gotham Narrow Medium, Tahoma, Verdana, sans-serif',
      },
      offsetY: -25,
    },
    noData: {
      text: 'Lade Daten...',
    },
    legend: {
      position: 'top',
      labels: {
        colors: '#ADB5BDFF',
      },
      markers: {
        fillColors: this.chartFillColors,
      },
    },
    fill: {
      colors: this.chartFillColors,
    },
  };

  constructor(private readonly translateService: TranslateService) {}

  async ngOnInit() {
    await this.updateChartsOptions();
  }

  private async updateChartsOptions() {
    this.chartOptions.series = this.chartSeries;
    this.chartOptions.title.text = this.title ? this.translateService.instant(this.title) : '';
    this.chartOptions.xaxis.categories = this.xAxisLabels;
  }
}
