/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxEivCalculationsDto } from '../models/ax-eiv-calculations-dto';
import { AxFoerderungLocationCommunitySponsorshipDto } from '../models/ax-foerderung-location-community-sponsorship-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxProject } from '../models/ax-project';
import { AxProjectFoerderungUsedItemsWithAmountDto } from '../models/ax-project-foerderung-used-items-with-amount-dto';
import { AxProjectModulesAreaDto } from '../models/ax-project-modules-area-dto';
import { AxProjectPowerWrAcDto } from '../models/ax-project-power-wr-ac-dto';
import { AxProjectWithLocationCommunitySponsorshipDto } from '../models/ax-project-with-location-community-sponsorship-dto';
import { AxUpdateProjectAnlageUndObjektdatenDto } from '../models/ax-update-project-anlage-und-objektdaten-dto';
import { AxUpdateProjectFoerderungDto } from '../models/ax-update-project-foerderung-dto';

@Injectable({ providedIn: 'root' })
export class AxProjectsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectsControllerFindAllPaginated()` */
  static readonly ProjectsControllerFindAllPaginatedPath = '/v2/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by objectZip query param.
     *           <p>
     *              <b>Format: </b> filter.objectZip={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.objectZip=$not:$like:John Doe&filter.objectZip=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.objectZip'?: Array<string>;

    /**
     * Filter by installationStart query param.
     *           <p>
     *              <b>Format: </b> filter.installationStart={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.installationStart=$not:$like:John Doe&filter.installationStart=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$btw</li></ul>
     */
      'filter.installationStart'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.businessunitId=$not:$like:John Doe&filter.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.containertypeId=$not:$like:John Doe&filter.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.containertypeId'?: Array<string>;

    /**
     * Filter by container.contact.zipcode query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.zipcode={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.zipcode=$not:$like:John Doe&filter.container.contact.zipcode=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.zipcode'?: Array<string>;

    /**
     * Filter by status.id query param.
     *           <p>
     *              <b>Format: </b> filter.status.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.status.id=$not:$like:John Doe&filter.status.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.status.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>numberQualified</li>
     * <li>installationStart</li>
     * <li>installationEnd</li>
     * <li>sale</li>
     * <li>implementing</li>
     * <li>container.contact.lastName</li>
     * <li>objectCity</li>
     * <li>status.id</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'numberQualified:ASC' | 'numberQualified:DESC' | 'installationStart:ASC' | 'installationStart:DESC' | 'installationEnd:ASC' | 'installationEnd:DESC' | 'sale:ASC' | 'sale:DESC' | 'implementing:ASC' | 'implementing:DESC' | 'container.contact.lastName:ASC' | 'container.contact.lastName:DESC' | 'objectCity:ASC' | 'objectCity:DESC' | 'status.id:ASC' | 'status.id:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> objectZip,objectCity,container.contact.zipcode,container.contact.city,numberQualified
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>objectZip</li>
     * <li>objectCity</li>
     * <li>container.contact.zipcode</li>
     * <li>container.contact.city</li>
     * <li>numberQualified</li>
     * <li>container.contact.lastName</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'objectZip'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'active'?: (string | Array<string>);
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'container.contact.zipcode'?: (string | Array<string>);
'status.id'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.objectZip', params['filter.objectZip'], {});
      rb.query('filter.installationStart', params['filter.installationStart'], {});
      rb.query('filter.active', params['filter.active'], {});
      rb.query('filter.container.contact.businessunitId', params['filter.container.contact.businessunitId'], {});
      rb.query('filter.container.containertypeId', params['filter.container.containertypeId'], {});
      rb.query('filter.container.contact.zipcode', params['filter.container.contact.zipcode'], {});
      rb.query('filter.status.id', params['filter.status.id'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxProject>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'objectZip'?: (string | Array<string>);
        'installationStart'?: (string | Array<string>);
        'active'?: (string | Array<string>);
        'container.contact.businessunitId'?: (string | Array<string>);
        'container.containertypeId'?: (string | Array<string>);
        'container.contact.zipcode'?: (string | Array<string>);
        'status.id'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by objectZip query param.
     *           <p>
     *              <b>Format: </b> filter.objectZip={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.objectZip=$not:$like:John Doe&filter.objectZip=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.objectZip'?: Array<string>;

    /**
     * Filter by installationStart query param.
     *           <p>
     *              <b>Format: </b> filter.installationStart={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.installationStart=$not:$like:John Doe&filter.installationStart=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$btw</li></ul>
     */
      'filter.installationStart'?: Array<string>;

    /**
     * Filter by active query param.
     *           <p>
     *              <b>Format: </b> filter.active={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.active=$not:$like:John Doe&filter.active=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.active'?: Array<string>;

    /**
     * Filter by container.contact.businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.businessunitId=$not:$like:John Doe&filter.container.contact.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.businessunitId'?: Array<string>;

    /**
     * Filter by container.containertypeId query param.
     *           <p>
     *              <b>Format: </b> filter.container.containertypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.containertypeId=$not:$like:John Doe&filter.container.containertypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.containertypeId'?: Array<string>;

    /**
     * Filter by container.contact.zipcode query param.
     *           <p>
     *              <b>Format: </b> filter.container.contact.zipcode={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.container.contact.zipcode=$not:$like:John Doe&filter.container.contact.zipcode=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.container.contact.zipcode'?: Array<string>;

    /**
     * Filter by status.id query param.
     *           <p>
     *              <b>Format: </b> filter.status.id={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.status.id=$not:$like:John Doe&filter.status.id=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.status.id'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> id:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>id</li>
     * <li>numberQualified</li>
     * <li>installationStart</li>
     * <li>installationEnd</li>
     * <li>sale</li>
     * <li>implementing</li>
     * <li>container.contact.lastName</li>
     * <li>objectCity</li>
     * <li>status.id</li></ul>
     */
      sortBy?: Array<'id:ASC' | 'id:DESC' | 'numberQualified:ASC' | 'numberQualified:DESC' | 'installationStart:ASC' | 'installationStart:DESC' | 'installationEnd:ASC' | 'installationEnd:DESC' | 'sale:ASC' | 'sale:DESC' | 'implementing:ASC' | 'implementing:DESC' | 'container.contact.lastName:ASC' | 'container.contact.lastName:DESC' | 'objectCity:ASC' | 'objectCity:DESC' | 'status.id:ASC' | 'status.id:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> objectZip,objectCity,container.contact.zipcode,container.contact.city,numberQualified
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>objectZip</li>
     * <li>objectCity</li>
     * <li>container.contact.zipcode</li>
     * <li>container.contact.city</li>
     * <li>numberQualified</li>
     * <li>container.contact.lastName</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'objectZip'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'active'?: (string | Array<string>);
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'container.contact.zipcode'?: (string | Array<string>);
'status.id'?: (string | Array<string>);
};
};
}> {
    return this.projectsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'objectZip'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'active'?: (string | Array<string>);
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'container.contact.zipcode'?: (string | Array<string>);
'status.id'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxProject>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'objectZip'?: (string | Array<string>);
'installationStart'?: (string | Array<string>);
'active'?: (string | Array<string>);
'container.contact.businessunitId'?: (string | Array<string>);
'container.containertypeId'?: (string | Array<string>);
'container.contact.zipcode'?: (string | Array<string>);
'status.id'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `projectsControllerFindOneById()` */
  static readonly ProjectsControllerFindOneByIdPath = '/v2/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOneById$Response(
    params: {
      id: number;
      withAllDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProject>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('withAllDetails', params.withAllDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProject>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOneById(
    params: {
      id: number;
      withAllDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxProject> {
    return this.projectsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProject>): AxProject => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetPowerWrAc()` */
  static readonly ProjectsControllerGetPowerWrAcPath = '/v2/projects/powerWrAc/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetPowerWrAc()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetPowerWrAc$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProjectPowerWrAcDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerGetPowerWrAcPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProjectPowerWrAcDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetPowerWrAc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetPowerWrAc(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxProjectPowerWrAcDto> {
    return this.projectsControllerGetPowerWrAc$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProjectPowerWrAcDto>): AxProjectPowerWrAcDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetFoerderungLocationCommunitySponsorship()` */
  static readonly ProjectsControllerGetFoerderungLocationCommunitySponsorshipPath = '/v2/projects/foerderungLocationCommunitySponsorship/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetFoerderungLocationCommunitySponsorship()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungLocationCommunitySponsorship$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxFoerderungLocationCommunitySponsorshipDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerGetFoerderungLocationCommunitySponsorshipPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxFoerderungLocationCommunitySponsorshipDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetFoerderungLocationCommunitySponsorship$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungLocationCommunitySponsorship(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxFoerderungLocationCommunitySponsorshipDto> {
    return this.projectsControllerGetFoerderungLocationCommunitySponsorship$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxFoerderungLocationCommunitySponsorshipDto>): AxFoerderungLocationCommunitySponsorshipDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetFoerderungUsedCalculationItems()` */
  static readonly ProjectsControllerGetFoerderungUsedCalculationItemsPath = '/v2/projects/foerderungUsedCalculationItems/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetFoerderungUsedCalculationItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungUsedCalculationItems$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProjectFoerderungUsedItemsWithAmountDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerGetFoerderungUsedCalculationItemsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProjectFoerderungUsedItemsWithAmountDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetFoerderungUsedCalculationItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungUsedCalculationItems(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxProjectFoerderungUsedItemsWithAmountDto> {
    return this.projectsControllerGetFoerderungUsedCalculationItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProjectFoerderungUsedItemsWithAmountDto>): AxProjectFoerderungUsedItemsWithAmountDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerGetFoerderungEivCalculations()` */
  static readonly ProjectsControllerGetFoerderungEivCalculationsPath = '/v2/projects/foerderungEivCalculations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetFoerderungEivCalculations()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungEivCalculations$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEivCalculationsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerGetFoerderungEivCalculationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEivCalculationsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetFoerderungEivCalculations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetFoerderungEivCalculations(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEivCalculationsDto> {
    return this.projectsControllerGetFoerderungEivCalculations$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEivCalculationsDto>): AxEivCalculationsDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerCalculateModulesArea()` */
  static readonly ProjectsControllerCalculateModulesAreaPath = '/v2/projects/modulesArea/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCalculateModulesArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCalculateModulesArea$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProjectModulesAreaDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerCalculateModulesAreaPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProjectModulesAreaDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCalculateModulesArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerCalculateModulesArea(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxProjectModulesAreaDto> {
    return this.projectsControllerCalculateModulesArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProjectModulesAreaDto>): AxProjectModulesAreaDto => r.body)
    );
  }

  /** Path part for operation `projectsControllerUpdateAnlageUndObjektDaten()` */
  static readonly ProjectsControllerUpdateAnlageUndObjektDatenPath = '/v2/projects/anlageDaten/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerUpdateAnlageUndObjektDaten()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdateAnlageUndObjektDaten$Response(
    params: {
      id: number;
      body: AxUpdateProjectAnlageUndObjektdatenDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProject>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerUpdateAnlageUndObjektDatenPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProject>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerUpdateAnlageUndObjektDaten$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdateAnlageUndObjektDaten(
    params: {
      id: number;
      body: AxUpdateProjectAnlageUndObjektdatenDto
    },
    context?: HttpContext
  ): Observable<AxProject> {
    return this.projectsControllerUpdateAnlageUndObjektDaten$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProject>): AxProject => r.body)
    );
  }

  /** Path part for operation `projectsControllerUpdateFoerderung()` */
  static readonly ProjectsControllerUpdateFoerderungPath = '/v2/projects/foerderung/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerUpdateFoerderung()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdateFoerderung$Response(
    params: {
      id: number;
      body: AxUpdateProjectFoerderungDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxProjectWithLocationCommunitySponsorshipDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxProjectsV2Service.ProjectsControllerUpdateFoerderungPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProjectWithLocationCommunitySponsorshipDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerUpdateFoerderung$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerUpdateFoerderung(
    params: {
      id: number;
      body: AxUpdateProjectFoerderungDto
    },
    context?: HttpContext
  ): Observable<AxProjectWithLocationCommunitySponsorshipDto> {
    return this.projectsControllerUpdateFoerderung$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxProjectWithLocationCommunitySponsorshipDto>): AxProjectWithLocationCommunitySponsorshipDto => r.body)
    );
  }

}
