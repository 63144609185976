import { Pipe, PipeTransform } from '@angular/core';
import { getResponsibleUserTypeFromEnum } from '../functions/get-responsible-user-type-from-enum';
import { AxStandardtaskResponsibleUsertypeEnum } from '@axova-frontend-monorepo/axova-rest-api';

@Pipe({
  name: 'getResponsibleUserTypeFromEnum',
  standalone: true,
})
export class GetResponsibleUserTypeFromEnumPipe implements PipeTransform {
  transform(userType: AxStandardtaskResponsibleUsertypeEnum | null): string {
    return getResponsibleUserTypeFromEnum(userType);
  }
}
