<div class="ax-library">
  <div class="ax-library-header">
    <div class="ax-library-header__content">
      <ax-ui-logo variant="white"></ax-ui-logo>
      <p><b>Component Library</b></p>
    </div>
  </div>
  <div class="ax-library-layout">

    <ax-component-library-container title="Logo">
      <ax-component-library-item description="Default">
        <ax-ui-logo></ax-ui-logo>
      </ax-component-library-item>

      <ax-component-library-item backgroundColor="green" description="White">
        <ax-ui-logo variant="white"></ax-ui-logo>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container [verticalDirection]="true" title="Typography">
      <ax-component-library-item description="Headings">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Subtitle 1 (H4)</h4>
        <h5>Subtitle 2 (H5)</h5>
      </ax-component-library-item>
      <ax-component-library-item description="Paragraph">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.</p>
      </ax-component-library-item>
      <ax-component-library-item description="Paragraph Bold">
        <p><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua.</b></p>
      </ax-component-library-item>
      <ax-component-library-item description="Paragraph small">
        <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua.</small>
      </ax-component-library-item>
      <ax-component-library-item description="Paragraph small Bold">
        <small><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua.</b></small>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Material Symbol Icons">
      <ax-component-library-item description="Size Medium (Default)">
        <ax-ui-icon name="shopping_cart"></ax-ui-icon>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container [verticalDirection]="true" title="Buttons">
      <ax-component-library-item description="Primary">
        <div class="ax-buttons">
          <ax-ui-button text="Default"></ax-ui-button>
          <ax-ui-button [disabled]="true" text="Disabled"></ax-ui-button>
          <ax-ui-button iconName="check" text="With Icon"></ax-ui-button>
          <ax-ui-button (click)="simulateRequestLoading()"
                        [(loadingState)]="loadingState"
                        [showLoadingOnClick]="true"
                        text="Trigger Load">
          </ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" iconName="check" text="With Icon Right"></ax-ui-button>
          <ax-ui-button [loadingState]="'loading'" iconName="check" text="With Icon"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Primary Small">
        <div class="ax-buttons">
          <ax-ui-button [small]="true" text="Default"></ax-ui-button>
          <ax-ui-button [disabled]="true" [small]="true" text="Disabled"></ax-ui-button>
          <ax-ui-button [small]="true" iconName="check" text="With Icon"></ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" [small]="true" iconName="check"
                        text="With Icon Right"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Secondary">
        <div class="ax-buttons">
          <ax-ui-button text="Default" variant="secondary"></ax-ui-button>
          <ax-ui-button [disabled]="true" text="Disabled" variant="secondary"></ax-ui-button>
          <ax-ui-button iconName="check" text="With Icon" variant="secondary"></ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" iconName="check" text="With Icon Right"
                        variant="secondary"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Secondary Small">
        <div class="ax-buttons">
          <ax-ui-button [small]="true" text="Default" variant="secondary"></ax-ui-button>
          <ax-ui-button [disabled]="true" [small]="true" text="Disabled" variant="secondary"></ax-ui-button>
          <ax-ui-button [small]="true" iconName="check" text="With Icon" variant="secondary"></ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" [small]="true" iconName="check" text="With Icon Right"
                        variant="secondary"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Tertiary">
        <div class="ax-buttons">
          <ax-ui-button text="Default" variant="tertiary"></ax-ui-button>
          <ax-ui-button [disabled]="true" text="Disabled" variant="tertiary"></ax-ui-button>
          <ax-ui-button iconName="check" text="With Icon" variant="tertiary"></ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" iconName="check" text="With Icon Right"
                        variant="tertiary"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Tertiary Small">
        <div class="ax-buttons">
          <ax-ui-button [small]="true" text="Default" variant="tertiary"></ax-ui-button>
          <ax-ui-button [disabled]="true" [small]="true" text="Disabled" variant="tertiary"></ax-ui-button>
          <ax-ui-button [small]="true" iconName="check" text="With Icon" variant="tertiary"></ax-ui-button>
          <ax-ui-button [iconPositionRight]="true" [small]="true" iconName="check" text="With Icon Right"
                        variant="tertiary"></ax-ui-button>
        </div>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container [verticalDirection]="true" title="Icon Buttons">
      <ax-component-library-item description="Primary">
        <div class="ax-buttons">
          <ax-ui-button [iconOnly]="true" iconName="edit"></ax-ui-button>
          <ax-ui-button [disabled]="true" [iconOnly]="true" iconName="edit"></ax-ui-button>
          <ax-ui-button (click)="simulateRequestLoading()"
                        [(loadingState)]="loadingState"
                        [iconOnly]="true"
                        [showLoadingOnClick]="true"
                        iconName="edit">
          </ax-ui-button>
          <ax-ui-button [iconOnly]="true" [loadingState]="'loading'" iconName="check"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Primary Small">
        <div class="ax-buttons">
          <ax-ui-button [iconOnly]="true" [small]="true" iconName="edit"></ax-ui-button>
          <ax-ui-button [disabled]="true" [iconOnly]="true" [small]="true" iconName="edit"></ax-ui-button>
          <ax-ui-button [iconOnly]="true" [loadingState]="'loading'" [small]="true" iconName="check"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Secondary">
        <div class="ax-buttons">
          <ax-ui-button [iconOnly]="true" iconName="edit" variant="secondary"></ax-ui-button>
          <ax-ui-button [disabled]="true" [iconOnly]="true" iconName="edit" variant="secondary"></ax-ui-button>
          <ax-ui-button [iconOnly]="true" [loadingState]="'loading'" iconName="check"
                        variant="secondary"></ax-ui-button>
        </div>
      </ax-component-library-item>

      <ax-component-library-item description="Secondary Small">
        <div class="ax-buttons">
          <ax-ui-button [iconOnly]="true" [small]="true" iconName="edit" variant="secondary"></ax-ui-button>
          <ax-ui-button [disabled]="true" [iconOnly]="true" [small]="true" iconName="edit"
                        variant="secondary"></ax-ui-button>
          <ax-ui-button [iconOnly]="true" [loadingState]="'loading'" [small]="true" iconName="check"
                        variant="secondary"></ax-ui-button>
        </div>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Boolean Selectors">
      <ax-component-library-item description="Checkbox">
        <fieldset>
          <legend>Demo Checkbox choices</legend>
          <ax-ui-checkbox [checked]="true" id="checkOption1" label="Option 1"></ax-ui-checkbox>
          <ax-ui-checkbox id="checkOption2" label="Option 2"></ax-ui-checkbox>
          <ax-ui-checkbox [disabled]="true" id="checkOption3" label="Disabled"></ax-ui-checkbox>
        </fieldset>
      </ax-component-library-item>

      <ax-component-library-item description="Radio Button">
        <form (ngSubmit)="onSubmit()" [formGroup]="radioTestForm">
          <ax-ui-radio-group [options]="testOptions"
                             formControlName="test"
                             legendText="Demo Radio Button choices">
          </ax-ui-radio-group>
        </form>
      </ax-component-library-item>

      <ax-component-library-item description="Toggle Switch">
        <ax-ui-toggle-switch [checked]="true" />
        <ax-ui-toggle-switch [disabled]="true" />
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Input Fields">
      <ax-component-library-item description="Input Field">
        <ax-ui-input-field name="Default input"
                           placeholder="Default Input">
        </ax-ui-input-field>
        <ax-ui-input-field label="Input with label"
                           name="Default input"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
        <ax-ui-input-field [required]="true"
                           label="Required"
                           name="Required input"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
        <ax-ui-input-field iconName="search"
                           label="Icon Input"
                           name="Icon input"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
        <ax-ui-input-field [iconRight]="true"
                           iconName="search"
                           label="Icon Input right"
                           name="Icon input right"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
        <ax-ui-input-field iconName="search"
                           name="Icon input without label"
                           placeholder="Icon input without label">
        </ax-ui-input-field>
        <ax-ui-input-field [invalid]="true"
                           errorMessage="This is a custom error message for this case"
                           label="Error input"
                           name="Error input"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
        <ax-ui-input-field [disabled]="true"
                           label="Disabled input"
                           name="Disabled input"
                           placeholder="Placeholder text">
        </ax-ui-input-field>
      </ax-component-library-item>

      <ax-component-library-item description="Text Field">
        <ax-ui-input-field name="Default input"
                           placeholder="Default Input"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field label="Input with label"
                           name="Default input"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field [required]="true"
                           label="Required"
                           name="Required input"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field iconName="search"
                           label="Icon Input"
                           name="Icon input"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field [iconRight]="true"
                           iconName="search"
                           label="Icon Input right"
                           name="Icon input right"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field iconName="search"
                           name="Icon input without label"
                           placeholder="Icon input without label"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field [invalid]="true"
                           errorMessage="This is a custom error message for this case"
                           label="Error input"
                           name="Error input"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
        <ax-ui-input-field [disabled]="true"
                           label="Disabled input"
                           name="Disabled input"
                           placeholder="Placeholder text"
                           variant="textarea">
        </ax-ui-input-field>
      </ax-component-library-item>

      <ax-component-library-item description="Select">
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [options]="selectOptions"
                      name="Default"
                      placeholder="Default">
        </ax-ui-select>
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [options]="selectOptions"
                      label="Input with label"
                      name="Default">
        </ax-ui-select>
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [options]="selectOptions"
                      [required]="true"
                      label="Required"
                      name="Required">
        </ax-ui-select>
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [options]="selectOptions"
                      [required]="true"
                      iconName="search"
                      label="With Icon"
                      name="Icon">
        </ax-ui-select>
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [invalid]="true"
                      [options]="selectOptions"
                      [required]="true"
                      hintMessage="This is a custom error message for this case"
                      label="Error Select"
                      name="Error Select">
        </ax-ui-select>
        <ax-ui-select (valueChanged)="onSelectValueChanged($event)"
                      [disabled]="true"
                      [options]="selectOptions"
                      label="Disabled Select"
                      name="Disabled">
        </ax-ui-select>
      </ax-component-library-item>

    </ax-component-library-container>

    <ax-component-library-container [verticalDirection]="true" title="Form Showcase">
      <form (ngSubmit)="onSubmit()" [formGroup]="showCaseform1">
        <ax-ui-radio-group [options]="genderOptions"
                           formControlName="gender"
                           legendText="Demo Radio Button choices">
        </ax-ui-radio-group>
        <ax-ui-input-field [required]="true"
                           formControlName="inputDemo1"
                           iconName="search"
                           label="Icon Input"
                           name="Icon input"
                           placeholder="Placeholder text"
                           type="text">
        </ax-ui-input-field>
        <ax-ui-checkbox [required]="true"
                        formControlName="acceptTerms"
                        id="acceptTerms"
                        label="Accept Terms and Conditions">
        </ax-ui-checkbox>
        <ax-ui-toggle-switch [checked]="true"
                             formControlName="toggleTest">
        </ax-ui-toggle-switch>
        <ax-ui-button (buttonClick)="onSubmit()" [disabled]="!showCaseform1.valid" text="Submit"></ax-ui-button>
      </form>
    </ax-component-library-container>

    <ax-component-library-container title="Labels">
      <ax-component-library-item description="Neutral">
        <ax-ui-label text="Neutral" variant="neutral"></ax-ui-label>
        <ax-ui-label [small]="true" text="Neutral Small" variant="neutral"></ax-ui-label>
        <ax-ui-label [removable]="true" text="Neutral" variant="neutral"></ax-ui-label>
      </ax-component-library-item>

      <ax-component-library-item description="Success">
        <ax-ui-label text="Success" variant="success"></ax-ui-label>
        <ax-ui-label [small]="true" text="Success Small" variant="success"></ax-ui-label>
        <ax-ui-label [removable]="true" text="Success" variant="success"></ax-ui-label>
      </ax-component-library-item>

      <ax-component-library-item description="Warning">
        <ax-ui-label text="Success" variant="warning"></ax-ui-label>
        <ax-ui-label [small]="true" text="Success Small" variant="warning"></ax-ui-label>
        <ax-ui-label [removable]="true" text="Success" variant="warning"></ax-ui-label>
      </ax-component-library-item>

      <ax-component-library-item description="Error">
        <ax-ui-label text="Success" variant="error"></ax-ui-label>
        <ax-ui-label [small]="true" text="Success Small" variant="error"></ax-ui-label>
        <ax-ui-label [removable]="true" text="Success" variant="error"></ax-ui-label>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Modals">
      <ax-component-library-item description="Card Modal Opener">
        <ax-ui-card-modal-opener (click)="demoModal.open(); isModalOpen = true"
                                 [attr.aria-expanded]="isModalOpen"
                                 [iconName]="'calendar_month'"
                                 aria-controls="demoModal"
                                 aria-haspopup="true"
                                 label="Termine">
        </ax-ui-card-modal-opener>
        <ax-ui-modal #demoModal modalId="demoModal" titleText="Modal Demo">
          <ng-container modal-body>
            <form (ngSubmit)="onSubmit()" [formGroup]="showCaseform2">
              <ax-ui-radio-group [options]="genderOptions"
                                 formControlName="gender"
                                 legendText="Demo Radio Button choices">
              </ax-ui-radio-group>
              <ax-ui-input-field [required]="true"
                                 formControlName="inputDemo2"
                                 iconName="search"
                                 label="Icon Input"
                                 name="Icon input"
                                 placeholder="Placeholder text"
                                 type="text">
              </ax-ui-input-field>
              <ax-ui-checkbox [required]="true"
                              formControlName="acceptTerms"
                              id="acceptTerms2"
                              label="Accept Terms and Conditions">
              </ax-ui-checkbox>
              <ax-ui-toggle-switch [checked]="true"
                                   formControlName="toggleTest">
              </ax-ui-toggle-switch>
              <div class="ax-ui-modal__footer">
                <ax-ui-button (buttonClick)="demoModal.close(); isModalOpen = false; onSubmit()"
                              [disabled]="!showCaseform2.valid"
                              text="Speichern"></ax-ui-button>
                <ax-ui-button (click)="demoModal.close(); isModalOpen = false" text="Schliessen"
                              variant="tertiary"></ax-ui-button>
              </div>
            </form>
          </ng-container>
        </ax-ui-modal>
      </ax-component-library-item>
      <ax-component-library-item description="With action required hint">
        <ax-ui-card-modal-opener (click)="demoModal2.open(); isModalOpen = true"
                                 [actionRequired]="true"
                                 [attr.aria-expanded]="isModalOpen"
                                 [iconName]="'calendar_month'"
                                 aria-controls="demoModal"
                                 aria-haspopup="true"
                                 label="Termine">
        </ax-ui-card-modal-opener>
        <ax-ui-modal #demoModal2 modalId="demoModal" titleText="Modal Demo">
          <ng-container modal-body>
            <p>Modal Demo Body Content - put anything in here</p>
          </ng-container>
          <ng-container modal-footer>
            <ax-ui-button (click)="demoModal2.close(); isModalOpen = false" text="Speichern"></ax-ui-button>
            <ax-ui-button (click)="demoModal2.close(); isModalOpen = false" text="Schliessen"
                          variant="tertiary"></ax-ui-button>
          </ng-container>
        </ax-ui-modal>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Avatar">
      <ax-component-library-item description="Square">
        <ax-ui-avatar [square]="true" />
        <ax-ui-avatar [size]="'small'" [square]=true />
      </ax-component-library-item>

      <ax-component-library-item description="Rounded">
        <ax-ui-avatar></ax-ui-avatar>
        <ax-ui-avatar [size]="'small'"></ax-ui-avatar>
      </ax-component-library-item>

      <ax-component-library-item description="Status Available">
        <ax-ui-avatar status="available"></ax-ui-avatar>
        <ax-ui-avatar [size]="'small'" status="available"></ax-ui-avatar>
      </ax-component-library-item>

      <ax-component-library-item description="Status Busy">
        <ax-ui-avatar status="busy"></ax-ui-avatar>
        <ax-ui-avatar [size]="'small'" status="busy"></ax-ui-avatar>
      </ax-component-library-item>

      <ax-component-library-item description="Status Absent">
        <ax-ui-avatar status="absent"></ax-ui-avatar>
        <ax-ui-avatar [size]="'small'" status="absent"></ax-ui-avatar>
      </ax-component-library-item>
    </ax-component-library-container>

    <ax-component-library-container title="Infoblock">
      <ax-component-library-item description="Default">
        <ax-ui-infoblock iconName="business_center"
                         label="Kunde"
                         subtitle="Magdenerstrasse 8, 4310 Rheinfelden (AG)"
                         title="Steck + Partner Architekten AG">
        </ax-ui-infoblock>
      </ax-component-library-item>
      <ax-component-library-item description="Clickable">
        <ax-ui-infoblock [clickable]="true"
                         iconName="business_center"
                         label="Kunde"
                         subtitle="Magdenerstrasse 8, 4310 Rheinfelden (AG)"
                         title="Steck + Partner Architekten AG">
        </ax-ui-infoblock>
      </ax-component-library-item>
      <ax-component-library-item description="Warning">
        <ax-ui-infoblock [clickable]="true"
                         [warning]="true"
                         iconName="business_center"
                         label="Kunde"
                         subtitle="Magdenerstrasse 8, 4310 Rheinfelden (AG)"
                         title="Steck + Partner Architekten AG">
        </ax-ui-infoblock>
      </ax-component-library-item>
      <ax-component-library-item description="With Avatar">
        <ax-ui-infoblock [clickable]="true"
                         [withAvatar]="true"
                         avatarImgSrc="assets/images/Sunny.webp"
                         avatarStatus="absent"
                         iconName="business_center"
                         label="Aktuelle Ansprechperson"
                         subtitle="Projektleiter"
                         title="Dario Kowalski">
        </ax-ui-infoblock>
      </ax-component-library-item>
    </ax-component-library-container>

    <h3>Tabs</h3>
    <div class="ax-card no-padding">
      <ax-ui-tabs [tabOptions]="tabs">
        <ng-template axUiTabContent tabReference="demo 1">
          <p>Complex Content for Tab 1, can be anything!</p>
        </ng-template>
        <ng-template axUiTabContent tabReference="demo 2">
          <p>Complex Content for Tab 2, also can be anything!</p>
        </ng-template>
        <ng-template axUiTabContent tabReference="demo 3">
          <p>Example wih custom component and logic inside</p>
          <ax-ui-card-modal-opener (click)="demoModal2.open(); isModalOpen = true"
                                   [actionRequired]="true"
                                   [attr.aria-expanded]="isModalOpen"
                                   [iconName]="'calendar_month'"
                                   aria-controls="demoModal"
                                   aria-haspopup="true"
                                   label="Termine">
          </ax-ui-card-modal-opener>
        </ng-template>
        <ng-template axUiTabContent tabReference="demo 4">
          <p>Complex Content for Tab 4, can be anything!</p>
        </ng-template>
        <ng-template axUiTabContent tabReference="demo 5">
          <p>Complex Content for Tab 5, can be anything!</p>
        </ng-template>
        <ng-template axUiTabContent tabReference="demo 6">
          <p>Complex Content for Tab 6, can be anything!</p>
        </ng-template>
      </ax-ui-tabs>
    </div>

    <h3>Tables</h3>
    <ax-component-library-table-demo></ax-component-library-table-demo>

    <ax-component-library-container [verticalDirection]=true style="margin-top: 48px; display: block" title="Filter">
      <ax-component-library-item description="Button mit Tags">
        <ax-ui-filter
          [filterDropdownOptions]="[{label: 'Filter 1', value: 'Filter1'}, {label: 'Filter 2', value: 'Filter2'}, {label: 'Filter 3', value: 'Filter3'}, {label: 'Filter 4', value: 'Filter4'}]"
          [filterConfigKeyName]="ConfigurationKeyNamesEnum.AxOfficeArtikelTable" />
      </ax-component-library-item>
    </ax-component-library-container>
  </div>
</div>
