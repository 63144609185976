import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { InputFieldComponent } from '../../../inputs/input-field/input-field.component';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { TerminassistentState } from '../../ngxs/terminassistent/terminassistent.state';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import {
  TerminassistentStateSetLocation,
  TerminassistentStateSetSearchedLocation
} from '../../ngxs/terminassistent/terminassistent.actions';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { AxLocation, AxLocationsService } from '@axova-frontend-monorepo/axova-rest-api';
import { LabelComponent } from '../../../label/label.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../icon/icon.component';
import { lastValueFrom } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'ax-ui-terminassistent-zip-to-business-unit',
  standalone: true,
  imports: [
    InputFieldComponent,
    IconComponent,
    TranslateModule,
    LabelComponent,
    AsyncPipe
  ],
  templateUrl: './terminassistent-zip-to-business-unit.component.html',
  styleUrl: './terminassistent-zip-to-business-unit.component.scss'
})
export class TerminassistentZipToBusinessUnitComponent implements OnInit {
  public location = '';
  public locationResults: AxLocation[] = [];
  public selectedLocationIndex: number | undefined;
  public showTooShortQueryHint = false;
  public showNoResultError = false;
  public showLocationUsageExplanation = true;
  public internalView$ = this.store.select(TerminassistentState.apiAppointmentSchedulerKey);


  constructor(
    private axLocationsService: AxLocationsService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private ccService: NgcCookieConsentService
  ) {
  }

  async ngOnInit() {
    const location = this.store.selectSnapshot(TerminassistentState.location);
    if (location) {
      this.showLocationUsageExplanation = false;
      this.location = location.name || '';
      await this.search(location.name || '');
      this.selectedLocationIndex = this.locationResults.findIndex(location => location.name === this.location);
    }
    this.checkForQueryParameters();
  }

  public async search(keyword: string): Promise<void> {
    this.showNoResultError = false;
    this.location = keyword;
    this.selectedLocationIndex = undefined;
    if (this.location && this.location.length >= 3) {
      this.showTooShortQueryHint = false;
      try {
        this.locationResults = await lastValueFrom(this.axLocationsService.locationsControllerSearchByKeyword({
          keyword: this.location,
          'x-api-key': environment.apiConfiguration.publicApiKey
        }));
        let searchedLocationsForFeedbackString = '';
        this.locationResults.forEach(location => {
          searchedLocationsForFeedbackString += `${location.zipcode} ${location.name}, `;
        });
        if (this.locationResults.length === 0) {
          this.showNoResultError = true;
        }
        this.store.dispatch(new TerminassistentStateSetSearchedLocation(searchedLocationsForFeedbackString));
      } catch (searchException) {
        this.showNoResultError = true;
        LoggerService.ERROR('Terminassisstent location search', searchException);
      }
      this.trackEvent('ta_location_search', { zipcode: this.location });
    } else {
      this.locationResults = [];
      this.showTooShortQueryHint = true;
    }
    this.showLocationUsageExplanation = false;
  }

  public async chooseLocation(location: AxLocation, index: number) {
    this.store.dispatch(new TerminassistentStateSetLocation(location));
    this.selectedLocationIndex = index;
    await this.router.navigateByUrl('terminassistent/typ');
  }

  private checkForQueryParameters() {
    this.activatedRoute.queryParams
      .subscribe(async (params: any) => {
          try {
            if (params.city) {
              this.location = params.city;
              await this.search(params.city);
              this.location = params.city;
            }
          } catch (noParamsException) {
            LoggerService.LOG(this, 'Keine Einstiegsparameter mitgegeben.');
          }
        }
      );
  }

  private trackEvent(eventName: string, eventOptions: any = {}) {
    try {
      if (isPlatformBrowser(this.platformId) && this.ccService && this.ccService.hasConsented()) {
        (window as any).gtag('event', eventName, eventOptions);
      }
    } catch (trackEventException) {
      LoggerService.ERROR('trackEventException', trackEventException);
    }
  }
}
