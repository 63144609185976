@if (variant === 'dateRange') {
  <mat-form-field class="ax-ui-input-datepicker"
                  appearance="outline"
                  floatLabel="always"
                  [class.disable-hint]="disableHint"
                  [style.max-width]="maxWidth + 'px'"
                  [class.with-label]="label">
    @if (label) {
      <mat-label>{{ label | translate }}</mat-label>
    }
    <mat-date-range-input [rangePicker]="rangePicker"
                          [dateFilter]="dateFilter"
                          [required]="required"
                          [formGroup]="rangeDateForm"
                          [disabled]="disabled">
      <input #startDateInput
             matStartDate
             (dateInput)="handleRangeDateChange($event.value, 'startDate')"
             (input)="onRangeInput($event, 'startDate')"
             [formControlName]="rangeStartDateFormControlName"
             [placeholder]="(rangeStartDatePlaceholder || 'STARTDATUM' | translate)">
      <input #endDateInput
             matEndDate
             (dateInput)="handleRangeDateChange($event.value, 'endDate')"
             (input)="onRangeInput($event, 'endDate')"
             [formControlName]="rangeEndDateFormControlName"
             [placeholder]="( rangeEndDatePlaceholder || 'ENDDATUM' | translate)">
    </mat-date-range-input>
    @if (!rangeDateForm.untouched && rangeDateForm.invalid) {
      <mat-error>
        @if (rangeDateForm.controls[rangeStartDateFormControlName].hasError('required') || rangeDateForm.controls[rangeEndDateFormControlName].hasError('required')) {
          <span>Dieses Feld darf nicht leer sein</span>
        }
        @if (rangeDateForm.controls[rangeStartDateFormControlName].hasError('invalidDate') || rangeDateForm.controls[rangeEndDateFormControlName].hasError('invalidDate')) {
          <span>Ungültiges Datum Format</span>
        }
      </mat-error>
    }
    <mat-datepicker-toggle matIconSuffix [for]="rangePicker" />
    <mat-date-range-picker #rangePicker />
  </mat-form-field>
} @else {
  <mat-form-field class="ax-ui-input-datepicker"
                  appearance="outline"
                  floatLabel="always"
                  [class.disable-hint]="disableHint"
                  [style.max-width]="maxWidth + 'px'"
                  [class.with-label]="label">
    @if (label) {
      <mat-label>{{ label | translate }}</mat-label>
    }
    <input #singleDateInput
           matInput
           [matDatepicker]="picker"
           [matDatepickerFilter]="dateFilter"
           [value]="singleDate"
           [required]="required"
           [formControl]="singleDateFormControl"
           [placeholder]="singleDatePlaceholder | translate"
           (input)="onInput($event)"
           (dateChange)="onDateSingleChange($event)">
    @if (!singleDateFormControl.untouched && singleDateFormControl.invalid) {
      <mat-error>
        @if (singleDateFormControl.hasError('required')) {
          <span>Dieses Feld darf nicht leer sein</span>
        }
        @if (singleDateFormControl.hasError('invalidDate')) {
          <span>Ungültiges Datum Format</span>
        }
      </mat-error>
    }
    <mat-datepicker-toggle matIconSuffix [for]="picker" />
    <mat-datepicker #picker />
  </mat-form-field>
}
