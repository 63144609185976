import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ax-ui-secret-reveal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './secret-reveal.component.html',
  styleUrl: './secret-reveal.component.scss',
})
export class SecretRevealComponent implements OnInit {
  @Input() value: string | number | null = '******';
  public obfuscatedValue = '';
  public isRevealed = false;
  public containerWidth = 'auto';

  ngOnInit(): void {
    this.setContainerWidth();
    this.obfuscatedValue = this.obfuscate(this.value);
  }

  toggleReveal(): void {
    this.isRevealed = !this.isRevealed;
  }

  private obfuscate(value: string | number | null): string {
    return `${value}`.replace(/./g, '•');
  }

  private setContainerWidth(): void {
    const virtualSpan = document.createElement('span');
    virtualSpan.style.visibility = 'hidden';
    virtualSpan.style.position = 'absolute';
    virtualSpan.style.whiteSpace = 'nowrap';
    virtualSpan.style.pointerEvents = 'none';
    virtualSpan.textContent = `${this.value}`;
    document.body.appendChild(virtualSpan);
    const width = virtualSpan.offsetWidth;
    this.containerWidth = `${width}px`;
    document.body.removeChild(virtualSpan);
  }
}
