export enum ConfigurationKeyNamesEnum {
  AxOfficeArtikelTable = 'AxOfficeArtikelTable',
  AxOfficeAuswertungenAusstehendeAufgabenTable = 'AxOfficeAuswertungenAusstehendeAufgabenTable',
  AxOfficeAuswertungenFoerdergelder = 'AxOfficeAuswertungenFoerdergelder',
  AxOfficeAuswertungenMitarbeiterLohnlisteTable = 'AxOfficeAuswertungenMitarbeiterLohnlisteTable',
  AxOfficeAuswertungenMitarbeiterSaldouebersichtTable = 'AxOfficeAuswertungenMitarbeiterSaldouebersichtTable',
  AxOfficeAuswertungenMitarbeiterSaldouebertragTable = 'AxOfficeAuswertungenMitarbeiterSaldouebertragTable',
  AxOfficeAuswertungenMitarbeiterStundenauszugTable = 'AxOfficeAuswertungenMitarbeiterStundenauszugTable',
  AxOfficeAuswertungenUmsatzDeckungsbeitrag = 'AxOfficeAuswertungenUmsatzDeckungsbeitrag',
  AxOfficeBetriebsmittelLogbuchTable = 'AxOfficeBetriebsmittelLogbuchTable',
  AxOfficeBetriebsmittelTable = 'AxOfficeBetriebsmittelTable',
  AxOfficeElektroDrittkontrolleTable = 'AxOfficeElektroDrittkontrolleTable',
  AxOfficeGeruestbauTable = 'AxOfficeGeruestbauTable',
  AxOfficeKontakteTable = 'AxOfficeKontakteTable',
  AxOfficeLagerorteTable = 'AxOfficeLagerorteTable',
  AxOfficeMitarbeiterStundenuebersichtTable = 'AxOfficeMitarbeiterStundenuebersichtTable',
  AxOfficeMitarbeiterTable = 'AxOfficeMitarbeiterTable',
  AxOfficeMontageplanTeams = 'AxOfficeMontageplanTeams',
  AxOfficeMontageplanTeamsExpandedState = 'AxOfficeMontageplanTeamsExpandedState',
  AxOfficeMontageplanUnplannedBlocksProjectsTable = 'AxOfficeMontageplanUnplannedBlocksProjectsTable',
  AxOfficeMontageplanUnplannedBlocksTasksTable = 'AxOfficeMontageplanUnplannedBlocksTasksTable',
  AxOfficeMontageplanWeekOverviewTeams = 'AxOfficeMontageplanWeekOverviewTeams',
  AxOfficeMontageplanZoom = 'MontageplanZoom',
  AxOfficeProjekteAufgabenTable = 'AxOfficeProjekteAufgabenTable',
  AxOfficeProjekteTable = 'AxOfficeProjekteTable',
  AxOfficeScreenGeraeteTable = 'AxOfficeScreenGeraeteTable',
  AxOfficeScreenFolienTable = 'AxOfficeScreenFolienTable',
  AxOfficeScreenKomponentenTable = 'AxOfficeScreenKomponentenTable',
  AxOfficeVeranstaltungenTable = 'AxOfficeVeranstaltungenTable',
}
