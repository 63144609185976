/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddOrRemoveDocumentToEventDto } from '../models/ax-add-or-remove-document-to-event-dto';
import { AxCreateEventDto } from '../models/ax-create-event-dto';
import { AxDocument } from '../models/ax-document';
import { AxEvent } from '../models/ax-event';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateEventDto } from '../models/ax-update-event-dto';

@Injectable({ providedIn: 'root' })
export class AxEventsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventsControllerFindAllPaginated()` */
  static readonly EventsControllerFindAllPaginatedPath = '/v2/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by endAt query param.
     *           <p>
     *              <b>Format: </b> filter.endAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.endAt=$not:$like:John Doe&filter.endAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$gt</li>
     * <li>$lt</li></ul>
     */
      'filter.endAt'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> startAt:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li>
     * <li>startAt</li>
     * <li>organizer.firstName</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC' | 'startAt:ASC' | 'startAt:DESC' | 'organizer.firstName:ASC' | 'organizer.firstName:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,organizer.firstName,organizer.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>organizer.firstName</li>
     * <li>organizer.lastName</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEvent>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'endAt'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.endAt', params['filter.endAt'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxEvent>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'endAt'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by endAt query param.
     *           <p>
     *              <b>Format: </b> filter.endAt={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.endAt=$not:$like:John Doe&filter.endAt=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$gt</li>
     * <li>$lt</li></ul>
     */
      'filter.endAt'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> startAt:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>title</li>
     * <li>startAt</li>
     * <li>organizer.firstName</li></ul>
     */
      sortBy?: Array<'title:ASC' | 'title:DESC' | 'startAt:ASC' | 'startAt:DESC' | 'organizer.firstName:ASC' | 'organizer.firstName:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> title,organizer.firstName,organizer.lastName
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>title</li>
     * <li>organizer.firstName</li>
     * <li>organizer.lastName</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxEvent>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'endAt'?: (string | Array<string>);
};
};
}> {
    return this.eventsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxEvent>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'endAt'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxEvent>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'endAt'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `eventsControllerCreate_1()` */
  static readonly EventsControllerCreate_1Path = '/v2/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerCreate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerCreate_1$Response(
    params: {
      body: AxCreateEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerCreate_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerCreate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerCreate_1(
    params: {
      body: AxCreateEventDto
    },
    context?: HttpContext
  ): Observable<AxEvent> {
    return this.eventsControllerCreate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEvent>): AxEvent => r.body)
    );
  }

  /** Path part for operation `eventsControllerFindOneById()` */
  static readonly EventsControllerFindOneByIdPath = '/v2/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxEvent> {
    return this.eventsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEvent>): AxEvent => r.body)
    );
  }

  /** Path part for operation `eventsControllerDelete()` */
  static readonly EventsControllerDeletePath = '/v2/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.eventsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `eventsControllerUpdate_1()` */
  static readonly EventsControllerUpdate_1Path = '/v2/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerUpdate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerUpdate_1$Response(
    params: {
      id: number;
      body: AxUpdateEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerUpdate_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerUpdate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerUpdate_1(
    params: {
      id: number;
      body: AxUpdateEventDto
    },
    context?: HttpContext
  ): Observable<AxEvent> {
    return this.eventsControllerUpdate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEvent>): AxEvent => r.body)
    );
  }

  /** Path part for operation `eventsControllerGetDocumentsByEventId()` */
  static readonly EventsControllerGetDocumentsByEventIdPath = '/v2/events/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerGetDocumentsByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerGetDocumentsByEventId$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDocument>>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerGetDocumentsByEventIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerGetDocumentsByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsControllerGetDocumentsByEventId(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<Array<AxDocument>> {
    return this.eventsControllerGetDocumentsByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>): Array<AxDocument> => r.body)
    );
  }

  /** Path part for operation `eventsControllerAddDocumentToEvent()` */
  static readonly EventsControllerAddDocumentToEventPath = '/v2/events/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerAddDocumentToEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerAddDocumentToEvent$Response(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxEvent>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerAddDocumentToEventPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerAddDocumentToEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerAddDocumentToEvent(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEventDto
    },
    context?: HttpContext
  ): Observable<AxEvent> {
    return this.eventsControllerAddDocumentToEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxEvent>): AxEvent => r.body)
    );
  }

  /** Path part for operation `eventsControllerRemoveDocumentFromEvent()` */
  static readonly EventsControllerRemoveDocumentFromEventPath = '/v2/events/{id}/documents/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsControllerRemoveDocumentFromEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerRemoveDocumentFromEvent$Response(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxEventsV2Service.EventsControllerRemoveDocumentFromEventPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsControllerRemoveDocumentFromEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventsControllerRemoveDocumentFromEvent(
    params: {
      id: number;
      body: AxAddOrRemoveDocumentToEventDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.eventsControllerRemoveDocumentFromEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
