<div class="ax-card no-padding table">
  <div class="ax-table">
    <div class="ax-table-wrapper">
      <table>
        <!-- Caption provides a brief overview of the table content -->
        <caption class="hide">Monthly Sales Data</caption>

        <thead>
        <tr>
          <ax-ui-table-headers (sort)="sort($event)" [tableHeaderItems]="tableHeaderItems"></ax-ui-table-headers>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <ax-ui-input-field [editable]="false"
                               [max]="200"
                               [required]="false"
                               [tableInput]="true"
                               value="AxSun AX M-60 3.2, 305 Wp, Solaredge Optimizer, AxSun AX M-60 3.2, 305 Wp, Solaredge Optimizer">
            </ax-ui-input-field>
          </td>
          <td>
            <ax-ui-input-field (valueChange)="simulateRequestLoading()"
                               [(loadingState)]="loadingState"
                               [editable]="true"
                               [required]="true"
                               [showLoadingOnValueChange]="true"
                               [tableInput]="true"
                               [type]="'number'"
                               value="53">
            </ax-ui-input-field>
          </td>
          <td>
            <ax-ui-input-field (valueChange)="console.log($event)"
                               [editable]="false"
                               [required]="true"
                               [tableInput]="true"
                               value="250.80">
            </ax-ui-input-field>
          </td>
          <td>
            <ax-ui-input-field (valueChange)="console.log($event)"
                               [editable]="false"
                               [required]="true"
                               [tableInput]="true"
                               value="13’292.40">
            </ax-ui-input-field>
          </td>
          <td>
            <ax-ui-input-field (valueChange)="simulateRequestLoading()"
                               [(loadingState)]="loadingState"
                               [editable]="true"
                               [max]="200"
                               [required]="false"
                               [showLoadingOnValueChange]="true"
                               [tableInput]="true"
                               placeholder="Bemerkungen"
                               value="">
            </ax-ui-input-field>
          </td>
          <td>
            <ax-ui-select (valueChanged)="console.log($event)"
                          [editable]="true"
                          [options]="[
                    { label: 'Test 1', value: 1 },
                    { label: 'Test 2', value: 2 }
                    ]"
                          [tableInput]="true"
                          placeholder="Lagerort">>
            </ax-ui-select>
          </td>
          <td>
            <ax-ui-checkbox (valueChanged)="console.log($event)"
                            [checked]="true">
            </ax-ui-checkbox>
          </td>
          <td>
            <ax-ui-checkbox (valueChanged)="console.log($event)"
                            [checked]="false">
            </ax-ui-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ax-ui-table-footer (valueChanged)="console.log('New Pagination Options: ' + $event)"
                        [paginationOptions]="paginationOptions" />
  </div>
</div>
