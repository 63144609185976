<div [class.close]="closeMenu" class="ax-ui-inline-nav-list">
  <div class="ax-ui-inline-nav-list__heading flex flex-row vertical-center gap-12">
    @if (showToggleButton) {
      <ax-ui-button (buttonClick)="toggleMenu()"
                    [iconName]="!closeMenu ? 'menu_open' : 'chevron_right'"
                    [iconOnly]="true"
                    [whiteBackground]="true"
                    [small]="true"
                    class="ax-ui-inline-nav-list__toggle"
                    variant="secondary" />
    }
    <ax-ui-input-field (valueChange)="search($event)"
                       [debounceTimeInMs]="0"
                       [disableError]="true"
                       [showClearButton]="true"
                       [class.hide]="closeMenu"
                       [compactStyle]="true"
                       axAutofocus
                       iconName="search"
                       placeholder="SEITE_SUCHEN" />
  </div>
  <nav>
    <ul class="ax-list standalone" role="list">
      @for (navigationItemGroup of navigationItemsFiltered; track $index; let isFirst = $first; let isLast = $last) {
        @if (!isFirst) {
          <hr class="margin-vertical-12">
        }
        <li class="ax-list__sublist">
          @if (navigationItemGroup.title) {
            <p class="text-overflow-ellipsis"><b>{{ navigationItemGroup.title | translate }}</b></p>
          }
          <ul class="ax-list standalone" role="list">
            @for (navigationItem of navigationItemGroup.items; track navigationItem) {
              <li class="ax-action-element width-100"
                  role="listitem"
                  routerLinkActive="active">
                <a [routerLink]="getRouterLink(navigationItem)">
                  @if (navigationItem.icon) {
                    <ax-ui-icon [name]="navigationItem.icon" />
                  }
                  <span class="text-overflow-ellipsis"
                        [class.margin-top-2]="navigationItem.icon">
                      {{ navigationItem.label | translate }}
                    </span>
                </a>
              </li>
            }
          </ul>
        </li>
      }
    </ul>
  </nav>
</div>
<div class="ax-ui-inline-nav-list--mobile">
  <ax-ui-button (buttonClick)="dropdown.toggleDropDown()"
                [active]="dropdown.dropDownOpen"
                [iconPositionRight]="true"
                [text]="selectedRouteName"
                [fullWidth]="true"
                iconName="expand_more"
                variant="secondary" />
  <ax-ui-dropdown-list #dropdown
                       (optionSelected)="selectMobileDropDownRoute($event)"
                       [activeStringValue]="activeNavigationItem?.route || ''"
                       [options]="mobileDropdownOptions" />
</div>
