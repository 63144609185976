import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'internationaliseChPhoneNr',
  standalone: true
})
export class InternationaliseChPhoneNrPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/^0/, '+41 ');
  }
}
