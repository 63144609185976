import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ConfirmActionModalButton, ConfirmActionModalOptions } from '../../models/confirm-action-modal-options.model';
import { ConfirmActionModalService } from '../../services/confirm-action-modal/confirm-action-modal.service';

@Directive({
  selector: '[axUiShowConfirmActionModal]',
  standalone: true,
})
export class ConfirmActionModalDirective {
  @Input() modalOptions?: ConfirmActionModalOptions;
  @Output() actionConfirmResult: EventEmitter<ConfirmActionModalButton | undefined> = new EventEmitter();

  constructor(
    private readonly confirmActionModalService: ConfirmActionModalService,
    private readonly elementRef: ElementRef,
  ) {
  }

  @HostListener('click')
  @HostListener('buttonClick')
  open() {
    if (this.isElementDisabled()) {
      return;
    }
    this.confirmActionModalService.showModal(this.modalOptions).subscribe({
      next: (button: ConfirmActionModalButton) => {
        if (!button || (button && button.role && button.role === 'cancel')) {
          return;
        }
        this.actionConfirmResult.emit(button);
      },
    });
  }

  private isElementDisabled(): boolean {
    let isDisabled = false;
    try {
      isDisabled = this.elementRef.nativeElement.attributes.getNamedItem('ng-reflect-disabled').nodeValue === 'true';
    } catch (noNgReflectAttribute) {
    }
    try {
      isDisabled = this.elementRef.nativeElement.attributes.getNamedItem('disabled').nodeValue === 'true';
    } catch (noDisabledAttribute) {
    }
    try {
      const button = this.elementRef.nativeElement.querySelector('button');
      if (button) {
        isDisabled = button.disabled;
      }
    } catch (noNgReflectAttribute) {
    }
    return isDisabled;
  }
}
