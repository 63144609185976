import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { SelectDropdownOptions } from '../../../models/select-dropdown-options.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IconComponent } from '../../icon/icon.component';
import { ButtonComponent } from '../../button/button.component';
import { ConfirmActionModalDirective } from '../../../directives/confirm-action-modal/confirm-action-modal.directive';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'ax-ui-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  imports: [TranslateModule, IconComponent, ButtonComponent, ConfirmActionModalDirective, NgTemplateOutlet],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('root') root!: ElementRef;
  @ViewChild('customSelect') customSelect!: ElementRef;
  @ViewChild('nativeSelect') nativeSelect!: ElementRef;

  @Input() required = false;
  @Input() label = '';
  @Input() labelSize: 'small' | 'default' = 'small';
  @Input() value: any;
  @Input() name = '';
  @Input() placeholder = 'Auswählen';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() readonlyNoBorders = false;
  @Input() invalid = false;
  @Input() hintMessage = 'Hinweis';
  @Input() hideHint = false;
  @Input() disableHint = false;
  @Input() iconName = '';
  @Input({ required: true }) options: SelectDropdownOptions[] = [];
  @Input() tableInput = false;
  @Input() editable = false;
  @Input() valueClearable = false;
  @Input() maxWidth: number | string | undefined;
  @Input() width: number | string | undefined;
  @Input() formControlName = '';

  @Output() valueChanged = new EventEmitter<any>();
  @Output() valueCleared = new EventEmitter<any>();

  public selectedLabel = '';
  private formControl: FormControl | undefined;

  constructor(private readonly translateService: TranslateService, @Optional() private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.setOptionsFromFormControl();
    this.setSelectedLabel();
  }

  ngAfterViewInit(): void {
    this.customSelect.nativeElement.addEventListener('focus', () => {
      this.focusOnSelect();
    });
    this.nativeSelect.nativeElement.addEventListener('blur', () => {
      this.customSelect.nativeElement.classList.remove('focused');
      this.onTouched();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && changes['options'].currentValue) {
      if (changes['options'].currentValue.length > 0) {
        this.setSelectedLabel();
      }
    }
    if (changes['value']) {
      this.setSelectedLabel();
    }
    if (changes['placeholder']) {
      this.placeholder = this.translateService.instant(this.placeholder);
    }
  }

  /**
   * Emit the value change to the parent
   */
  public emitValueChange(event: Event) {
    const newValue = (event.target as HTMLSelectElement).value;
    this.value = newValue === this.placeholder ? null : newValue;
    this.setSelectedLabel();
    if (this.value !== null) {
      this.value = isNaN(this.value) || this.value.trim() === '' ? this.value : Number(this.value);
    }
    this.onTouched();
    this.onChange(this.value);
    this.formControl?.markAsDirty();
    this.valueChanged.emit(this.value);
  }

  /**
   * Emit the value change to the parent
   */
  public emitValueCleared() {
    this.selectedLabel = this.placeholder;
    this.valueCleared.emit();
  }

  /**
   *  Set focus to the actual select element
   */
  public focusOnSelect() {
    if (!this.disabled && !this.readonly) {
      // focus native select
      this.nativeSelect.nativeElement.focus();
      // reset possible invalid state
      this.root.nativeElement.classList.remove('error');
      this.invalid = false;
      // add class to custom select for focus style
      this.customSelect.nativeElement.classList.add('focused');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setSelectedLabel() {
    if (!this.value) {
      this.selectedLabel = this.placeholder;
    } else if (this.options) {
      const found = this.options.find((option) => option.value == this.value);
      this.selectedLabel = found ? found.label : this.placeholder;
    }
  }

  setOptionsFromFormControl() {
    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
      if (this.formControl) {
        if (this.formControl.value) {
          this.value = this.formControl.value;
        }
        if (this.formControl.validator) {
          const validator = this.formControl.validator({} as AbstractControl);
          this.required = validator && validator['required'];
        }
        this.formControl.registerOnChange(() => {
          this.value = this.formControl?.value;
          this.setSelectedLabel();
        });
      }
    }
  }

  validate(control: FormControl) {
    if (this.required && control.dirty && !control.value) {
      this.invalid = true;
      return { required: true };
    } else {
      this.invalid = false;
    }
    return null;
  }
}
