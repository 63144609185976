import { Component, effect, input } from '@angular/core';
import { AxDocument } from '@axova-frontend-monorepo/axova-rest-api';
import { FormatDatePipe, PrependServerToFileSrcPipe } from '@axova-frontend-monorepo/axova-commons';
import * as dayjs from 'dayjs';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'ax-ui-gallery',
  imports: [
    PrependServerToFileSrcPipe,
    FormatDatePipe,
    TranslateModule,
    IconComponent,
  ],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
  standalone: true,
})
export class GalleryComponent {
  public galleryDocuments = input.required<AxDocument[]>();
  public documentsByDate: { date: string, documents: AxDocument[] }[] = [];

  constructor() {
    effect(() => {
      this.orderDocumentsByDate();
    });
  }

  private orderDocumentsByDate() {
    const documents = this.galleryDocuments();

    if (!documents || documents.length === 0) {
      this.documentsByDate = [];
      return;
    }

    const groupedByDate: Record<string, AxDocument[]> = documents.reduce((acc, document) => {
      if (!document.upload) {
        console.warn('Document with missing date found:', document);
        return acc;
      }

      const date = dayjs(document.upload).format('YYYY-MM-DD');
      acc[date] = acc[date] || [];
      acc[date].push(document);
      return acc;
    }, {} as Record<string, AxDocument[]>);

    this.documentsByDate = Object.entries(groupedByDate)
      .map(([date, documents]) => ({ date, documents }))
      .sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());
  }
}
