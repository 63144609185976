import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAge',
  standalone: true,
})
export class GetAgePipe implements PipeTransform {
  transform(value: Date | string | null | undefined): number | null {
    if (!value) {
      return null;
    }

    const birthDate = value instanceof Date ? value : new Date(value);
    if (isNaN(birthDate.getTime())) {
      // Invalid date
      return null;
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has not occurred yet this year
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();

    // If current month/day is before the birth month/day, subtract one year
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    return age;
  }
}
