/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddPermissionDto } from '../models/ax-add-permission-dto';
import { AxAddUserDto } from '../models/ax-add-user-dto';
import { AxCreateRoletypeDto } from '../models/ax-create-roletype-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxRemovePermissionDto } from '../models/ax-remove-permission-dto';
import { AxRemoveUserDto } from '../models/ax-remove-user-dto';
import { AxRoletype } from '../models/ax-roletype';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdateRoletypeDto } from '../models/ax-update-roletype-dto';

@Injectable({ providedIn: 'root' })
export class AxRoletypesV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `roletypesControllerGetAllDropdownOptions()` */
  static readonly RoletypesControllerGetAllDropdownOptionsPath = '/v2/roletypes/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.roletypesControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `roletypesControllerFindAllPaginated()` */
  static readonly RoletypesControllerFindAllPaginatedPath = '/v2/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxRoletype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxRoletype>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxRoletype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}> {
    return this.roletypesControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxRoletype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxRoletype>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
} => r.body)
    );
  }

  /** Path part for operation `roletypesControllerCreate()` */
  static readonly RoletypesControllerCreatePath = '/v2/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerCreate$Response(
    params: {
      body: AxCreateRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerCreate(
    params: {
      body: AxCreateRoletypeDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.roletypesControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `roletypesControllerFindOneById()` */
  static readonly RoletypesControllerFindOneByIdPath = '/v2/roletypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxRoletype>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxRoletype>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxRoletype> {
    return this.roletypesControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxRoletype>): AxRoletype => r.body)
    );
  }

  /** Path part for operation `roletypesControllerDelete()` */
  static readonly RoletypesControllerDeletePath = '/v2/roletypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roletypesControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.roletypesControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `roletypesControllerUpdate()` */
  static readonly RoletypesControllerUpdatePath = '/v2/roletypes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerUpdate(
    params: {
      id: number;
      body: AxUpdateRoletypeDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.roletypesControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `roletypesControllerAddUserToRoletype()` */
  static readonly RoletypesControllerAddUserToRoletypePath = '/v2/roletypes/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerAddUserToRoletype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerAddUserToRoletype$Response(
    params: {
      id: number;
      body: AxAddUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerAddUserToRoletypePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerAddUserToRoletype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerAddUserToRoletype(
    params: {
      id: number;
      body: AxAddUserDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.roletypesControllerAddUserToRoletype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `roletypesControllerRemoveUserFromRoletype()` */
  static readonly RoletypesControllerRemoveUserFromRoletypePath = '/v2/roletypes/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerRemoveUserFromRoletype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerRemoveUserFromRoletype$Response(
    params: {
      id: number;
      body: AxRemoveUserDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerRemoveUserFromRoletypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerRemoveUserFromRoletype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerRemoveUserFromRoletype(
    params: {
      id: number;
      body: AxRemoveUserDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.roletypesControllerRemoveUserFromRoletype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `roletypesControllerAddPermissionToRoletype()` */
  static readonly RoletypesControllerAddPermissionToRoletypePath = '/v2/roletypes/{id}/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerAddPermissionToRoletype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerAddPermissionToRoletype$Response(
    params: {
      id: number;
      body: AxAddPermissionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerAddPermissionToRoletypePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerAddPermissionToRoletype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerAddPermissionToRoletype(
    params: {
      id: number;
      body: AxAddPermissionDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.roletypesControllerAddPermissionToRoletype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `roletypesControllerRemovePermissionFromRoletype()` */
  static readonly RoletypesControllerRemovePermissionFromRoletypePath = '/v2/roletypes/{id}/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roletypesControllerRemovePermissionFromRoletype()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerRemovePermissionFromRoletype$Response(
    params: {
      id: number;
      body: AxRemovePermissionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxRoletypesV2Service.RoletypesControllerRemovePermissionFromRoletypePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `roletypesControllerRemovePermissionFromRoletype$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roletypesControllerRemovePermissionFromRoletype(
    params: {
      id: number;
      body: AxRemovePermissionDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.roletypesControllerRemovePermissionFromRoletype$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
