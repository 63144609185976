import { Component, Input } from '@angular/core';

export type BadgeVariant = 'neutral' | 'success' | 'warning' | 'error' | 'white';
export type BadgeSize = 'small' | 'default';

@Component({
    selector: 'ax-ui-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: true,
})
export class BadgeComponent {
  @Input() label: string | number | null | undefined = '';
  @Input() variant: BadgeVariant = 'neutral';
  @Input() size: BadgeSize = 'default';
}
