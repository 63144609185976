<div [class.ax-ui-tabs--no-padding]="noPadding" class="ax-ui-tabs">
  <ul #tabGroup [class.hide]="showTabs" aria-label="Sample Tabs" role="tablist">
    @for (tab of tabOptions; track tab; let i = $index) {
      @if (!tab.hidden) {
        <li role="presentation">
          <button #tabButton
                  (click)="activateTab(tab.tabReference)"
                  (keydown)="onKeydown($event)"
                  [attr.aria-controls]="'tabpanel-' + tab.tabReference"
                  [attr.aria-selected]="tab.tabReference === activeTab"
                  [attr.tabindex]="tab.tabReference === activeTab ? 0 : -1"
                  [id]="'tab-' + tab.tabReference"
                  role="tab">
            @if (tab.iconName) {
              <ax-ui-icon [fontSize]="18" [name]="tab.iconName" />
            }
            {{ tab.title | translate }}
            @if (tab.badgeValue) {
              <span class="ax-badge">{{ tab.badgeValue }}</span>
            }
          </button>
        </li>
      }
    }
  </ul>
  @if (showTabs) {
    <ax-ui-select (valueChanged)="activateTab($event)"
                  [class.inline-padding]="noPadding"
                  [hideHint]="true"
                  [name]="'tabOptionsMobile'"
                  [options]="selectOptions"
                  [value]="activeTab"
                  class="display-block">
    </ax-ui-select>
  }

  @if (tabContentTemplates) {
    @for (contentDirective of tabContentTemplates.toArray(); track contentDirective; let i = $index) {
      <div [attr.aria-labelledby]="'tab' + tabOptions[i].tabReference"
           [class.active]="tabOptions[i].tabReference === activeTab"
           [class.no-padding]="noPadding"
           [class.content-padding-bottom]="contentPadding"
           [id]="'tabpanel-' + tabOptions[i].tabReference"
           class="ax-ui-tabs__panel"
           role="tabpanel">
        <ng-template [ngTemplateOutlet]="contentDirective.template"></ng-template>
      </div>
    }
  }
</div>
