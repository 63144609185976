<div [class.disable-hint]="disableHint"
     [class.editable]="editable"
     [class.error]="invalid"
     [class.icon-right]="iconRight"
     [class.icon]="iconName"
     [class.max-width-smaller]="maxWidthSmaller"
     [class.table-input]="tableInput"
     [class.with-label]="label && iconName"
     [style.max-width]="maxWidth"
     class="ax-ui-input-field ax-input">
  <div class="ax-ui-input-field__header">
    @if (label) {
      <label [for]="id">
        <small><b>{{ label | translate }} @if (required) {
          <b class="ax-ui-required-asterisk"> *</b>
        }</b></small>
      </label>
    }
  </div>
  <div class="input-wrapper">
    <input #inputFieldSearch
           (blur)="onTouched()"
           (focus)="dropdownOptions && dropdownOptions.length > 0 ? toggleDropDown() : null"
           (input)="updateValue($event)"
           (keyup)="dropdownOptions ? handleDropDownNavigationOnKeyboardEvent($event) : null"
           [(ngModel)]="valueLabel"
           [axUiAutoInitialWidthInputFieldIsTableEl]="tableInput"
           [disabled]="disabled"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           [style.max-width]="maxWidth"
           axUiAutoInitialWidthInputField
           type="text" />
    <input #inputField
           [disabled]="true"
           [id]="id"
           [maxLength]="maxLength"
           [max]="max"
           [min]="min"
           [name]="name"
           [placeholder]="placeholder | translate"
           [required]="required"
           [style.max-width]="maxWidth"
           [value]="value"
           type="hidden" />
    <ax-ui-loading-spinner [(loadingState)]="loadingState"
                           [iconSize]="16" />

    <!-- dropdown behaviour-->
    @if (dropdownOptions) {
      @if (dropdownShowArrow) {
        <ax-ui-icon (click)="inputField.focus()"
                    class="dropdown-toggle"
                    name="expand_more" />
      }
      <ax-ui-dropdown-list #dropdown
                           (optionSelected)="emitOptionChange($event)"
                           [(activeStringValue)]="valueLabel"
                           [dropdownWidthFitContent]="dropdownWidthFitContent"
                           [dynamicDropdownOptions]="dynamicDropdownOptions"
                           [initialOption]="{value: value, label: valueLabel}"
                           [options]="dropdownOptions" />
    }
    @if (iconName) {
      <ax-ui-icon [name]="iconName" />
    }

    @if (showClearButton && value) {
      <div (click)="clear()"
           [class.fade-in]="loadingState === 'none'"
           [class.fade-out]="loadingState !== 'none'"
           class="ax-ui-input-field__clear">
        <ax-ui-icon name="close" />
      </div>
    }
  </div>

  @if (!disableHint) {
    <span
      [class.hide]="hideHint"
      [style.opacity]="invalid ? '1' : '0'" class="ax-input__hint">
      <small><b>{{ hintMessage || '-' | translate }}</b></small>
    </span>
  }
</div>
