<div [class.disable-hint]="disableError"
     [class.editable]="editable"
     [class.error]="invalid"
     [class.icon-right]="iconRight"
     [class.icon]="iconName"
     [class.max-width-smaller]="maxWidthSmaller"
     [class.table-input]="tableInput"
     [class.with-label]="label && iconName"
     [class.compact]="compactStyle"
     [class.readonly]="readonly"
     [class.unit-text]="textFieldUnitName"
     [style.max-width]="maxWidth + 'px'"
     class="ax-ui-input-field ax-input">
  <div class="ax-ui-input-field__header">
    @if (label) {
      <label [for]="id">
        <ng-template #labelContent>
          <b>{{ label | translate }}
            @if (required) {
              <b class="ax-ui-required-asterisk"> *</b>
            }</b>
        </ng-template>

        @switch (labelSize) {
          @case ('small') {
            <small>
              <ng-container *ngTemplateOutlet="labelContent" />
            </small>
          }
          @case ('default') {
            <p>
              <ng-container *ngTemplateOutlet="labelContent" />
            </p>
          }
        }
      </label>
    }
    @if (maxLength && maxLength !== 10000) {
      <div class="ax-ui-input-field__maxlength-hint">
        <small class="color-light">{{ currentCharLength }}/{{ maxLength }}</small>
      </div>
    }
  </div>
  <div class="input-wrapper">
    @if (variant === 'textField') {
      @if (textFieldUnitName) {
        <span #unitText class="input-wrapper__unit-wrapper">
          <p class="input-wrapper__unit" [innerHTML]="textFieldUnitName"></p>
        </span>
      }
      <input #inputField
             (blur)="onBlur()"
             (input)="updateValue()"
             [axUiAutoInitialWidthInputFieldIsTableEl]="tableInput"
             [disabled]="disabled"
             [readOnly]="readonly"
             [class.readonly--no-borders]="readonlyNoBorders"
             [id]="id"
             [maxLength]="maxLength"
             [max]="max"
             [min]="min"
             [name]="name"
             [placeholder]="placeholder | translate"
             [required]="required"
             [style.max-width]="maxWidth + 'px'"
             [type]="type"
             [value]="value"
             [attr.autocomplete]="autoComplete ? 'on' : 'off'"
             axUiAutoInitialWidthInputField />
      @if (secretRevealToggle) {
        <ax-ui-icon class="input-wrapper__password-toggle" (click)="togglePasswordVisibility()" name="visibility_off" />
      }
    }
    @if (variant === 'textarea') {
      <textarea #inputArea
                (blur)="onBlur()"
                (input)="updateValue()"
                [attr.rows]="tableInput ? null : rows"
                [axUiAutoHeightTextarea]="setAutoHeight"
                [class.full-height]="!setAutoHeight"
                [disabled]="disabled"
                [readOnly]="readonly"
                [id]="id"
                [maxLength]="maxLength"
                [name]="name"
                [placeholder]="placeholder | translate"
                [required]="required"
                [value]="value"
                [attr.autocomplete]="autoComplete ? 'on' : 'off'">
      </textarea>
    }
    <ax-ui-loading-spinner [(loadingState)]="loadingState"
                           [iconSize]="16" />
    @if (iconName) {
      <ax-ui-icon [name]="iconName" />
    }
    @if (showClearButton && value) {
      <div (click)="clear()"
           [class.fade-in]="loadingState === 'none'"
           [class.fade-out]="loadingState !== 'none'"
           class="ax-ui-input-field__clear">
        <ax-ui-icon name="close" />
      </div>
    }
  </div>

  @if (!!hintMessage && !invalid) {
    <span class="ax-input__hint">
      <small class="color-light">{{ hintMessage | translate }}</small>
    </span>
  }

  @if (!disableError) {
    <span [style.opacity]="invalid ? '1' : '0'" class="ax-input__hint">
      <small><b>{{ errorMessage || '-' | translate }}</b></small>
    </span>
  }
</div>
