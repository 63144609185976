import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { LoadingAndFeedbackState } from '../../../models/loading-and-feedback-state.type';
import * as dayjs from 'dayjs';
import { TranslateModule } from '@ngx-translate/core';

import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { IconComponent } from '../../icon/icon.component';
import { AutoHeightTextareaDirective } from '../../../directives/auto-height-textarea/auto-height-textarea.directive';
import {
  AutoInitialWidthInputFieldDirective
} from '../../../directives/auto-initial-width-input-field/auto-initial-width-input-field.directive';


export type InputFieldVariant = 'textField' | 'textarea'

@Component({
  selector: 'ax-ui-input-field',
  standalone: true,
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true
    }
  ],
  imports: [
    TranslateModule,
    LoadingSpinnerComponent,
    IconComponent,
    AutoHeightTextareaDirective,
    AutoInitialWidthInputFieldDirective
  ]
})
export class InputFieldComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, Validator, OnDestroy {
  @ViewChild('inputField') inputField: ElementRef | undefined;
  @ViewChild('inputArea') inputArea: ElementRef | undefined;

  // General config
  @Input() variant: InputFieldVariant = 'textField';
  @Input() id = '';
  @Input() name = '';
  @Input() label = '';
  @Input() type = '';
  @Input() rows = 6;
  @Input() placeholder = '';
  @Input() value: any = '';
  @Input() disabled = false;
  @Input() iconName = '';
  @Input() iconRight = false;
  @Input() tableInput = false;
  @Input() editable = false;
  @Input() megaSearch = false;
  @Input() maxWidthSmaller = false;
  @Input() maxWidth: number | string | undefined;
  @Input() fitContentWidth = false;
  @Input() setAutoHeight = true;
  @Input() autoComplete = false;
  @Input() compactStyle = false;
  // Validation
  @Input() required = false;
  @Input() invalid = false;
  @Input() hintMessage = '';
  @Input() hideHint = false;
  @Input() disableHint = false;
  @Input() min: number | undefined;
  @Input() max: number | undefined;
  @Input() debounceTimeInMs = 500;
  @Input() formControlName = '';
  @Input() validateSwissPhoneNumbers = false;
  // Loading state
  @Input() showLoadingOnValueChange = false;
  @Input() loadingState: LoadingAndFeedbackState = 'none';
  @Input() showClearButton = false;

  @Output() valueChange = new EventEmitter<any>();
  @Output() clickedInsideInput = new EventEmitter<any>();
  @Output() loadingStateChange: EventEmitter<LoadingAndFeedbackState> = new EventEmitter<LoadingAndFeedbackState>();

  public currentCharLength = 0;
  public maxLength: number | undefined;
  private inputElement!: HTMLInputElement;
  private valueChangedSubject = new Subject<any>();
  private emailOrTelValueChangedSubject = new Subject<any>();
  private controlStatusChangeSubscription: Subscription = new Subscription();
  private inputHintMessage = '';
  private formControl: FormControl | undefined;
  private isDateInput = false;

  constructor(
    private elementRef: ElementRef,
    @Optional() private controlContainer: ControlContainer
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.clickedInsideInput.emit();
    }
  }

  ngOnInit() {
    if (!this.id && this.name) {
      this.id = this.name;
    }
    if (this.variant === 'textarea' && !this.maxLength) {
      this.maxLength = this.max || 10000;
    } else {
      // prevent maxLength = 0 assumption if variable is undefined
      this.maxLength = 10000;
    }
    if (this.tableInput && this.maxLength && this.maxLength > 30) {
      this.variant = 'textarea';
    }

    if (this.placeholder && this.type === 'date' && !this.value) {
      this.isDateInput = true;
      this.type = 'text';
    }

    if (this.fitContentWidth) {
      this.elementRef.nativeElement.style.width = 'auto';
    }

    this.setOptionsFromFormControl();

    // save initial hintMessage from parent for validation usage
    this.inputHintMessage = this.hintMessage;

    if (this.type === 'email' || this.type === 'tel') {
      this.emailOrTelValueChangedSubject
        .pipe(
          debounceTime(3 * this.debounceTimeInMs),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.validateNow();
        });
    } else {
      this.valueChangedSubject
        .pipe(
          debounceTime(this.debounceTimeInMs),
          distinctUntilChanged()
        )
        .subscribe(value => {
          if (!this.required || value !== '') {
            if (this.showLoadingOnValueChange) {
              this.loadingState = 'loading';
              this.loadingStateChange.emit('loading');
            }
            this.valueChange.emit(value);
          }
        });
    }
  }

  ngAfterViewInit() {
    this.inputElement = this.inputField?.nativeElement || this.inputArea?.nativeElement;

    if (this.placeholder && this.isDateInput && this.inputField) {
      setTimeout(() => {
        if (this.value) {
          this.value = dayjs(this.value).format('YYYY-MM-DD');
        }
      });
      this.inputField.nativeElement.addEventListener('focus', () => {
        if (!this.value) {
          this.type = 'date';
        }
      });
      this.inputField.nativeElement.addEventListener('blur', () => {
        if (!this.value) {
          this.type = 'text';
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadingState'] && changes['loadingState'].currentValue) {
      switch (changes['loadingState'].currentValue as LoadingAndFeedbackState) {
        case 'loading': {
          this.loadingStateChange.emit('loading');
          break;
        }
        case 'success': {
          this.loadingStateChange.emit('success');
          break;
        }
        case 'error': {
          this.loadingStateChange.emit('error');
          break;
        }
        case 'none': {
          this.loadingStateChange.emit('none');
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    try {
      this.valueChangedSubject.unsubscribe();
      this.emailOrTelValueChangedSubject.unsubscribe();
      this.loadingStateChange.unsubscribe();
      this.controlStatusChangeSubscription.unsubscribe();
    } catch {
      // nothing to catch
    }
  }


  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  updateValue() {
    let inputValue: any = this.inputElement.value;
    this.currentCharLength = inputValue.length;

    // prevent writing more characters if maxLength is set
    if (this.maxLength !== undefined && inputValue.length > this.maxLength) {
      inputValue = inputValue.substring(0, this.maxLength);
      this.inputElement.value = inputValue;
      // Adjust currentLength if maxLength is exceeded
      this.currentCharLength = this.maxLength;
    }

    if (this.type === 'number') {
      inputValue = parseFloat(inputValue);
    }
    this.value = inputValue;
    this.onChange(this.value);

    if (this.type === 'email' || this.type === 'tel') {
      this.emailOrTelValueChangedSubject.next(this.value);
    } else {
      this.onTouched();
      this.validateNow();
      this.valueChangedSubject.next(this.value);
    }
  }

  writeValue(value: any): void {
    if (value && this.type === 'datetime-local') {
      this.value = dayjs(value).format('YYYY-MM-DDTHH:mm');
    } else if (value && this.type === 'date') {
      this.value = dayjs(value).format('YYYY-MM-DD');
    } else {
      this.value = value;
    }
    if (this.isDateInput) {
      this.type = 'date';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Used for real-time validation while user is typing rather
   * than only triggering when focus is lost on input
   */
  validateNow() {
    if (this.formControl) {
      this.formControl.markAsTouched();
      this.validateInput();
    }
  }

  validateInput() {
    if (this.formControl) {
      const value = this.formControl.value;

      if (this.formControl.touched) {
        // Check if the input is required and is empty
        if (value === null || value === undefined || value === '') {
          if (this.required) {
            this.invalid = true;
            this.hintMessage = 'Dieses Feld darf nicht leer sein';
            return { 'required': true };
          }
          return null; // If not required, and value is empty, it's valid
        }

        // Min/Max validation
        if (this.min !== undefined && value < this.min) {
          this.invalid = true;
          this.hintMessage = this.inputHintMessage ? this.inputHintMessage : `Wert muss mindestens ${this.min} sein`;
          return { 'min': { 'min': this.min, 'actual': value } };
        }
        if (this.max !== undefined && value > this.max) {
          this.invalid = true;
          this.hintMessage = this.inputHintMessage ? this.inputHintMessage : `Wert darf maximal ${this.max} sein`;
          return { 'max': { 'max': this.max, 'actual': value } };
        }

        // Maxlength validation
        if (this.maxLength !== undefined && value.length > this.maxLength) {
          this.invalid = true;
          this.hintMessage = `Die maximale Länge von ${this.maxLength} Zeichen ist erreicht`;
          return { 'maxlength': { 'requiredLength': this.maxLength, 'actualLength': value.length } };
        }

        // Email validation
        if (this.type === 'email' && !this.isValidEmail(value)) {
          this.invalid = true;
          this.hintMessage = 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
          return { 'email': true };
        }

        // Phone validation
        if (this.type === 'tel' && this.validateSwissPhoneNumbers && !this.isValidSwissPhoneNumber(value)) {
          this.invalid = true;
          this.hintMessage = 'Bitte geben Sie eine gültige Telefonnummer ein';
          return { 'tel': true };
        }
      }
    }

    // Reset invalid state
    this.invalid = false;

    // If the value passes all validations
    return null;
  }

  validate(): ValidationErrors | null {
    return this.validateInput();
  }

  onBlur() {
    this.onTouched();
    this.validateNow();
  }

  /**
   * Clear the input.
   */
  clear() {
    this.value = '';
    this.onChange(this.value);
    this.validateNow();
    this.valueChangedSubject.next(this.value);
  }

  private setOptionsFromFormControl() {
    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
      if (this.formControl) {
        if (this.formControl.validator) {
          const validator = this.formControl.validator({} as AbstractControl);
          this.required = validator && validator['required'];
        }
        this.controlStatusChangeSubscription = this.formControl.statusChanges.subscribe(() => {
          this.required = this.formControl ? this.formControl.hasValidator(Validators.required) : false;
        });
      }
    }
  }

  private isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  private isValidSwissPhoneNumber(value: string): boolean {
    const phonePattern = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/;
    return phonePattern.test(value);
  }
}
