
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getUpdateProjectFoerderungDtoFormGroup() {
return new FormGroup({
    kevNumber: new FormControl<string | undefined>(undefined),
    eivFinancing: new FormControl<boolean | undefined>(undefined),
    eivFinancingAmount: new FormControl<number | undefined>(undefined),
    locationId: new FormControl<number | undefined>(undefined, [Validators.required]),
    communitySponsorshipAmount: new FormControl<number | undefined>(undefined),
    locationCommunitySponsorship: new FormControl<string | undefined>(undefined),
})
;
}
      