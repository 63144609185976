<div [attr.aria-expanded]="isOpen" class="ax-ui-accordion-item" role="tab" [class.ax-ui-accordion-item--with-border]="withBorder"
     [class.ax-ui-accordion-item--is-disabled]="isDisabled">
  <div #heading (click)="toggle()" [class.open]="isOpen" class="flex flex-row vertical-center space-between gap-24" role="heading" aria-level="4">
    <ng-content select="[accordion-header]" />
    <ng-content select="[accordion-icon]"></ng-content>
    <ax-ui-icon *ngIf="!hideIcon" [fontSize]="40" name="add" />
  </div>
  <div [class.open]="isOpen" role="region">
    <div class="content">
      <ng-content select="[accordion-content]" />
    </div>
  </div>
</div>
