/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreateTimerecordHolidayDto } from '../models/ax-create-timerecord-holiday-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxTimerecordHoliday } from '../models/ax-timerecord-holiday';
import { AxUpdateTimerecordHolidayDto } from '../models/ax-update-timerecord-holiday-dto';

@Injectable({ providedIn: 'root' })
export class AxTimerecordHolidaysV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `timerecordHolidaysControllerFindAllPaginated()` */
  static readonly TimerecordHolidaysControllerFindAllPaginatedPath = '/v2/timerecord-holidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by timerecordtypeId query param.
     *           <p>
     *              <b>Format: </b> filter.timerecordtypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.timerecordtypeId=$not:$like:John Doe&filter.timerecordtypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.timerecordtypeId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> date:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>description</li>
     * <li>date</li>
     * <li>timerecordtypeId</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'description:ASC' | 'description:DESC' | 'date:ASC' | 'date:DESC' | 'timerecordtypeId:ASC' | 'timerecordtypeId:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,date,businessunit.name,businessdivision.name,location.name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>date</li>
     * <li>businessunit.name</li>
     * <li>businessdivision.name</li>
     * <li>location.name</li>
     * <li>timerecordtype.name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTimerecordHoliday>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'timerecordtypeId'?: (string | Array<string>);
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('filter.businessunitId', params['filter.businessunitId'], {});
      rb.query('filter.businessdivisionId', params['filter.businessdivisionId'], {});
      rb.query('filter.timerecordtypeId', params['filter.timerecordtypeId'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxTimerecordHoliday>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        'businessunitId'?: (string | Array<string>);
        'businessdivisionId'?: (string | Array<string>);
        'timerecordtypeId'?: (string | Array<string>);
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Filter by businessunitId query param.
     *           <p>
     *              <b>Format: </b> filter.businessunitId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessunitId=$not:$like:John Doe&filter.businessunitId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessunitId'?: Array<string>;

    /**
     * Filter by businessdivisionId query param.
     *           <p>
     *              <b>Format: </b> filter.businessdivisionId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.businessdivisionId=$not:$like:John Doe&filter.businessdivisionId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.businessdivisionId'?: Array<string>;

    /**
     * Filter by timerecordtypeId query param.
     *           <p>
     *              <b>Format: </b> filter.timerecordtypeId={$not}:OPERATION:VALUE
     *           </p>
     *           <p>
     *              <b>Example: </b> filter.timerecordtypeId=$not:$like:John Doe&filter.timerecordtypeId=like:John
     *           </p>
     *           <h4>Available Operations</h4><ul><li>$eq</li></ul>
     */
      'filter.timerecordtypeId'?: Array<string>;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> date:DESC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li>
     * <li>description</li>
     * <li>date</li>
     * <li>timerecordtypeId</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC' | 'description:ASC' | 'description:DESC' | 'date:ASC' | 'date:DESC' | 'timerecordtypeId:ASC' | 'timerecordtypeId:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name,date,businessunit.name,businessdivision.name,location.name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li>
     * <li>date</li>
     * <li>businessunit.name</li>
     * <li>businessdivision.name</li>
     * <li>location.name</li>
     * <li>timerecordtype.name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxTimerecordHoliday>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'timerecordtypeId'?: (string | Array<string>);
};
};
}> {
    return this.timerecordHolidaysControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxTimerecordHoliday>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'timerecordtypeId'?: (string | Array<string>);
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxTimerecordHoliday>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
'businessunitId'?: (string | Array<string>);
'businessdivisionId'?: (string | Array<string>);
'timerecordtypeId'?: (string | Array<string>);
};
};
} => r.body)
    );
  }

  /** Path part for operation `timerecordHolidaysControllerCreate()` */
  static readonly TimerecordHolidaysControllerCreatePath = '/v2/timerecord-holidays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordHolidaysControllerCreate$Response(
    params: {
      body: AxCreateTimerecordHolidayDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecordHoliday>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecordHoliday>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordHolidaysControllerCreate(
    params: {
      body: AxCreateTimerecordHolidayDto
    },
    context?: HttpContext
  ): Observable<AxTimerecordHoliday> {
    return this.timerecordHolidaysControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecordHoliday>): AxTimerecordHoliday => r.body)
    );
  }

  /** Path part for operation `timerecordHolidaysControllerFindOneById()` */
  static readonly TimerecordHolidaysControllerFindOneByIdPath = '/v2/timerecord-holidays/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecordHoliday>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecordHoliday>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTimerecordHoliday> {
    return this.timerecordHolidaysControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecordHoliday>): AxTimerecordHoliday => r.body)
    );
  }

  /** Path part for operation `timerecordHolidaysControllerDelete()` */
  static readonly TimerecordHolidaysControllerDeletePath = '/v2/timerecord-holidays/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.timerecordHolidaysControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `timerecordHolidaysControllerUpdate()` */
  static readonly TimerecordHolidaysControllerUpdatePath = '/v2/timerecord-holidays/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordHolidaysControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdateTimerecordHolidayDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTimerecordHoliday>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimerecordHoliday>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timerecordHolidaysControllerUpdate(
    params: {
      id: number;
      body: AxUpdateTimerecordHolidayDto
    },
    context?: HttpContext
  ): Observable<AxTimerecordHoliday> {
    return this.timerecordHolidaysControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTimerecordHoliday>): AxTimerecordHoliday => r.body)
    );
  }

  /** Path part for operation `timerecordHolidaysControllerFindByDateRange()` */
  static readonly TimerecordHolidaysControllerFindByDateRangePath = '/v2/timerecord-holidays/byDate/{dateFrom}/{dateTo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timerecordHolidaysControllerFindByDateRange()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindByDateRange$Response(
    params: {
      dateFrom: string;
      dateTo: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTimerecordHoliday>>> {
    const rb = new RequestBuilder(this.rootUrl, AxTimerecordHolidaysV2Service.TimerecordHolidaysControllerFindByDateRangePath, 'get');
    if (params) {
      rb.path('dateFrom', params.dateFrom, {});
      rb.path('dateTo', params.dateTo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTimerecordHoliday>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timerecordHolidaysControllerFindByDateRange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timerecordHolidaysControllerFindByDateRange(
    params: {
      dateFrom: string;
      dateTo: string;
    },
    context?: HttpContext
  ): Observable<Array<AxTimerecordHoliday>> {
    return this.timerecordHolidaysControllerFindByDateRange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTimerecordHoliday>>): Array<AxTimerecordHoliday> => r.body)
    );
  }

}
