import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProjectDetailsStateModel } from './project-details-state.model';
import { AxProject } from '@axova-frontend-monorepo/axova-rest-api';
import { ProjectDetailsStateSetProject } from './project-details.actions';

const DEFAULT_STATE: ProjectDetailsStateModel = {
  project: undefined
};

@State<ProjectDetailsStateModel>({
  name: 'ax_state__projectdetails',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ProjectDetailsState {

  @Selector()
  static project(state: ProjectDetailsStateModel): AxProject | undefined {
    return state.project;
  }

  @Action(ProjectDetailsStateSetProject)
  ProjectDetailsStateSetProject(ctx: StateContext<ProjectDetailsStateModel>, action: ProjectDetailsStateSetProject) {
    ctx.patchState({ project: action.project });
  }
}
