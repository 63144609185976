import { Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { FormEditModeService } from '../services/form-editmode/form-edit-mode.service';
import { LoadingAndFeedbackState } from '../models/loading-and-feedback-state.type';
import { FormGroup } from '@angular/forms';

export interface FormEditInlineModel {
  canUpdate$: Observable<boolean>;
  formGroupUpdate: FormGroup;
  editMode: boolean;
  formSaveLoadingState: LoadingAndFeedbackState;

  hasUnsavedChanges(): boolean;

  resetForm(): void;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<FormEditInlineModel> {
  constructor(
    private readonly formEditModeService: FormEditModeService
  ) {
  }

  canDeactivate(component: FormEditInlineModel): Observable<boolean> | boolean {
    if (component.hasUnsavedChanges()) {
      return this.formEditModeService.showConfirmEditModeCancelModal().pipe(take(1));
    } else {
      return true;
    }
  }
}
