import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmActionModalButton, ConfirmActionModalOptions } from '../../models/confirm-action-modal-options.model';
import { ModalComponent } from '../modal/modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ax-ui-modal-confirm-action',
  templateUrl: './modal-confirm-action.component.html',
  styleUrls: ['./modal-confirm-action.component.scss'],
  standalone: true,
  imports: [
    ModalComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class ModalConfirmActionComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationModal') modalElement!: ModalComponent;
  @Input({ required: true }) modalOptions!: ConfirmActionModalOptions;
  @Input() showCancelButton = true;
  @Output() userAction: EventEmitter<ConfirmActionModalButton | undefined> = new EventEmitter<ConfirmActionModalButton | undefined>();

  ngOnInit() {
    if (!this.modalOptions.title && this.modalOptions.title !== '') {
      this.modalOptions.title = 'EINTRAG_LOESCHEN_BESTAETIGUNGSTEXT';
    }
    if (!this.modalOptions.buttons || !this.modalOptions.buttons.length) {
      this.modalOptions.buttons = [
        {
          label: 'LOESCHEN',
          variant: 'primary',
          value: true
        }
      ];
    }
    if (this.showCancelButton && !this.modalOptions.hideCancelButton && !this.modalOptions.buttons.find(button => button.role === 'cancel')) {
      this.modalOptions.buttons.push({
        label: 'ABBRECHEN',
        variant: 'secondary',
        value: false,
        role: 'cancel'
      });
    }
    if (this.modalOptions.hideCloseButton !== true && this.modalOptions.hideCloseButton !== false) {
      this.modalOptions.hideCloseButton = true;
    }
  }

  ngAfterViewInit() {
    this.modalElement.open();
  }

  public async doAction(selectedButton: ConfirmActionModalButton) {
    await this.modalElement.close();
    if (selectedButton.role === 'cancel') {
      this.userAction.emit();
      return;
    }
    this.userAction.emit(selectedButton);
  }

  public async declineAction() {
    await this.modalElement.close();
    this.userAction.emit();
  }
}
