import { Component, OnInit } from '@angular/core';

import { AxLocation, AxReservationtype, AxReservationtypesService } from '@axova-frontend-monorepo/axova-rest-api';
import { Store } from '@ngxs/store';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { TerminassistentState } from '../../ngxs/terminassistent/terminassistent.state';
import { TerminassistentStateSetReservationtype } from '../../ngxs/terminassistent/terminassistent.actions';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { LabelComponent } from '../../../label/label.component';
import { Router } from '@angular/router';
import { IconComponent } from '../../../icon/icon.component';
import { TooltipDirective } from '../../../../directives/tooltip/tooltip.directive';

@Component({
  selector: 'ax-ui-terminassistent-choose-containertype',
  standalone: true,
  imports: [LabelComponent, IconComponent, TooltipDirective],
  templateUrl: 'terminassistent-choose-container-type.component.html',
  styleUrl: 'terminassistent-choose-container-type.component.scss',
})
export class TerminassistentChooseContainerTypeComponent implements OnInit {
  public reservationTypes: AxReservationtype[] | undefined;
  public reservationTypesNotAvailable: AxReservationtype[] | undefined;
  public selectedReservationTypeIndex: number | undefined;
  private location: AxLocation | undefined;
  serviceIcons: string[] = ['solar_power', 'handyman', 'roofing', 'battery_charging_full', 'heat_pump'];

  constructor(private store: Store, private axReservationtypesService: AxReservationtypesService, private router: Router) {}

  ngOnInit(): void {
    this.store.select(TerminassistentState.location).subscribe(async (location) => {
      this.location = location;
      await this.findAllReservationTypes();
      const reservationType = this.store.selectSnapshot(TerminassistentState.reservationtype);
      if (reservationType) {
        this.selectedReservationTypeIndex = this.reservationTypes?.findIndex((reservation) => reservation.id === reservationType.id);
      }
    });
  }

  public async chooseType(reservationType: AxReservationtype, index: number) {
    this.store.dispatch(new TerminassistentStateSetReservationtype(reservationType));
    this.selectedReservationTypeIndex = index;
    await this.router.navigateByUrl('terminassistent/zeit');
  }

  private async findAllReservationTypes() {
    if (this.location && this.location.businessunit.id) {
      try {
        const reservationtypes = await lastValueFrom(
          this.axReservationtypesService.reservationtypesControllerFindAll({
            businessunitId: this.location.businessunit.id,
            'x-api-key': environment.apiConfiguration.publicApiKey,
          }),
        );
        this.reservationTypes = reservationtypes.filter((reservationtype) => reservationtype.isActiveForSelectedBusinessunit);
        this.reservationTypesNotAvailable = reservationtypes.filter((reservationtype) => !reservationtype.isActiveForSelectedBusinessunit);
      } catch (findReservationTypesException) {
        LoggerService.ERROR('Terminassisstent find reservationtypes search', findReservationTypesException);
      }
    }
  }
}
