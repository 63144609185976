import { Pipe, PipeTransform } from '@angular/core';
import { AxStocklocation, AxStocklocationsV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { lastValueFrom } from 'rxjs';

@Pipe({
  name: 'getStocklocationBreadcrumb',
  standalone: true,
})
export class GetStocklocationBreadcrumbPipe implements PipeTransform {
  constructor(
    private readonly axStocklocationsV2Service: AxStocklocationsV2Service,
  ) {
  }

  async transform(stocklocation: AxStocklocation): Promise<string> {
    const stocklocationWithParents = await lastValueFrom(this.axStocklocationsV2Service.stocklocationsControllerGetAllParentStocklocationsForId({ id: stocklocation.id }));
    const stocklocationNames: string[] = [`<strong>${stocklocationWithParents.name}</strong>`];
    let currentStocklocation = stocklocationWithParents;
    while (currentStocklocation.parent && currentStocklocation.parent.name) {
      stocklocationNames.unshift(currentStocklocation.parent.name);
      currentStocklocation = currentStocklocation.parent;
    }
    return stocklocationNames.join(' → ');
  }
}
