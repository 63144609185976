import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { DropdownOption } from '@axova-frontend-monorepo/axova-commons';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { CheckboxComponent } from '../inputs/checkbox/checkbox.component';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

export type dropDownAlignment = 'left' | 'right' | 'bottom' | 'top' | 'top center' | 'bottom center';

@Component({
  selector: 'ax-ui-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent, CheckboxComponent, IconComponent, TranslateModule],
})
export class DropdownListComponent implements OnChanges {
  @ViewChildren('optionList') optionList!: QueryList<ElementRef>;
  @ViewChildren('optionElements') optionElements!: QueryList<ElementRef>;

  @Input() options: DropdownOption[] = [];
  @Input() activeStringValue!: string;
  @Input() initialOption: DropdownOption | undefined;
  @Input() activeOption: DropdownOption | undefined;
  @Input() dropDownOpen = false;
  @Input() maxWidth: number | string | undefined;
  @Input() alignment: dropDownAlignment = 'bottom';
  @Input() preventActiveHighlighting = false;
  @Input() preventClickOutsideClosing = false;
  @Input() dynamicDropdownOptions = false;
  @Input() dropdownWidthFitContent = false;
  @Input() noParentCard = false;
  @Input() withCheckboxes = false;
  @Input() listTitle = '';

  @Output() optionSelected: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() optionRemoved: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() selectedCheckboxOptionsChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() activeStringValueChange: EventEmitter<string> = new EventEmitter<string>();
  // To keep track of selected index
  public selectedIndex = -1;
  // For highlighting the best match
  public bestMatch: DropdownOption | undefined;
  public checkboxesSelectedAll = false;
  public checkboxesSelectedNone = false;
  public numberOfSelectedCheckboxes = 0;
  public filteredOptions: DropdownOption[] = [];
  public initialOptionsState: DropdownOption[] = [];
  public hasChanges = false;
  public groupedOptions: { [key: string]: DropdownOption[] } = {};
  public groupsState: { [key: string]: { allSelected: boolean } } = {};
  public useGroups = false;
  private activeIndex = 0;

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (this.preventClickOutsideClosing) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.parentElement.contains(targetElement);
    if (this.dropDownOpen && !clickedInside) {
      this.dropDownOpen = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Find search result from dropdown list.
    if (changes['activeStringValue'] && changes['activeStringValue'].currentValue !== changes['activeStringValue'].previousValue) {
      if (!this.dynamicDropdownOptions) {
        this.findBestMatch();
      }
    }
    // If the active string value (aka search string) is being delete by the user, and the search field is empty again, it should
    // display all options again (not just an empty list).
    if (changes['activeStringValue'] && changes['activeStringValue'].previousValue && !changes['activeStringValue'].currentValue) {
      this.filteredOptions = this.options;
      this.bestMatch = undefined;
      this.dropDownOpen = true;
    }
    if (changes['initialOption'] && changes['initialOption'].currentValue !== changes['initialOption'].previousValue) {
      const option = this.options.find((option) => option.value === this.initialOption?.value);
      if (option) {
        this.selectOption(option);
        this.dropDownOpen = false;
      }
    }
    if (changes['options'] && changes['options'].currentValue && changes['options'].currentValue !== changes['options'].previousValue) {
      this.filteredOptions = this.options;
      this.initialOptionsState = JSON.parse(JSON.stringify(this.filteredOptions));
      if (this.dynamicDropdownOptions && !this.dropDownOpen) {
        this.toggleDropDown();
      }
      if (this.withCheckboxes) {
        this.completeGroupNames();
        this.groupOptions();
        this.checkGroupAllSelected();
        this.numberOfSelectedCheckboxes = this.options.filter((option) => option.selected === true).length;
        this.checkboxesSelectedAll = this.options.every((option) => option.selected === true);
        this.checkboxesSelectedNone = this.options.every((option) => option.selected === false);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setDropDownMaxHeight();
  }

  public selectOption(option: DropdownOption) {
    this.activeStringValue = option.label;
    this.optionSelected.emit(option);
    this.activeIndex = this.options.findIndex((arrayOption) => arrayOption === option);
    this.selectedIndex = this.activeIndex;
    this.toggleDropDown();
  }

  public toggleDropDown() {
    if (this.options.length > 0) {
      if (!this.dropDownOpen) {
        this.checkGroupAllSelected();
        this.bestMatch = this.options.find((option) => option.label.includes(this.activeStringValue));
        if (this.selectedIndex > -1) {
          this.selectedIndex = this.activeIndex;
        }
        // Store a deep copy of the options array to ensure the initial state is not modified later
        this.initialOptionsState = JSON.parse(JSON.stringify(this.filteredOptions));
      }
      this.dropDownOpen = !this.dropDownOpen;
      setTimeout(() => {
        this.setDropDownMaxHeight();
      }, 0);
    }
  }

  public findBestMatch() {
    if (this.activeStringValue) {
      const searchLowerCase = `${this.activeStringValue}`.toLowerCase();
      this.filteredOptions = this.options.filter((option) => `${option.label}`.toLowerCase().includes(searchLowerCase));
      if (this.filteredOptions.length === 0) {
        if (this.dropDownOpen) {
          this.toggleDropDown();
        }
        return;
      }
      this.bestMatch = this.options.find((option) => `${option.label}`.toLowerCase().includes(searchLowerCase));
      if (this.bestMatch) {
        if (this.activeStringValue !== this.bestMatch.label) {
          this.dropDownOpen = true;
        }
        this.selectedIndex = this.options.findIndex((option) => option.value === this.bestMatch?.value);
      }
    } else {
      this.filteredOptions = this.options;
      this.bestMatch = undefined;
      this.dropDownOpen = false;
    }
  }

  public selectBestMatch() {
    if (this.bestMatch) {
      const option = this.options[this.selectedIndex];
      this.selectOption(option);
    }
  }

  public navigateOptionUpOrDown(direction: 1 | -1) {
    if (this.options.length === 0 || !this.dropDownOpen) return;

    // Reset selectedIndex if it's out of bounds
    if (this.selectedIndex < 0 || this.selectedIndex >= this.options.length) {
      this.selectedIndex = direction === 1 ? 0 : this.options.length - 1;
    } else {
      this.selectedIndex += direction;
      if (this.selectedIndex < 0) this.selectedIndex = this.options.length - 1;
      if (this.selectedIndex >= this.options.length) this.selectedIndex = 0;
    }

    // Update bestMatch
    this.bestMatch = this.options[this.selectedIndex];

    // Get the DOM element for the current option index.
    const optionElement = this.optionElements.toArray()[this.selectedIndex].nativeElement;

    // Scroll the option into view.
    optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  public selectCheckboxOption(option: DropdownOption) {
    if (option.selected) {
      this.numberOfSelectedCheckboxes -= 1;
      option.selected = false;
      this.optionRemoved.emit(option);
    } else {
      this.numberOfSelectedCheckboxes += 1;
      option.selected = true;
      this.optionSelected.emit(option);
    }
    this.updateHasChanges();
    this.checkboxesSelectedAll = this.options.every((option) => option.selected === true);
    this.checkboxesSelectedNone = this.options.every((option) => option.selected === false);
    this.checkGroupAllSelected();
  }

  public actionForAllCheckboxes(action: 'select' | 'deselect') {
    this.numberOfSelectedCheckboxes = action === 'deselect' ? this.options.length : 0;
    this.options.forEach((option) => {
      option.selected = action !== 'select';
      this.selectCheckboxOption(option);
    });
    this.checkGroupAllSelected();
  }

  public acceptSelectedCheckboxOptions() {
    this.toggleDropDown();
    if (this.hasChanges) {
      this.selectedCheckboxOptionsChange.emit();
    }
  }

  public resetSelection() {
    this.filteredOptions = JSON.parse(JSON.stringify(this.initialOptionsState));
    this.updateHasChanges();
  }

  public getGroupObjectKeys(obj: any): string[] {
    // Assure 'Weitere' is last array element
    const keys = Object.keys(obj);
    const indexOfWeitere = keys.indexOf('Weitere');
    if (indexOfWeitere !== -1) {
      keys.splice(indexOfWeitere, 1);
      keys.push('Weitere');
    }
    return keys;
  }

  public toggleAllGroupOptions(group: string) {
    const allSelected = this.groupedOptions[group].every((option) => option.selected);
    const selectedOptionsCountInGroup = this.groupedOptions[group].filter((option) => option.selected).length;
    this.numberOfSelectedCheckboxes -= allSelected || selectedOptionsCountInGroup === 0 ? 0 : selectedOptionsCountInGroup;
    this.options.forEach((option) => {
      if (option.group !== group) return;
      option.selected = allSelected;
      this.selectCheckboxOption(option);
    });
  }

  public checkGroupAllSelected() {
    this.getGroupObjectKeys(this.groupedOptions).forEach((group) => {
      this.groupsState[group] = {
        allSelected: this.groupedOptions[group].every((option) => option.selected),
      };
    });
  }

  private setDropDownMaxHeight() {
    if (this.optionList.first) {
      const optionListElement = this.optionList.first.nativeElement;
      const viewportHeight = window.innerHeight;
      const dropdownPosition = optionListElement.getBoundingClientRect().top;
      const bottomMargin = 24;
      const maxHeight = viewportHeight - dropdownPosition - bottomMargin;
      optionListElement.style.maxHeight = `${maxHeight}px`;
    }
  }

  // Method to deeply compare two arrays of DropdownOption objects
  private updateHasChanges() {
    const arr1 = this.filteredOptions;
    const arr2 = this.initialOptionsState;
    if (arr1.length !== arr2.length) {
      this.hasChanges = true;
      return;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].selected !== arr2[i].selected) {
        this.hasChanges = true;
        break;
      } else this.hasChanges = false;
    }
  }

  public groupOptions() {
    // reset options when reopening the dropdown
    this.groupedOptions = {};
    // fill the options container
    this.filteredOptions.forEach((option) => {
      const groupName = option.group || 'Weitere';
      if (!this.groupedOptions[groupName]) {
        this.groupedOptions[groupName] = [];
      }
      this.groupedOptions[groupName].push(option);
    });

    // Determine if we should use groups or not (based on the presence of any group other than 'none')
    this.useGroups = Object.keys(this.groupedOptions).some((groupName) => groupName !== 'Weitere');
  }

  private completeGroupNames() {
    if (this.options.find((option) => option.group !== undefined)) {
      this.options.forEach((option) => {
        if (option.group === undefined) {
          option.group = 'Weitere';
        }
      });
    }
  }
}
