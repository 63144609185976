<div>
  <h2 class="ax-web-text--large margin-bottom-24">Ihr Projekt Schwerpunkt</h2>
  <p>
    Bitte teilen Sie uns mit, zu welcher Art von Dienstleistung Sie gerne beim Objekt vor Ort für Ihr Projekt beraten
    werden möchten:
  </p>

  <ul class="margin-top-32 ax-ui-terminassistent-choose-containertype__list">
    @for (reservationType of reservationTypes; track reservationType; let i = $index) {
      <li>
        <div [axUiTooltipMaxWidth]="400"
             [axUiTooltip]="tooltipTemplate"
             class="width-fit-content ax-ui-terminassistent-choose-containertype__tooltip">
          <ax-ui-icon name="info"
                      theme="success"
                      class="cursor-help"
                      [paddingSize]="4" />
          <ng-template #tooltipTemplate>
            <div class="padding-24">
              <p><small>{{ reservationType.additionalInfotext }}</small></p>
            </div>
          </ng-template>
        </div>
        <div (click)="chooseType(reservationType, i)"
             class="ax-card ax-card--clickable"
             [class.ax-card--selected]="selectedReservationTypeIndex === i">
          <div class="flex flex-row gap-12 space-between width-100">
            <div>
              <ax-ui-icon [boldness]="400"
                          [fontSize]="32"
                          [name]="serviceIcons[i]" />
              <p class="ax-web-text--medium margin-top-8"><b>{{ reservationType.description }}</b>
              </p>
            </div>
            <ax-ui-icon name="arrow_forward" />
          </div>
          <p class="color-light"><small>Dauer: ca. {{ reservationType.durationInMinutes }} Minuten</small></p>
        </div>
      </li>
    }
  </ul>

  @if (reservationTypesNotAvailable && reservationTypesNotAvailable.length > 0) {
    <ax-ui-label text="Folgende Dienstleistungen bieten wir am angegeben Ort aktuell nicht an"
                 variant="warning"
                 [small]="true"
                 class="display-block margin-top-64"
                 borderStyle="square"
                 [textWrap]="true" />

    <ul class="margin-top-32 ax-ui-terminassistent-choose-containertype__list">
      @for (reservationType of reservationTypesNotAvailable; track reservationType; let i = $index) {
        <li class="disabled">
          <div [axUiTooltipMaxWidth]="400"
               [axUiTooltip]="tooltipTemplate"
               class="width-fit-content ax-ui-terminassistent-choose-containertype__tooltip">
            <ax-ui-icon name="info"
                        theme="success"
                        class="cursor-help"
                        [paddingSize]="4" />
            <ng-template #tooltipTemplate>
              <div class="padding-24">
                <p><small>{{ reservationType.additionalInfotext }}</small></p>
              </div>
            </ng-template>
          </div>
          <div class="ax-card">
            <div class="flex flex-row gap-12 space-between width-100">
              <div>
                <ax-ui-icon [boldness]="400"
                            [fontSize]="32"
                            [name]="serviceIcons[i]" />
                <p class="ax-web-text--medium margin-top-8"><b>{{ reservationType.description }}</b>
                </p>
              </div>
            </div>
          </div>
        </li>
      }
    </ul>
  }
</div>
