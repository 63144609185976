import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getEquipmentlogtypeIconName',
  standalone: true,
})
export class GetEquipmentlogtypeIconNamePipe implements PipeTransform {
  transform(equipmentlogtypeId: number): string {
    switch (equipmentlogtypeId) {
      case 1:
        return 'login';
      case 2:
        return 'logout';
      case 3:
        return 'disabled_by_default';
      case 4:
        return 'engineering';
      case 5:
      case 6:
        return 'checklist';
      default:
        return 'question_mark';
    }
  }
}
