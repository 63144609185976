<div [class.close]="!isOpenWithAnimationDelay"
     [class.open]="isOpenWithAnimationDelay"
     [id]="modalId"
     aria-labelledby="modalTitle"
     aria-modal="true"
     class="ax-ui-modal">
  <div [class]="modalWidth"
       class="ax-ui-modal__container"
       tabindex="-1">
    <div class="ax-ui-modal__content ax-card no-padding"
         [class.no-inline-borders]="!showInlineBorders">
      <div class="ax-ui-modal__header"
           [class.border-bottom]="showInlineBorders">
        <div class="ax-ui-modal__header-text">
          <h2 id="modalTitle">{{ titleText | translate }}</h2>
          @if (introText) {
            <p>{{ introText | translate }}</p>
          }
        </div>
        @if (!hideCloseButton) {
          <ax-ui-button (click)="close(true)"
                        class="ax-ui-modal__header-close-button"
                        [iconOnly]="true"
                        aria-label="Close"
                        [small]="modalWidth === 'small'"
                        iconName="close"
                        variant="secondary">
          </ax-ui-button>
        }
      </div>

      <div #modalBody class="ax-ui-modal__body"
           [class.ax-ui-modal__body--no-padding]="noBodyPadding"
           [class.padding]="!noBodyContent"
           [class.padding-bottom-32]="noFooter">
        <ng-content select="[modal-body]"></ng-content>
        <ng-template #dynamicComponentSlot></ng-template>
      </div>

      <!-- Modal Footer (if needed, in case of forms, put buttons inside form of modal__body) -->
      <div class="ax-ui-modal__footer" [class.border-top]="showInlineBorders">
        <ng-content select="[modal-footer]"></ng-content>
        <!--      Only use buttons here, example:-->
        <!--      <ng-container modal-footer>-->
        <!--        <ax-ui-button text="Speichern" (click)="demoModal.close(); isModalOpen = false"></ax-ui-button>-->
        <!--        <ax-ui-button text="Schliessen" variant="secondary" (click)="demoModal.close(); isModalOpen = false"></ax-ui-button>-->
        <!--      </ng-container>-->
      </div>
    </div>
  </div>
</div>
