import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { lastValueFrom } from 'rxjs';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AxDocumentsV2Service } from '@axova-frontend-monorepo/axova-rest-api';

@Component({
  selector: 'ax-ui-trix-editor',
  templateUrl: './trix-editor.component.html',
  styleUrls: ['trix-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrixEditorComponent implements AfterViewInit, OnChanges {
  @ViewChild('trixEditor') trixEditorElement!: ElementRef;
  @ViewChild('trixEditorContainer') trixEditorContainerElement!: ElementRef;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() proseSmallSpacings = false;
  @Input() content: string | null | undefined = '';
  @Input() triggerReset = false;
  @Output() contentUpdated: EventEmitter<string> = new EventEmitter<string>();
  public id: string = Math.random().toString(36).substring(2, 9);
  private trixEditor: any;
  private trixEditorContainer: any;
  private eventListenersSet = false;
  private contentChangeFromInput = false;

  constructor(private readonly axDocumentsV2Service: AxDocumentsV2Service) {
    document.addEventListener('trix-before-initialize', (event: any) => {
      const Trix = (window as any).Trix;
      Trix.config.blockAttributes.default.tagName = 'p';
      Trix.config.blockAttributes.default.breakOnReturn = true;
      Trix.Block.prototype.breaksOnReturn = function () {
        const attr = this.getLastAttribute();
        // Function `Trix.getBlockConfig()` was not found so I replaced it with analog:
        const config = Trix.config.blockAttributes[attr ? attr : 'default'];
        return config ? config.breakOnReturn : false;
      };

      Trix.config.blockAttributes.heading1.tagName = 'h2';
      Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function () {
        if (this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
          return this.startLocation.offset > 0;
        } else {
          return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
        }
      };

      const { toolbarElement } = event.target;
      const inputElement = toolbarElement.querySelector('input[name=href]');
      inputElement.type = 'text';
      inputElement.pattern = '(https?://|/).+';
    });
  }

  ngAfterViewInit() {
    try {
      this.trixEditor = this.trixEditorElement?.nativeElement;
      this.trixEditorContainer = this.trixEditorContainerElement?.nativeElement;
      if (this.disabled || this.readonly) {
        this.toggleDisableTrix();
      }
      this.toggleReadonlyTrix();
      if (!this.eventListenersSet) {
        this.setContent();
        this.setEventListeners();
      }
    } catch (noNativeElementFound) {
      LoggerService.ERROR(this, 'noNativeElementFound', noNativeElementFound);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['triggerReset'] && !changes['triggerReset'].isFirstChange() && changes['triggerReset'].currentValue === true) {
        while (this.trixEditor.editorController.editor.canUndo()) {
          this.trixEditor.editorController.editor.undo();
        }
      }
    } catch (noTriggerReset) {
      LoggerService.ERROR(this, 'noTriggerReset', noTriggerReset);
    }
    if (changes['content'] && changes['content'].currentValue && changes['content'].previousValue === null) {
      this.contentChangeFromInput = true;
      this.setContent();
    }

    if (changes['readonly']) {
      this.toggleReadonlyTrix();
      this.toggleDisableTrix();
    }

    if (changes['disabled']) {
      this.toggleDisableTrix();
    }
  }

  setContent() {
    if (this.trixEditor && this.content) {
      this.trixEditor.editorController.editor.insertHTML(this.content);
    }
  }

  setEventListeners() {
    this.eventListenersSet = true;
    this.trixEditor.addEventListener('trix-change', (event: any) => {
      let htmlString = this.addTargetBlankToAllExternalAnchorElements(event.target.value);
      htmlString = this.removeEmptyParagraphs(htmlString);
      if (!this.contentChangeFromInput) {
        this.contentUpdated.emit(htmlString);
      } else {
        this.contentChangeFromInput = false;
      }
    });
    this.trixEditor.addEventListener('trix-attachment-add', async (event: any) => {
      event.attachment.setUploadProgress(50);
      const uploadedDocument = await lastValueFrom(
        this.axDocumentsV2Service.documentsControllerUploadFile_1({
          body: {
            file: event.attachment.file,
            folder: 'Trixeditor',
          },
        }),
      );
      event.attachment.setUploadProgress(100);
      event.attachment.setAttributes({
        host: environment.apiConfiguration.baseUrl,
        url: environment.apiConfiguration.baseUrl + uploadedDocument.fileSrcOptimized,
      });
    });
    try {
      this.trixEditor.blur();
    } catch (noBlurException) {}
  }

  private showTrixToolbar() {
    this.trixEditorContainer.querySelector('trix-toolbar').classList.add('active');
  }

  private hideTrixToolbar() {
    this.trixEditorContainer.querySelector('trix-toolbar').classList.remove('active');
  }

  private toggleDisableTrix() {
    if (this.trixEditor) {
      this.trixEditor.toggleAttribute('disabled');
      this.trixEditorContainer.querySelector('trix-toolbar').toggleAttribute('disabled');
    }
  }

  private toggleReadonlyTrix() {
    if (this.trixEditor) {
      if (!this.readonly && this.trixEditor.classList.contains('readonly')) {
        this.trixEditor.classList.remove('readonly');
        this.showTrixToolbar();
      } else {
        this.trixEditor.classList.add('readonly');
        this.hideTrixToolbar();
      }
    }
  }

  private addTargetBlankToAllExternalAnchorElements(htmlString: string): string {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      const anchors = doc.querySelectorAll('a');
      anchors.forEach((anchor) => {
        const href = anchor.getAttribute('href');
        if (!anchor.getAttribute('target') && href && href.startsWith('http')) {
          anchor.setAttribute('target', '_blank');
        }
      });

      return doc.body.innerHTML;
    } catch (parsingException) {
      LoggerService.ERROR(this, 'parsingException', parsingException);
      return htmlString;
    }
  }

  private removeEmptyParagraphs(htmlString: string): string {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      const paragraphs = doc.querySelectorAll('p');
      paragraphs.forEach((paragraph) => {
        if (paragraph.innerHTML === '') {
          paragraph.remove();
        }
      });

      return doc.body.innerHTML;
    } catch (parsingException) {
      LoggerService.ERROR(this, 'parsingException', parsingException);
      return htmlString;
    }
  }
}
