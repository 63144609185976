import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabOptions } from '../../models/tab-options.model';
import { SelectDropdownOptions } from '../../models/select-dropdown-options.model';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { SelectComponent } from '../inputs/select/select.component';
import { IconComponent } from '../icon/icon.component';
import { Store } from '@ngxs/store';
import { ProfileState } from '@axova-frontend-monorepo/axova-state';

@Directive({
  selector: '[axUiTabContent]',
  standalone: true,
})
export class TabContentDirective {
  @Input() tabReference = '';

  constructor(public template: TemplateRef<never>) {}
}

@Component({
  selector: 'ax-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [IconComponent, SelectComponent, NgTemplateOutlet, TranslateModule],
})
export class TabsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup!: ElementRef;
  @ViewChildren('tabButton') tabButtons!: QueryList<ElementRef>;
  @ContentChildren(TabContentDirective) tabContentTemplates!: QueryList<TabContentDirective>;

  @Input() tabOptions: TabOptions[] = [];
  @Input() saveRouteInUrl = true;
  @Input() noPadding = false;
  @Input() contentPadding = true;
  @Input() activeTab!: string; // Ensure the activeTab is managed as an input
  @Input() routeParamName: 'tab' | 'subtab' = 'tab';
  @Output() activeTabChange: EventEmitter<string> = new EventEmitter<string>();

  public tabsDoOverflow = false;
  public selectOptions: SelectDropdownOptions[] = [];
  private tabGroupResizeObserver!: ResizeObserver;
  private routingChangeSubscription!: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, private elementRef: ElementRef, private store: Store) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.updateActiveTabFromParams(params);
    });

    this.tabOptions = this.tabOptions.map((tabOption) => {
      if (tabOption.requiredPermission && !this.store.selectSnapshot(ProfileState.hasPermission(tabOption.requiredPermission))) {
        tabOption.hidden = true;
      }
      return tabOption;
    });

    this.tabOptions.forEach((tab) => {
      this.selectOptions.push({
        label: tab.title,
        value: tab.tabReference,
      });
    });

    this.routingChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTabFromParams(this.activatedRoute.snapshot.params);
      }
    });
  }

  ngAfterViewInit() {
    this.tabGroupResizeObserver = new ResizeObserver(() => {
      this.tabsDoOverflow = this.tabGroupHasOverflow();
      this.changeDetectorRef.detectChanges();
    });

    this.tabGroupResizeObserver.observe(this.tabGroup.nativeElement);
  }

  ngOnDestroy() {
    this.tabGroupResizeObserver?.disconnect();
    this.routingChangeSubscription.unsubscribe();
  }

  private updateActiveTabFromParams(params: Params) {
    const tabKey = params[this.routeParamName];
    if (tabKey && this.tabOptions.some((tab) => tab.tabReference === tabKey)) {
      this.activeTab = tabKey;
      this.activateTab(tabKey, true);
    } else if (!tabKey) {
      this.activeTab = this.tabOptions[0].tabReference;
      this.activateTab(this.activeTab, true);
    }
  }

  /**
   * **************************************
   * *** DANGER ** THIS METHOD IS HACKY ***
   * **************************************
   *
   *
   * @param tabReference
   * @param preventUrlChange
   */
  public activateTab(tabReference: string, preventUrlChange = false) {
    // prevent changeDetection errors in the parent by wrapping this in setTimeout
    setTimeout(() => {
      this.activeTab = tabReference;
      this.activeTabChange.emit(tabReference);
    });

    if (!preventUrlChange && this.saveRouteInUrl) {
      let route = this.activatedRoute.snapshot;
      let hasAuxiliaryOutlet = false;
      const urlSegmentsPerOutlet: { [outlet: string]: string[] } = {};

      while (route.parent) {
        route = route.parent;
        if (route.url.length) {
          const outlet = this.getOutletForRoute(route);
          if (outlet !== 'primary') {
            hasAuxiliaryOutlet = true;
          }
          if (!urlSegmentsPerOutlet[outlet]) {
            urlSegmentsPerOutlet[outlet] = [];
          }
          urlSegmentsPerOutlet[outlet] = [...route.url.map((segment) => segment.path), ...urlSegmentsPerOutlet[outlet]];
        }
      }

      // Get the current URL params for tab/subtab, remove subtab if we're changing tab.
      const currentParams = { ...this.activatedRoute.snapshot.params };
      if (this.routeParamName === 'tab') {
        delete currentParams['subtab'];
      }

      // Update the specific parameter
      currentParams[this.routeParamName] = tabReference;

      // Story primary outlet in seperate variable and delete it.
      const primaryOutletSegments = urlSegmentsPerOutlet['primary'];
      for (const [outlet, segments] of Object.entries(urlSegmentsPerOutlet)) {
        const currentParamsArray = Object.values(currentParams)
          .filter((value: string) => segments.indexOf(value) === -1)
          .map((value: string) => value) as string[];
        urlSegmentsPerOutlet[outlet] = [...segments, ...currentParamsArray];
      }

      if (hasAuxiliaryOutlet) {
        delete urlSegmentsPerOutlet['primary'];
        this.router
          .navigate([...primaryOutletSegments, { outlets: urlSegmentsPerOutlet }], {
            queryParamsHandling: 'merge',
            queryParams: this.activatedRoute.snapshot.queryParams,
          })
          .catch();
      } else {
        this.router
          .navigate([...urlSegmentsPerOutlet['primary']], {
            queryParamsHandling: 'merge',
            queryParams: this.activatedRoute.snapshot.queryParams,
          })
          .catch();
      }
    }
  }

  public onKeydown(event: KeyboardEvent) {
    const activeTabIndex = this.tabOptions.findIndex((tab) => tab.tabReference === this.activeTab);
    if (event.key === 'ArrowRight') {
      const nextTabReference = this.tabOptions[(activeTabIndex + 1) % this.tabOptions.length].tabReference;
      this.activateTab(nextTabReference);
    } else if (event.key === 'ArrowLeft') {
      const previousTabReference = this.tabOptions[activeTabIndex === 0 ? this.tabOptions.length - 1 : activeTabIndex - 1].tabReference;
      this.activateTab(previousTabReference);
    }
  }

  private tabGroupHasOverflow() {
    const element = this.tabGroup.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }

  private getOutletForRoute(route: ActivatedRouteSnapshot): string {
    let currentRoute: ActivatedRouteSnapshot | null = route;

    while (currentRoute?.parent) {
      if (currentRoute.outlet !== 'primary') {
        return currentRoute.outlet; // Found the named outlet
      }
      currentRoute = currentRoute.parent; // Move up
    }

    return 'primary'; // Default to primary if no named outlet is found
  }
}
