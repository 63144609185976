<div [axUiModalFloating]="modalTemplate"
     [axUiModalFloatingPosition]="modalPosition"
     (axUiModalFloatingOpening)="dropDownOpening.emit()"
     class="ax-ui-actions-dropdown-button">
  <ax-ui-button [disabled]="dropdownOptions && !dropdownOptions.length"
                [iconOnly]="true"
                [small]="!defaultButtonSize"
                [whiteBackground]="buttonWhiteBackground"
                [tooltipDescription]="tooltipDescription"
                [iconName]="iconName"
                [variant]="buttonVariant" />
  <ng-template #modalTemplate>
    <ax-ui-dropdown-list #actionsDropdown
                         (optionSelected)="selectAction($event)"
                         [dropDownOpen]="true"
                         [noParentCard]="true"
                         [options]="dropdownOptions"
                         [preventActiveHighlighting]="true"
                         [preventClickOutsideClosing]="true" />
  </ng-template>
</div>
