import { AxStandardtaskResponsibleUsertypeEnum } from '@axova-frontend-monorepo/axova-rest-api';

export const getResponsibleUserTypeFromEnum = function(userType: AxStandardtaskResponsibleUsertypeEnum | null): string {
  if (!userType) {
    return '';
  }
  switch (userType) {
    case (AxStandardtaskResponsibleUsertypeEnum.Tpbuser): {
      return 'Projektleiter des Projekts';
    }
    case (AxStandardtaskResponsibleUsertypeEnum.User): {
      return 'Kundenberater des Projekts';
    }
    case (AxStandardtaskResponsibleUsertypeEnum.Electricianuser): {
      return 'Elektriker des Projekts';
    }
  }
};
