<div [class.close]="closeMenu" class="ax-ui-inline-nav-list">
  <div class="ax-ui-inline-nav-list__heading flex flex-row vertical-center gap-12">
    @if (showToggleButton) {
      <ax-ui-button (buttonClick)="toggleMenu()"
                    [iconName]="!closeMenu ? 'menu_open' : 'chevron_right'"
                    [iconOnly]="true"
                    [whiteBackground]="true"
                    [small]="true"
                    class="ax-ui-inline-nav-list__toggle"
                    variant="secondary" />
    }
    <ax-ui-input-field (valueChange)="search($event)"
                       [debounceTimeInMs]="0"
                       [disableError]="true"
                       [showClearButton]="true"
                       [class.hide]="closeMenu"
                       [compactStyle]="true"
                       axAutofocus
                       iconName="search"
                       placeholder="SEITE_SUCHEN" />
  </div>
  <nav>
    <ul class="ax-list standalone" role="list">
      @for (navigationItemGroup of navigationItemsFiltered; track $index; let isFirst = $first; let isLast = $last) {
        @if (navigationItemGroup.title) {
          @if (!isFirst) {
            <hr class="margin-vertical-12">
          }
          <li class="ax-list__sublist">
            <p class="text-overflow-ellipsis"><b>{{ navigationItemGroup.title | translate }}</b></p>
            <ul class="ax-list standalone" role="list">
              @for (navigationItem of navigationItemGroup.items; track navigationItem) {
                <li class="ax-action-element width-100"
                    role="listitem"
                    routerLinkActive="active">
                  <a [routerLink]="getRouterLink(navigationItem)">
                    @if (navigationItem.icon) {
                      <ax-ui-icon [name]="navigationItem.icon" />
                    }
                    <span class="text-overflow-ellipsis"
                          [class.margin-top-2]="navigationItem.icon">
                      {{ navigationItem.label | translate }}
                    </span>
                  </a>
                </li>
              }
            </ul>
          </li>
          @if (navigationItemsFiltered[$index + 1] && !navigationItemsFiltered[$index + 1].title && !isLast) {
            <hr class="margin-vertical-12">
          }
        } @else {
          <li class="ax-action-element width-100"
              role="listitem"
              routerLinkActive="active">
            <a [routerLink]="getRouterLink(navigationItemGroup.items[0])">
              @if (navigationItemGroup.items[0].icon) {
                <ax-ui-icon [name]="navigationItemGroup.items[0].icon" />
              }
              <span class="text-overflow-ellipsis"
                    [class.margin-top-2]="navigationItemGroup.items[0].icon">
                      {{ navigationItemGroup.items[0].label | translate }}
              </span>
            </a>
          </li>
        }
      }
    </ul>
  </nav>
</div>
