import { ComponentRef, Injectable } from '@angular/core';
import { ConfirmActionModalHostDirective } from '../../directives/confirm-action-modal-host/confirm-action-modal-host.directive';
import { ModalConfirmActionComponent } from '../../components/modal-confirm-action/modal-confirm-action.component';
import { ConfirmActionModalButton, ConfirmActionModalOptions } from '../../models/confirm-action-modal-options.model';
import { Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmActionModalService {
  private modalsHost!: ConfirmActionModalHostDirective;
  private activeModal: ComponentRef<ModalConfirmActionComponent> | null = null;

  public setModalHost(host: ConfirmActionModalHostDirective) {
    this.modalsHost = host;
  }

  public showModal(modalOptions?: ConfirmActionModalOptions): Observable<ConfirmActionModalButton> {
    if (this.activeModal) {
      this.closeModal();
    }

    const modalRef = this.modalsHost.viewContainerRef.createComponent(ModalConfirmActionComponent);
    const modalRefInstance = modalRef.instance;
    if (!modalOptions) {
      modalOptions = {};
    }
    modalRefInstance.modalOptions = modalOptions;
    this.activeModal = modalRef;

    return new Observable<ConfirmActionModalButton>((observer) => {
      modalRefInstance.userAction.pipe(
        take(1),
        tap((confirmActionModalButton) => {
          if (confirmActionModalButton !== undefined) {
            observer.next(confirmActionModalButton);
          }
          observer.complete();
          this.closeModal();
        }),
      ).subscribe();
    });
  }

  public closeModal() {
    if (this.activeModal) {
      this.activeModal.destroy();
      this.activeModal = null;
    }
  }
}
