import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { StickyFooterComponent } from '../sticky-footer/sticky-footer.component';
import { FormEditModeService } from '../../services/form-editmode/form-edit-mode.service';
import { FormGroup } from '@angular/forms';
import { LoadingAndFeedbackState } from '../../models/loading-and-feedback-state.type';

@Component({
  selector: 'ax-ui-form-edit-inline-container',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TranslateModule,
    IconComponent,
    StickyFooterComponent
  ],
  templateUrl: './form-edit-inline-container.component.html',
  styleUrl: './form-edit-inline-container.component.scss'
})
export class FormEditInlineContainerComponent {
  @Input({ required: true }) canUpdate = false;
  @Input({ required: true }) formGroupUpdate!: FormGroup;
  @Input({ required: true }) editMode = false;
  @Input() formSaveLoadingState: LoadingAndFeedbackState = 'none';

  @Output() editModeChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private readonly formEditModeService: FormEditModeService
  ) {
  }

  // listen for reload & prevent on unsaved changes
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (this.hasUnsavedChanges()) {
      event.preventDefault();
    }
  }

  public cancelEdit() {
    const cancel = () => {
      this.emitEditModeChange(false);
      this.cancel.emit();
    };

    if (this.formGroupUpdate.dirty) {
      this.formEditModeService.showConfirmEditModeCancelModal().subscribe({
        next: (confirmed) => {
          if (confirmed) {
            cancel();
          }
        }
      });
    } else {
      cancel();
    }
  }

  public emitSaveEvent() {
    this.save.emit();
  }

  public emitEditModeChange(active: boolean) {
    this.editMode = active;
    this.editModeChange.emit(this.editMode);
  }

  private hasUnsavedChanges(): boolean {
    return this.editMode && this.formGroupUpdate.dirty;
  }
}
