import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { dropDownAlignment, DropdownListComponent } from '../dropdown-list/dropdown-list.component';
import { DropdownOption } from '@axova-frontend-monorepo/axova-commons';
import { ButtonComponent } from '../button/button.component';
import { ModalFloatingDirective } from '../../directives/modal-floating/modal-floating.directive';

@Component({
  selector: 'ax-ui-dropdown-button',
  templateUrl: './actions-dropdown-button.component.html',
  styleUrls: ['./actions-dropdown-button.component.scss'],
  standalone: true,
  imports: [
    ModalFloatingDirective,
    ButtonComponent,
    DropdownListComponent
  ]
})
export class ActionsDropdownButtonComponent {
  @ViewChild('actionsDropdown') dropdown!: DropdownListComponent;

  @Input({ required: true }) dropdownOptions!: DropdownOption[];
  @Input() buttonWhiteBackground = false;
  @Input() defaultButtonSize = false;
  @Input() modalPosition: dropDownAlignment = 'bottom';
  @Input() iconName = 'more_horiz';

  @Output() actionSelected: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @Output() dropDownOpening: EventEmitter<any> = new EventEmitter<any>();

  selectAction(dropdownOption: DropdownOption) {
    this.actionSelected.emit(this.dropdownOptions.find(option => option.label === dropdownOption.label));
  }
}
