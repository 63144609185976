export interface NavigationItemInterface {
  label: string;
  icon?: string;
  route: string;
  fragment?: string;
  outlet?: string;
  primaryOutletRoute?: string;
  requiredPermission?: string;
  excludeFromSideNav?: boolean;
}

export interface NavigationGroupInterface {
  title?: string;
  items: NavigationItemInterface[];
}