import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AxCreateReservationDto, AxLocationsV2Service, AxReservation, AxReservationsService, getCreateReservationDtoFormGroup } from '@axova-frontend-monorepo/axova-rest-api';
import { InputFieldComponent } from '../../../inputs/input-field/input-field.component';
import { Store } from '@ngxs/store';
import { TerminassistentState } from '../../ngxs/terminassistent/terminassistent.state';
import { defer, lastValueFrom, Observable } from 'rxjs';
import { SelectDropdownOptions } from '../../../../models/select-dropdown-options.model';
import { shareReplay } from 'rxjs/operators';
import { SelectComponent } from '../../../inputs/select/select.component';
import { CheckboxComponent } from '../../../inputs/checkbox/checkbox.component';
import { IconComponent } from '../../../icon/icon.component';
import { LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { environment } from '@axova-frontend-monorepo/axova-environments';
import { TerminassistentStateSetReservation } from '../../ngxs/terminassistent/terminassistent.actions';
import { ButtonComponent } from '../../../button/button.component';
import { TerminassistentWizardStepsNavService } from '../../services/terminassistent-wizard-steps-nav.service';
import { Router } from '@angular/router';
import { sha1 } from 'js-sha1';

@Component({
  selector: 'ax-ui-terminassistent-enter-your-data',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent, SelectComponent, CheckboxComponent, IconComponent, ButtonComponent],
  templateUrl: './terminassistent-enter-your-data.component.html',
  styleUrl: './terminassistent-enter-your-data.component.scss',
})
export class TerminassistentEnterYourDataComponent implements OnInit {
  public formGroup = getCreateReservationDtoFormGroup();
  public isSeparateObjectAddress = false;
  public hasApiKey = (!!this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerKey) || !!this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerExternalKey));
  public isTermsAccepted = this.hasApiKey;
  public dropdownOptionsCantons$: Observable<SelectDropdownOptions[]>;
  public dropdownOptionstitle: SelectDropdownOptions[] = [
    {
      label: 'Herr',
      value: 1,
    },
    {
      label: 'Frau',
      value: 2,
    },
    {
      label: 'Familie',
      value: 3,
    },
    {
      label: 'Firma',
      value: 4,
    },
  ];

  constructor(
    private store: Store,
    private router: Router,
    private axLocationsV2Service: AxLocationsV2Service,
    private axReservationsService: AxReservationsService,
    private wizardStepsNavService: TerminassistentWizardStepsNavService,
  ) {
    this.dropdownOptionsCantons$ = defer(() => this.axLocationsV2Service.locationsControllerGetAllDropdownOptionsCantons()).pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.initializeFormWithLocationData();
  }

  public changeIsSeparateObjectAddress(checked: boolean) {
    this.isSeparateObjectAddress = !checked;
  }

  public async onSubmit(triggeredFromForm = false) {
    if (!this.isTermsAccepted) {
      return;
    }
    const timeslot = this.store.selectSnapshot(TerminassistentState.timeslot);

    this.formGroup.patchValue({
      created: new Date().toISOString(),
      businessunitId: this.store.selectSnapshot(TerminassistentState.location)?.businessunit.id as number,
      start: timeslot.start,
      end: timeslot.end,
      googlecalendarId: timeslot.googlecalendarId,
      reservationtypeId: this.store.selectSnapshot(TerminassistentState.reservationtype)?.id || -1,
      street: this.formGroup.value.street || this.formGroup.value.objectStreet,
    });

    const reservation = this.formGroup.value as Partial<AxReservation>;
    this.formGroup.patchValue({
      accessKey: sha1(JSON.stringify(reservation)),
    });
    const reservationWithHashedAccessKey = this.formGroup.value as Partial<AxReservation>;
    this.store.dispatch(new TerminassistentStateSetReservation(reservationWithHashedAccessKey));

    // Attach API scheduler key (internal).
    const apiAppointmentSchedulerKey = this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerKey);
    if (apiAppointmentSchedulerKey) {
      this.formGroup.patchValue({
        apiAppointmentSchedulerKey: apiAppointmentSchedulerKey,
      });
    }

    // Attach API scheduler key (external).
    const apiAppointmentSchedulerExternalKey = this.store.selectSnapshot(TerminassistentState.apiAppointmentSchedulerExternalKey);
    if (apiAppointmentSchedulerExternalKey) {
      this.formGroup.patchValue({
        apiAppointmentSchedulerExternalKey: apiAppointmentSchedulerExternalKey,
      });
    }

    await this.createReservation(triggeredFromForm);
  }

  public async createReservation(redirectAfterRequest: boolean) {
    this.wizardStepsNavService.showLoader();
    try {
      const result = await lastValueFrom(this.axReservationsService.reservationsControllerCreate({
        body: this.formGroup.value as AxCreateReservationDto,
        'x-api-key': environment.apiConfiguration.publicApiKey,
      }));
      if ((result as AxReservation).otp) {
        this.store.dispatch(new TerminassistentStateSetReservation({ otp: (result as AxReservation).otp }));
      }
      this.wizardStepsNavService.hideLoader();
      if (redirectAfterRequest) {
        await this.router.navigateByUrl('/terminassistent/verifizierung');
      }
    } catch (createReservationException) {
      this.wizardStepsNavService.hideLoader();
      LoggerService.ERROR('Terminassisstent create reservation', createReservationException);
    }
  }

  private initializeFormWithLocationData() {
    const reservation = this.store.selectSnapshot(TerminassistentState.reservation);
    if (reservation) {
      this.formGroup.patchValue(reservation as any);
      this.formGroup.controls.titleId.setValue(reservation?.titleId);
    }
    const location = this.store.selectSnapshot(TerminassistentState.location);
    const timeslot = this.store.selectSnapshot(TerminassistentState.timeslot);
    this.formGroup.patchValue({
      objectZip: location?.zipcode + '' || '',
      zipcode: location?.zipcode + '' || '',
      objectCity: location?.name + '' || '',
      city: location?.name + '' || '',
      objectCanton: location?.canton + '' || '',
      canton: location?.canton + '' || '',
      googlecalendarId: timeslot?.googlecalendarId,
    });
  }

  public acceptTerms(checked: boolean) {
    this.isTermsAccepted = checked;
  }
}
