/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxAddRoletypeDto } from '../models/ax-add-roletype-dto';
import { AxCreatePermissionDto } from '../models/ax-create-permission-dto';
import { AxDropdownOptionDto } from '../models/ax-dropdown-option-dto';
import { AxPaginatedDocumented } from '../models/ax-paginated-documented';
import { AxPermission } from '../models/ax-permission';
import { AxRemoveRoletypeDto } from '../models/ax-remove-roletype-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxUpdatePermissionDto } from '../models/ax-update-permission-dto';

@Injectable({ providedIn: 'root' })
export class AxPermissionsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `permissionsControllerGetAllDropdownOptions()` */
  static readonly PermissionsControllerGetAllDropdownOptionsPath = '/v2/permissions/dropdown-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerGetAllDropdownOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerGetAllDropdownOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDropdownOptionDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerGetAllDropdownOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDropdownOptionDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerGetAllDropdownOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerGetAllDropdownOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxDropdownOptionDto>> {
    return this.permissionsControllerGetAllDropdownOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDropdownOptionDto>>): Array<AxDropdownOptionDto> => r.body)
    );
  }

  /** Path part for operation `permissionsControllerFindAllPaginated()` */
  static readonly PermissionsControllerFindAllPaginatedPath = '/v2/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerFindAllPaginated$Response(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxPermission>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedDocumented & {
        'data'?: Array<AxPermission>;
        'meta'?: {
        'select'?: Array<string>;
        'filter'?: {
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerFindAllPaginated(
    params?: {

    /**
     * Page number to retrieve.If you provide invalid value the default page number will applied
     *         <p>
     *              <b>Example: </b> 1
     *           </p>
     *         <p>
     *              <b>Default Value: </b> 1
     *           </p>
     */
      page?: number;

    /**
     * Number of records per page.
     *       <p>
     *              <b>Example: </b> 20
     *           </p>
     *       <p>
     *              <b>Default Value: </b> 25
     *           </p>
     *       <p>
     *              <b>Max Value: </b> 100
     *           </p>
     *
     *       If provided value is greater than max value, max value will be applied.
     */
      limit?: number;

    /**
     * Parameter to sort by.
     *       <p>To sort by multiple fields, just provide query param multiple types. The order in url defines an order of sorting</p>
     *       <p>
     *              <b>Format: </b> fieldName:DIRECTION
     *           </p>
     *       <p>
     *              <b>Example: </b> sortBy=id:DESC&sortBy=createdAt:ASC
     *           </p>
     *       <p>
     *              <b>Default Value: </b> name:ASC
     *           </p>
     *       <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      sortBy?: Array<'name:ASC' | 'name:DESC'>;

    /**
     * Search term to filter result values
     *         <p>
     *              <b>Example: </b> John
     *           </p>
     *         <p>
     *              <b>Default Value: </b> No default value
     *           </p>
     */
      search?: string;

    /**
     * List of fields to search by term to filter result values
     *         <p>
     *              <b>Example: </b> name
     *           </p>
     *         <p>
     *              <b>Default Value: </b> By default all fields mentioned below will be used to search by term
     *           </p>
     *         <h4>Available Fields</h4><ul><li>name</li></ul>
     */
      searchBy?: Array<string>;
    },
    context?: HttpContext
  ): Observable<AxPaginatedDocumented & {
'data'?: Array<AxPermission>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}> {
    return this.permissionsControllerFindAllPaginated$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPaginatedDocumented & {
'data'?: Array<AxPermission>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
}>): AxPaginatedDocumented & {
'data'?: Array<AxPermission>;
'meta'?: {
'select'?: Array<string>;
'filter'?: {
};
};
} => r.body)
    );
  }

  /** Path part for operation `permissionsControllerCreate()` */
  static readonly PermissionsControllerCreatePath = '/v2/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerCreate$Response(
    params: {
      body: AxCreatePermissionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerCreate(
    params: {
      body: AxCreatePermissionDto
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.permissionsControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `permissionsControllerFindOneById()` */
  static readonly PermissionsControllerFindOneByIdPath = '/v2/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerFindOneById()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerFindOneById$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxPermission>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerFindOneByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPermission>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerFindOneById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerFindOneById(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxPermission> {
    return this.permissionsControllerFindOneById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxPermission>): AxPermission => r.body)
    );
  }

  /** Path part for operation `permissionsControllerDelete()` */
  static readonly PermissionsControllerDeletePath = '/v2/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerDelete$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionsControllerDelete(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.permissionsControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `permissionsControllerUpdate()` */
  static readonly PermissionsControllerUpdatePath = '/v2/permissions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerUpdate$Response(
    params: {
      id: number;
      body: AxUpdatePermissionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerUpdate(
    params: {
      id: number;
      body: AxUpdatePermissionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.permissionsControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `permissionsControllerAddRoletypeToPermission()` */
  static readonly PermissionsControllerAddRoletypeToPermissionPath = '/v2/permissions/{id}/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerAddRoletypeToPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerAddRoletypeToPermission$Response(
    params: {
      id: number;
      body: AxAddRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerAddRoletypeToPermissionPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerAddRoletypeToPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerAddRoletypeToPermission(
    params: {
      id: number;
      body: AxAddRoletypeDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.permissionsControllerAddRoletypeToPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

  /** Path part for operation `permissionsControllerRemoveRoletypeFromPermission()` */
  static readonly PermissionsControllerRemoveRoletypeFromPermissionPath = '/v2/permissions/{id}/roletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionsControllerRemoveRoletypeFromPermission()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerRemoveRoletypeFromPermission$Response(
    params: {
      id: number;
      body: AxRemoveRoletypeDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxPermissionsV2Service.PermissionsControllerRemoveRoletypeFromPermissionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionsControllerRemoveRoletypeFromPermission$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  permissionsControllerRemoveRoletypeFromPermission(
    params: {
      id: number;
      body: AxRemoveRoletypeDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.permissionsControllerRemoveRoletypeFromPermission$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
