import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true,
  pure: false,
})
export class ObjectKeysPipe implements PipeTransform {
  transform(object: any): Array<any> {
    try {
      return Object.keys(object);
    } catch (noObject) {
      return object;
    }
  }
}
