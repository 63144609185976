import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ax-ui-progressbar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progressbar.component.html',
  styleUrl: './progressbar.component.scss',
})
export class ProgressbarComponent {
  @Input() min = 0;
  @Input() progress = 0;
  @Input() max = 100;

  get progressPercentage(): number {
    return ((this.progress - this.min) / (this.max - this.min)) * 100;
  }
}
