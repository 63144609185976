import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getWorkflowtasktypeIconName',
    standalone: true,
})
export class GetWorkflowtasktypeIconNamePipe implements PipeTransform {
  transform(workflowtasktypeId: number | null | undefined): string {
    switch (workflowtasktypeId) {
      case 1:
        return 'add_task';
      case 2:
        return 'play_circle';
      case 3:
        return 'task_alt';
      case 4:
        return 'mail';
      default: {
        return 'event_available';
      }
    }
  }
}
