import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ax-ui-sticky-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sticky-footer.component.html',
  styleUrl: './sticky-footer.component.scss'
})
export class StickyFooterComponent implements OnChanges {
  @Input() padding: 'default' | 'compact' | 'none' = 'default';
  @Input() animationState: 'show' | 'hide' | 'none' = 'none';
  @Input() hideOnInit = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['animationState'] && changes['animationState'].currentValue === 'show') {
      this.hideOnInit = false;
    }
  }
}
