import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ax-ui-sticky-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sticky-footer.component.html',
  styleUrl: './sticky-footer.component.scss',
})
export class StickyFooterComponent {
  @Input() withPadding: boolean = true;
}
