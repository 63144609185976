@for (option of tableHeaderItems; track option; let i = $index) {
  <th class="ax-ui-table-header" scope="col" [class.small]="option.smallerSize">
    <div class="ax-ui-table-header-content" (click)="option.sortable ? changeSortStatus(i) : null"
         [class.align-right]="option.alignRight"
         [class.sortable]="option.sortable">
      @if (option.selectable) {
        <ax-ui-checkbox [noMargin]="true" [label]="option.label | translate" [(ngModel)]="option.selectedStatus"
                        (valueChanged)="checkboxChange.emit(tableHeaderItems[i])" />
      } @else {
        {{ option.label | translate }}
      }
      @if (option.sortable) {
        @if (option.sortStatus === 'NONE' || !sortOnClick) {
          <ax-ui-icon
            name="swap_vert">
          </ax-ui-icon>
        }
        @if (option.sortStatus === 'DESC' && sortOnClick) {
          <ax-ui-icon
            class="sort-active"
            name="arrow_downward_alt">
          </ax-ui-icon>
        }
        @if (option.sortStatus === 'ASC' && sortOnClick) {
          <ax-ui-icon
            class="sort-active"
            name="arrow_upward_alt">
          </ax-ui-icon>
        }
      }
    </div>
  </th>
}
