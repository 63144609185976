import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ax-component-library-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class ContainerComponent {
  @Input() title = '';
  @Input() verticalDirection = false;
}
