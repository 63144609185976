import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IconComponent } from '../../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'ax-ui-accordion-item',
  standalone: true,
  imports: [
    IconComponent,
    NgClass,
  ],
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent {
  @ViewChild('heading') headingElement: ElementRef | undefined;
  @Input() isOpen = false;
  @Input() isDisabled = false;
  @Input() withBorder = true;
  @Input() noPadding = false;
  @Input() hideIcon = false;
  @Input() headerNoHoverEffect = false;
  @Input() headerIconSpaceBetween = true;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
