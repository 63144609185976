import { TableHeaderItem } from './table-header-options.model';
import { AxPaginatedDocumented } from '@axova-frontend-monorepo/axova-rest-api';
import { DropdownOption, LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { ActivatedRoute, Router } from '@angular/router';
import { inject } from '@angular/core';
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface TableBase<T> {
  data?: T[];
  dataPaginated?: (AxPaginatedDocumented & { data?: Array<T> }) | undefined;
  dataSelectedRows?: T[];
  paginationOptions?: TablePaginationOptions;
  filterDropdownOptions: DropdownOption[];
  tableHeaderItems: TableHeaderItem[];
  isLoading?: boolean;
  load?: () => any;
  sort?: (tableHeaderItem: TableHeaderItem) => any;
  selectDataRow?: (dataRow: T) => any;
  search?: (searchString: string) => any;
  addFilter?: (tableFilter: DropdownOption) => any;
  removeFilter?: (tableFilter: DropdownOption) => any;
}

export class TableBaseClass<T> implements TableBase<T> {
  public data: T[] = [];
  public dataPaginated: (AxPaginatedDocumented & { data: Array<T> }) | undefined;
  public dataSelectedRows: T[] = [];
  public isLoading = true;
  public paginationOptions: TablePaginationOptions | undefined;
  public defaultPaginationOptions: Partial<TablePaginationOptions> = {};
  public activeTableHeaderItem: TableHeaderItem | undefined;
  public currentSearchTerm: string | undefined;
  public tableHeaderItems: TableHeaderItem[] = [];
  public filterDropdownOptions: DropdownOption[] = [];
  public activeDragElement: CdkDrag | undefined;
  public dragAborted = false;
  public draggingActive = false;
  private angularRouter: Router;
  private angularActivatedRoute: ActivatedRoute;

  constructor() {
    this.angularRouter = inject(Router);
    this.angularActivatedRoute = inject(ActivatedRoute);
  }

  /**
   * Main method which is responsible for loading paginated data from the API.
   */
  public async load() {
    LoggerService.LOG(
      this,
      'load() method must be implemented individually per component. e.g. load data from API like Events, Items, Users etc. or similar.',
    );
  }

  /**
   * When sorting was changed, sets the current sort table header options and does
   * a load afterwards.
   *
   * @param tableHeaderItem
   */
  public async sort(tableHeaderItem: TableHeaderItem) {
    this.activeTableHeaderItem = tableHeaderItem;
    await this.load();
  }

  /**
   * When pagination was changed by the user (e.g. by selecting a page, or change amount of items per page),
   * this saves these pagination options and does a load with the new settings afterwards.
   *
   * @param tablePaginationOptions
   */
  public async paginationOptionsUpdated(tablePaginationOptions: TablePaginationOptions) {
    this.paginationOptions = tablePaginationOptions;
    await this.load();
  }

  /**
   * Basically just sets the search term which will be sent to the API. Also
   * reset the pagination options, since they'll be overwritten by API after receiving the result.
   *
   * @param searchTerm
   */
  public async search(searchTerm: string) {
    this.currentSearchTerm = searchTerm;
    this.paginationOptions = undefined;
    if (this.angularRouter && this.angularActivatedRoute) {
      this.angularRouter.navigate([], {
        relativeTo: this.angularActivatedRoute,
        fragment: undefined,
        queryParamsHandling: 'merge', // If you want to preserve query parameters
      });
    }
    await this.load();
  }

  /**
   * Returns an array of strings which can be sent to the API.
   * You have to pass the name of the filter to find the correct results.
   *
   * @param filterName
   * @param includeFiltersDespiteSearch
   * @protected
   */
  protected getFilter(filterName: string, includeFiltersDespiteSearch = false): string[] {
    if (this.currentSearchTerm && this.currentSearchTerm !== '' && !includeFiltersDespiteSearch) {
      return [];
    }
    const filterBy: string[] = [];
    const currentFilterOptions = this.filterDropdownOptions.filter((option) => option.selected === true);
    if (currentFilterOptions && currentFilterOptions.length) {
      const filters = currentFilterOptions.filter((filterOption) => filterOption.name === filterName);
      filters.forEach((filter, index) => {
        // change boolean values to 0/1 to match stored DB values and ensure correct behaviour
        if (filter.value === true) {
          filter.value = 1;
        } else if (filter.value === false) {
          filter.value = 0;
        }
        const filterSuffix = index === 0 ? '' : '$or:';
        if (filter.value !== undefined) {
          filterBy.push(`${filterSuffix}${filter.operator}:${filter.value}`);
        } else {
          filterBy.push(`${filterSuffix}${filter.operator}`);
        }
      });
    }
    return filterBy;
  }

  /**
   * Returns the value of the plain filter without suffix or prefix.
   *
   * @param filterName
   * @param includeFiltersDespiteSearch
   * @protected
   */
  protected getPlainFilter(filterName: string, includeFiltersDespiteSearch = false) {
    if (this.currentSearchTerm && this.currentSearchTerm !== '' && !includeFiltersDespiteSearch) {
      return undefined;
    }
    let filterBy = undefined;
    const currentFilterOptions = this.filterDropdownOptions.filter((option) => option.selected === true);
    if (currentFilterOptions && currentFilterOptions.length) {
      const filters = currentFilterOptions.filter((filterOption) => filterOption.name === filterName);
      for (const filter of filters) {
        filterBy = filter.value;
      }
    }
    return filterBy;
  }

  /**
   * Returns the value of the plain filter without suffix or prefix.
   *
   * @param filterName
   * @param includeFiltersDespiteSearch
   * @protected
   */
  protected getPlainFilters(filterName: string, includeFiltersDespiteSearch = false): any[] {
    if (this.currentSearchTerm && this.currentSearchTerm !== '' && !includeFiltersDespiteSearch) {
      return [];
    }
    const filterBy: any[] = [];
    const currentFilterOptions = this.filterDropdownOptions.filter((option) => option.selected === true);
    if (currentFilterOptions && currentFilterOptions.length) {
      const filters = currentFilterOptions.filter((filterOption) => filterOption.name === filterName);
      for (const filter of filters) {
        filterBy.push(filter.value);
      }
    }
    return filterBy;
  }

  /**
   * Returns the amount of items to be displayed per page. Defaults to 25.
   *
   * @protected
   */
  protected getLimit() {
    if (this.paginationOptions) {
      return this.paginationOptions.itemsPerPage;
    } else if (this.defaultPaginationOptions.itemsPerPage) {
      return this.defaultPaginationOptions.itemsPerPage;
    } else {
      return 25;
    }
  }

  /**
   * Returns the current page number. Defaults to 1.
   *
   * @protected
   */
  protected getPage() {
    if (this.paginationOptions) {
      return this.paginationOptions.currentPage;
    } else if (this.defaultPaginationOptions.currentPage) {
      return this.defaultPaginationOptions.currentPage;
    } else {
      return 1;
    }
  }

  /**
   * Returns an array of sort strings which can be sent to the API.
   *
   * @protected
   */
  protected getSortBy() {
    const sortBy: any[] = [];
    if (this.activeTableHeaderItem) {
      sortBy.push(`${this.activeTableHeaderItem.name}:${this.activeTableHeaderItem.sortStatus}`);
    } else {
      // no active sort table header on init, check initial table header items config
      this.tableHeaderItems.forEach((tableHeaderItem) => {
        if (tableHeaderItem.sortStatus) {
          sortBy.push(`${tableHeaderItem.name}:${tableHeaderItem.sortStatus}`);
        }
      });
    }
    return sortBy;
  }

  /**
   * Sets the current pagination options based on the received meta data from the API.
   * Setting some default values as well, if API doesn't deliver them.
   *
   * @protected
   */
  protected setPaginationOptions() {
    if (this.dataPaginated) {
      this.paginationOptions = {
        itemsPerPage: this.dataPaginated.meta.itemsPerPage || 25,
        totalItems: this.dataPaginated.meta.totalItems || 0,
        currentPage: this.dataPaginated.meta.currentPage || 0,
      };
    }
  }

  public async handleCdkDrop($event: CdkDragDrop<string[]>): Promise<void> {
    if (this.dataPaginated?.data && !this.dragAborted) {
      moveItemInArray(this.dataPaginated.data, $event.previousIndex, $event.currentIndex);
      await this.executeLogicOnCdkDrop();
    }
  }

  /**
   * Optional method to be extended in child classes for additional logic after cdk drag & drop.
   */
  protected async executeLogicOnCdkDrop(): Promise<void> {
    // Base class default implementation can be empty
  }

  protected onDragStart($event: any) {
    this.draggingActive = true;
    this.activeDragElement = $event.source;
    document.addEventListener('keydown', this.escapeListener);
  }

  public onDragEnd() {
    document.removeEventListener('keydown', this.escapeListener);
    // await handleCdkDrop() to be executed
    this.dragAborted = false;
    setTimeout(() => {
      this.draggingActive = false;
    });
  }

  protected escapeListener = (keyboardEvent: KeyboardEvent) => {
    if (keyboardEvent.key === 'Escape') {
      keyboardEvent.preventDefault();
      this.dragAborted = true;
      // force the drag element to drop
      const dragEndEvent = new DragEvent('mouseup');
      document.dispatchEvent(dragEndEvent);
      this.activeDragElement?.reset();
      this.draggingActive = false;
      document.removeEventListener('keydown', this.escapeListener);
    }
  };
}

export interface TablePaginationOptions {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
}
