import { DropdownOption, ConfigurationKeyNamesEnum } from '@axova-frontend-monorepo/axova-commons';

export interface FilterStateModel {
  filters: Filter[];
}

export interface Filter {
  name: ConfigurationKeyNamesEnum;
  options: DropdownOption[];
}
