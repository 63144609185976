import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getContainertypeIconName',
  standalone: true,
})
export class GetContainertypeIconNamePipe implements PipeTransform {
  transform(businessdivisionId: number | null | undefined): string {
    switch (businessdivisionId) {
      case 1:
        return 'solar_power';
      case 2:
        return 'roofing';
      case 3:
        return 'task';
      case 4:
        return 'query_stats';
      case 5:
        return 'heat_pump';
      default: {
        return 'solar_power';
      }
    }
  }
}
