<div class="ax-ui-terminasssistent-available-appointments">
  <div class="flex gap-24 wrap margin-bottom-24">
    <h2 class="ax-web-text--large">Termin wählen</h2>
    @if (selectedTimeslot | async) {
      <ax-ui-label [text]="'Aktuell ausgewählt: ' + ((selectedTimeslot | async)?.start | date:'EE, d. MMMM YYYY, HH:mm') + ' Uhr'"
                   variant="success" />
    }
  </div>


  @if (availableTimeslots && availableTimeslots.length) {
    <p class="fade-in">Wählen Sie einen passenden Termin aus:</p>
    <div class="fade-in">
      <ul class="margin-top-32 flex flex-column gap-32">
        @for (day of objectKeys(availableTimeslotsGroupedByDay); track day) {
          @if (hasFreeSlotOnDay(availableTimeslotsGroupedByDay[day])) {
            <li>
              <h3 class="ax-web-text--regular margin-bottom-12">{{ day  | date:'EEEE, d. MMMM YYYY' }}</h3>
              <ul class="ax-ui-terminasssistent-available-appointments__day">
                @for (availableTimeslot of availableTimeslotsGroupedByDay[day]; track availableTimeslot) {
                  @if (availableTimeslot.available) {
                    <li (click)="chooseTimeslot(availableTimeslot)"
                        class="ax-card ax-card--clickable ax-text-align-center">
                      <p><small>{{ availableTimeslot.start | date:'HH:mm' }} Uhr</small></p>
                    </li>
                  }
                }
              </ul>
            </li>
            <hr>
          }
        }
      </ul>
      @if (isBiggerOffsetAllowed && !isLoading) {
        <div class="flex center margin-top-48">
          <ax-ui-button (buttonClick)="getTimeslots()"
                        text="Weitere Termine anzeigen"
                        [small]="true" />
        </div>
      }
    </div>
  } @else if (availableTimeslots && !availableTimeslots.length && !isLoading) {
    <div class="ax-text-prose">
      <p><small>Es gibt aktuell keine freien Termine, tut uns leid! Termine werden laufend hinzugefügt.</small></p>
      <p>Rufen Sie uns an – <a href="tel:+41800400222">0800 400 222</a> – wir finden eine Lösung.</p>
    </div>
  }


  @if (isLoading) {
    <div class="flex flex-column gap-24 center width-100 height-100 margin-top-120">
      <ax-ui-loading-spinner loadingState="loading" [iconSize]="40" />
      <p>Freie Termine werden gesucht</p>
    </div>
  } @else {
    <p class="margin-top-32"><strong>Keinen passenden Termin gefunden?</strong><br>Rufen Sie uns an, wir finden eine Lösung: <a href="tel:+41800400222">0800 400
      222</a></p>
  }

</div>
