import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'where',
  pure: false,
  standalone: true,
})
export class WherePipe implements PipeTransform {
  transform(array: Array<any> | undefined | null, property: string, value: any, operator: 'equals' | 'notEquals' | 'contains' = 'equals'): Array<any> {
    if (!array) {
      return [];
    }
    switch (operator) {
      case 'equals':
        return array.filter(item => this.getPropertyValue(item, property) === value);
      case 'notEquals':
        return array.filter(item => this.getPropertyValue(item, property) !== value);
      case 'contains':
        return array.filter(item => this.getPropertyValue(item, property).indexOf(value) > -1);
    }
  }

  private getPropertyValue(item: any, property: string): any {
    return property.split('.').reduce((acc, part) => acc && acc[part], item);
  }
}
