<ax-ui-widget-base [route]="route"
                   iconName="cake"
                   title="{{'GEBURTSTAGE_NAECHSTE' | translate}}">
  <div class="ax-ui-widget-geburtstage">
    @if (birthdayUsers.length > 0) {
      <ul class="ax-list">
        @for (user of birthdayUsers; track user) {
          <li class="no-inner-padding">
            <div class="flex flex-row vertical-center gap-8">
              <ax-ui-avatar
                [imgSrc]="user.userImage ? (user.userImage.fileSrcThumbnail | prependServerToFileSrc) : null"
                [size]="'mini'"
                alt="{{user.firstName}} {{user.lastName}}" />
              <div>
                <b>{{ user.firstName }} {{ user.lastName }}</b>
                <p>{{ user.birthdate }}</p>
              </div>
            </div>
            <div>
              <ax-ui-label [small]="true"
                           [text]="('WIRD' | translate) + ' ' + user.age"
                           variant="success" />
            </div>
          </li>
        }
      </ul>
    }
    @if (birthdayUsers.length === 0) {
      <p class="color-light">{{ 'GEBURTSTAGE_KEINE' | translate }}</p>
    }
  </div>
</ax-ui-widget-base>
